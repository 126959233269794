import { Breadcum } from "../../../components/breadcum";
import { NavLink, useLocation } from "react-router-dom";
import { EditorPanelRoutes } from "../../../Routes/editor-panel";
import { GETSOCIALFEEDS } from "../../../services/editor-panel.service";
import { toast } from "react-toastify";
import { useState } from "react";

export const EditorPanelPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const urlPath = location?.pathname.split("/");

  const breadcumInfo = [
    {
      label: "Editor Panel",
      value: "Editor Panel",
      routerLink: "/dashboard/editor-panel",
      isActive: true,
    },
  ];

  const onRfresh = () => {
    setLoading(true);
    GETSOCIALFEEDS().then((res: any) => {
      if (res.status === "fail") {
        setLoading(false);
        toast.error(res.error);
      } else {
        if (res) {
          toast.success(res.message);
          setLoading(false);
        }
      }
    });
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6">
          <Breadcum breadcumList={breadcumInfo} />
        </div>
        <div className="col-6 text-end ">
          <button
            className="btn btn-outline-dark me-2 my-2"
            type="submit"
            onClick={onRfresh}
          >
            <i className="bi bi-arrow-clockwise" title="Refresh"></i>
          </button>
        </div>
      </div>
      <div className="col-12">
        <div className="px-2 mx-1">
          <ul className="nav nav-tabs my-3 ">
            {/* <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/new-stories`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                New Stories
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/approved-stories`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                Approved Stories
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/todays-stories`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                Today Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/master-stories`}
                className="nav-link text-decoration-none text-dark"
              >
                Master Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/social-media-stories`}
                className="nav-link text-decoration-none text-dark"
              >
                Social Media Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/double-thumbs-up-stories`}
                className="nav-link text-decoration-none text-dark"
              >
                Double Thumbs Up Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/stared-stories`}
                className="nav-link text-decoration-none text-dark"
              >
                Starred Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/rejected-stories`}
                className="nav-link text-decoration-none text-dark"
              >
                Rejected Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/SREP-stories/feeds`}
                // className="nav-link text-decoration-none text-dark"
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  5 &&urlPath[3] === "SREP-stories"&&
                  (urlPath[4] === "approved" || urlPath[4] === "feeds" || urlPath[4] === "rejected") &&
                  "active"} `}
              >
                SERP Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/insight-stories/feeds`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  5 &&urlPath[3] === "insight-stories"&&
                  (urlPath[4] === "approved" || urlPath[4] === "feeds" || urlPath[4] === "rejected") &&
                  "active"} `}
              >
                Insight Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/individual-feeds`}
                className={`nav-link text-decoration-none text-dark`}
              >
                Individual Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/perplexity-stories/feeds`}
                // className="nav-link text-decoration-none text-dark"
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  5 &&urlPath[3] === "perplexity-stories"&&
                  (urlPath[4] === "approved" || urlPath[4] === "feeds" || urlPath[4] === "rejected") &&
                  "active"} `}
              >
                Perplexity Feeds
              </NavLink>
            </li>
          </ul>
          <div>
            {loading && (
              <div className="text-center p-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {!loading && <EditorPanelRoutes />}
          </div>
        </div>
      </div>
    </div>
  );
};

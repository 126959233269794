import { DataTableCol } from "../data-table/types";

export const MostPostedFeedReportGridCols: DataTableCol[] = [
    {
        title: "Feed Title",
        control: "feed_title",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Count",
        control: "count",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    },
    {
        title: "Feed Created At",
        control: "feed_created_at",
        sortable: true,
        canShowColumn: true,
        isDate: true
    }
];

import { DataTableCol } from "../../../components/data-table/types";

export const NewsSourcesDataGridCols: DataTableCol[] = [
  {
    title: "Serial No",
    control: "serial_no",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "News Platform Type",
    control: "news_platform_type",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "News Platform",
    control: "news_platform",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_MOST_POSTED_FEEDS } from "../../services/report.service";
import { DataTable } from "../data-table";
import moment from "moment";
import dayjs from "dayjs";
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MostPostedFeedReportGridCols } from "./most-posted-feed-report-grid-cols";

interface Props {
  onSelectedUser: (data: any) => void;
  selectedPage: (data: any) => void;
  selectedPageNumber: any;
}

export const MostPostedFeedsReportsPage = (props: Props) => {
  const [userPostData, setUserPostData] = useState<any>();
  const startDate = props?.selectedPageNumber?.start_date ? dayjs(props?.selectedPageNumber?.start_date) : dayjs(moment().add(-30, 'days').format('MM-DD-YYYY'));
  const endDate = props?.selectedPageNumber?.end_date ? dayjs(props?.selectedPageNumber?.end_date) : dayjs(moment().format('MM-DD-YYYY'));

  const [articleStartDate, setArticleStartDate] = useState<any>(startDate);
  const [articleEndDate, setArticleEndDate] = useState<any>(endDate);

  const [userPostReportPageCount, setUserPostReportPageCount] = useState<any>({
    limit: 10,
    page: props?.selectedPageNumber?.page ? props?.selectedPageNumber?.page : 1,
    start_date: dayjs(articleStartDate).format('YYYY-MM-DD'),
    end_date: dayjs(articleEndDate).format('YYYY-MM-DD')
  });
  useEffect(() => {
    getUserPostReport();
  }, []);

  const getUserPostReport = () => {
    const data = userPostReportPageCount
    data.start_date = dayjs(data.start_date).format('YYYY-MM-DD');
    data.end_date = dayjs(data.end_date).format('YYYY-MM-DD');
    // setLoading(true);
    GET_MOST_POSTED_FEEDS(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          setUserPostData(res?.data)
          // setLoading(false);
        }
      }
    });
  };

  const onPageChange = (data: any) => {
    const pageData = userPostReportPageCount;
    pageData.limit = data;
    setUserPostReportPageCount(pageData);
    getUserPostReport();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = userPostReportPageCount;
    pageData.page = data;
    props.selectedPage(userPostReportPageCount)
    setUserPostReportPageCount(pageData);
    getUserPostReport();
  };

  const onActions = (data: any) => {
    if (data.type === "link") {
      props.onSelectedUser(data?.data);
    }
  }

  const onChangeUserPostDateTime = (date: any) => {
    if (date[0] && date[1]) {
      setUserPostData([]);
      const pageData = userPostReportPageCount;
      pageData.page = 1;
      pageData.start_date = dayjs(date[0]).format('MM-DD-YYYY');
      pageData.end_date = dayjs(date[1]).format('MM-DD-YYYY');
      setUserPostReportPageCount({...pageData});
      getUserPostReport();
    }
  }
  return (
    <div className="row">
      <div className="ps-2 mb-3">
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['SingleInputDateRangeField']}>
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                name="allowedRange"
                onChange={(newValue) => onChangeUserPostDateTime(newValue)}
                value={[articleStartDate, articleEndDate]}
              />
            </DemoContainer>
          </LocalizationProvider>
        </StyledEngineProvider>
      </div>
      {(userPostData?.length!==0) && <DataTable
        tableData={userPostData}
        TableCols={MostPostedFeedReportGridCols}
        onActions={onActions}
        onPageChange={(e) => onPageChange(e)}
        pageCount={userPostReportPageCount}
        onSelectPageChange={(e) => onSelectPageinator(e)}
      />}
    </div>
  );
};

import { DataTableCol } from "../../../components/data-table/types";

export const WaitlistUsersDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Occupation",
    control: "occupation_title",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "status",
    control: "status",
    sortable: true,
    canShowColumn: true,
    isTextOverflowHide: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];

import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { DataTable } from "../../../components/data-table";
import { RolesDataGridCols } from "./roles-data-grid-cols";
import {
  CREATEROLE,
  DELETEROLE,
  GETROLES,
  UPDATEROLE,
} from "../../../services/roles.service";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../components/form-builder/form";
import CustomModel from "../../../components/modal/custom-model";

export const RolesPage = () => {
  const [rolesData, setRolesData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [roleFormData, setRoleFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Role",
      name: "role_name",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter role",
      error: "Please enter role",
      submitButtonName: "ADD ROLE",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
  ]);

  const breadcumInfo = [
    {
      label: "Roles",
      value: "Roles",
      routerLink: "/dashboard/roles",
      isActive: true,
    },
  ];

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Role",
        name: "role_name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter role",
        error: "Please enter role",
        submitButtonName: "ADD ROLE",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setRoleFormData(formDataFields);
    getRolesList(pageCount);
  }, []);

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const getRolesList = (pageCount: any) => {
    setLoading(true);
    GETROLES(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setRolesData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const ShowPopup = () => {
    setIsEdit(false);
    setShowModal(true);
  };
  const onSubmitRole = () => {
    const data = {
      role_name: roleName,
      is_active: Number(isActive),
    };
    setLoading(true);
    CREATEROLE(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setRoleName("");
          setIsActive(1);
          getRolesList(pageCount);
          setLoading(false);
          setShowModal(false);
        }
      }
    });
  };

  const onActions = (data: any) => {
    setSelectedRole(data?.data);
    if (data?.type === "edit") {
      setIsEdit(true);
      const newData = roleFormData;
      newData[0].submitButtonName = "UPDATE ROLE";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setRoleFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.user_name} role!`
      );
      setShowDeleteModal(true);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getRolesList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getRolesList(pageData);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    if (isEdit) {
      formData.uuid = selectedRole.uuid;
      setLoading(true);
      UPDATEROLE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getRolesList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      CREATEROLE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getRolesList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETEROLE(selectedRole?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getRolesList(pageCount);
          setLoading(false);
        }
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
            <div>
              <div className="mt-3 pe-1">
                <button
                  className="custom-bg-btn rounded border-0 text-white me-2"
                  onClick={ShowPopup}
                >
                  Create Role
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">
            <DataTable
              tableData={rolesData}
              TableCols={RolesDataGridCols}
              onActions={onActions}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
            {showDeleteModal && (
              <CustomModel
                modalTitle={"Delete"}
                ismodalShow={showDeleteModal}
                modalStatus={getModalStatus}
                modalHeaderText={deleteModalBody}
                modalType={"delete"}
                deleteStatus={onDeleteUser}
              />
            )}
            <div className="modal" style={{ display: showModal ? "block" : "none" }}>
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content modal-box-shadow">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? "Update Role" : "Create Role"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleModalClosed}
                    ></button>
                  </div>
                  <div className="modal-body text-start">
                    <DymamicForm
                      formControls={""}
                      userFormData={roleFormData}
                      columns={1}
                      cancelRoute={""}
                      isEdit={isEdit}
                      onChildEvent={(e) => onSubmitForm(e)}
                      customClass={"display-block text-center col-12"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>


    </div>
  );
};

import React, { useEffect, useState } from "react";
import { DataTable } from "../../../components/data-table";
import { SocialSourcesDataGridCols } from "./social-sources-data-grid-cols";
import { Breadcum } from "../../../components/breadcum";
import { GETINTERESTS } from "../../../services/interests.service";
import { toast } from "react-toastify";
import { GETSUBINTERESTS } from "../../../services/sub-interests.service";
import { GETSOCIALTYPES } from "../../../services/lookup.service";
import {
  ADDSOCIALSOURCE,
  DELETESOCIALSOURCE,
  FETCH_INDIVIDUAL_FEED,
  GETSOCIALSOURCES,
  UPDATESOCIALSOURCE,
} from "../../../services/social-sources.service";
import { Search } from "../../../components/search/search";

export const SocialServicesPage = () => {
  const [breadcumInfo, setBreadcumInfo] = useState<any>([
    {
      label: "Social Sources",
      value: "Social Sources",
      routerLink: "/dashboard/social-sources",
      isActive: true,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [interestsList, setInterestsList] = useState<any>([]);
  const [subInterestList, setSubInterestList] = useState<any>([]);
  const [socialTypesList, setSocialTypesList] = useState<any>([]);
  const [socialSourcesData, setSocialSourcesData] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [socialSourcesFormData, setSocialSourcesFormData] = useState<any>();
  const [selectedIntrest, setSelectedIntrest] = useState<any>("");
  const [selectedSubIntrest, setSelectedSubIntrest] = useState<any>("");
  const [selectedSocialType, setSelectedSocialType] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [account, setAccount] = useState<any>("");
  const [accountURL, setAccountURL] = useState<any>("");
  const [selectedData, setSelectedData] = useState<any>();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Interest Name",
        name: "interest_name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter interest name",
        error: "Please enter interest name",
        submitButtonName: "ADD INTEREST",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Keywords",
        name: "interest_keywords",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter keywords",
        error: "Please enter valid keywords",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setSocialSourcesFormData(formDataFields);
    getSocialSourceList();
    getInterestsList();
    getSocialTypes();
  }, []);

  const getSocialSourceList = () => {
    setLoading(true);
    GETSOCIALSOURCES(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setSocialSourcesData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const getInterestsList = () => {
    setLoading(true);
    GETINTERESTS("").then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setInterestsList(res.data?.data);
          setLoading(false);
        }
      }
    });
  };

  const getSocialTypes = () => {
    setLoading(true);
    GETSOCIALTYPES().then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setSocialTypesList(res.data);
          setLoading(false);
        }
      }
    });
  };

  const onChangeIntrest = (event: any) => {
    if (event) {
      setSelectedIntrest(event);
      setLoading(true);
      GETSUBINTERESTS({ interest_uuid: event }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setSubInterestList(res?.data?.data);
            setLoading(false);
          }
        }
      });
    }
  };

  const onChangeSubIntrest = (event: any) => {
    if (event.target.value) {
      setSelectedSubIntrest(event.target.value);
    }
  };

  const onChangeStauts = (event: any) => {
    if (event.target.value) {
      setSelectedStatus(event.target.value);
    }
  };
  const onChangeSocialType = (event: any) => {
    if (event.target.value) {
      setSelectedSocialType(event.target.value);
    }
  };
  const onChangeAccount = (event: any) => {
    if (event.target.value) {
      setAccount(event.target.value);
    }
  };
  const onChangeAccountURL = (event: any) => {
    if (event.target.value) {
      setAccountURL(event.target.value);
    }
  };
  const onActions = (data: any) => {
    setSelectedData(data?.data);
    if (data.type === "edit") {
      setIsEdit(true);
      onChangeIntrest(data.data.social_interest_uuid);
      setSelectedIntrest(data.data.social_interest_uuid);
      setSelectedSubIntrest(data.data.social_sub_interest_uuid);
      setSelectedSocialType(data.data.social_platform);
      setAccount(data.data.social_account_name);
      setAccountURL(data.data.social_account_url);
      setSelectedStatus(data.data.selectedData);
      // const newData = userFormData;
      // newData[0].submitButtonName = "UPDATE INTEREST";
      // newData.forEach((element: any) => {
      //   element.value =
      //     element.name === "interest_keywords"
      //       ? data.data[element.name]
      //         ? data.data[element.name]?.toString()
      //         : ""
      //       : data.data[element.name];
      // });
      // setUserFormData([...newData]);
    } else if (data.type === "delete") {
      setLoading(true);
      DELETESOCIALSOURCE(data.data.uuid).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          getSocialSourceList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    } else if (data.type === "refresh") {
      setLoading(true);
      const dataObj = {
        social_platform: data.data.social_platform,
        social_account_url: data.data.social_account_url,
        social_account_name: data.data?.social_account_name,
      };
      FETCH_INDIVIDUAL_FEED(dataObj).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          getSocialSourceList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    }
  };

  const onsubmit = () => {
    const data: any = {
      social_platform: selectedSocialType,
      social_account_name: account,
      // social_interest_uuid: selectedIntrest,
      // social_sub_interest_uuid: selectedSubIntrest,
      is_active: Number(selectedStatus),
      social_account_url: accountURL,
    };
    if (isEdit) {
      data.uuid = selectedData.uuid;
      setLoading(true);
      UPDATESOCIALSOURCE(data).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            getSocialSourceList();
            toast.success(res.message);
            setIsEdit(false);
            setSubInterestList([]);
            setSelectedIntrest("");
            setSelectedSubIntrest("");
            setSelectedSocialType("");
            setAccount("");
            setAccountURL("");
            setSelectedStatus("1");
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      ADDSOCIALSOURCE(data).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            getSocialSourceList();
            toast.success(res.message);
            setIsEdit(false);
            setSubInterestList([]);
            setSelectedIntrest("");
            setSelectedSubIntrest("");
            setSelectedSocialType("");
            setAccount("");
            setAccountURL("");
            setSelectedStatus("1");
            setLoading(false);
          }
        }
      });
    }
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getSocialSourceList();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getSocialSourceList();
  };

  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getSocialSourceList();
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Breadcum breadcumList={breadcumInfo}></Breadcum>
        </div>
        <div className="col-12 px-4">
          <form>
            <div className="row">
              {/* <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mt-1">
                  Select Interest
                </label>
                <select
                  className="form-select"
                  onChange={(e) => onChangeIntrest(e.target.value)}
                  value={selectedIntrest}
                >
                  <option value="">Select Interests</option>
                  {interestsList.map((intrest: any, index: number) => {
                    return (
                      <option key={index} value={intrest.uuid}>
                        {intrest.interest_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mb-1">
                  Select Sub Interest
                </label>
                <select
                  className="form-select"
                  onChange={(e) => onChangeSubIntrest(e)}
                  value={selectedSubIntrest}
                >
                  <option value="">Select Sub Interests</option>
                  {subInterestList?.map((intrest: any, index: number) => {
                    return (
                      <option key={index} value={intrest.uuid}>
                        {intrest.sub_interest_name}
                      </option>
                    );
                  })}
                </select>
              </div> */}
              <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mb-1">
                  Social Type
                </label>
                <select
                  className="form-select"
                  onChange={(e) => onChangeSocialType(e)}
                  value={selectedSocialType}
                >
                  <option value="">Select Social Type</option>
                  {socialTypesList?.map((type: any, index: number) => {
                    return (
                      <option key={index} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 ">
                <label className="d-flex justify-content-start mb-1">
                  Account Name
                </label>
                <input
                  type="text"
                  name="account"
                  className="form-control"
                  placeholder="Enter Account"
                  onChange={(e) => onChangeAccount(e)}
                  value={account}
                />
              </div>
              <div className="col-md-3 ">
                <label className="d-flex justify-content-start mb-1">
                  Account URL
                </label>
                <input
                  type="text"
                  name="accountURL"
                  className="form-control"
                  placeholder="Enter account URL"
                  onChange={(e) => onChangeAccountURL(e)}
                  value={accountURL}
                />
              </div>
              <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mb-1">
                  Status
                </label>
                <select
                  className="form-select"
                  onChange={(e) => onChangeStauts(e)}
                  value={selectedStatus}
                >
                  <option value="1">Active</option>
                  <option value="0">In-Active</option>
                </select>
              </div>
              <div className="col-md-3 my-3">
                <div className="d-flex justify-content-start ">
                  <button
                    type="button"
                    className="custom-bg-btn rounded border-0 text-white px-4 py-1"
                    onClick={() => onsubmit()}
                  >
                    {isEdit ? "UPDATE SOCIAL SOURCE" : "ADD SOCIAL SOURCE"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="col-12 px-4 my-2">
          <div className="d-flex justify-content-start">
            Accounts already added :
          </div>
          <div className="d-flex justify-content-start">
            <input type="radio" className="form-check me-2" />
            @elonmust twitter
            <input type="radio" className="form-check mx-2" />
            @elonmust twitter
            <input type="radio" className="form-check mx-2" />
            @elonmust twitter
          </div>
        </div>
        <div className="d-flex justify-content-start ms-2 ps-3 my-2">
          <button className="btn btn-dark px-4">UPDATE</button>
        </div> */}

        <div className="row my-2">
          <div className="col-12 mt-2 ms-2">
            <Search onSearch={(e) => onSearch(e)} />
          </div>
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <div className="col-12">
              <DataTable
                tableData={socialSourcesData}
                TableCols={SocialSourcesDataGridCols}
                onActions={(e) => onActions(e)}
                onPageChange={(e) => onPageChange(e)}
                pageCount={pageCount}
                onSelectPageChange={(e) => onSelectPageinator(e)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import API from "../utils/axios";

export const GETROLES = (data: any): Promise<any> => {
  return API.get(`/roles/fetch`, { params: data });
};

export const CREATEROLE = (data: any): Promise<any> => {
  return API.post(`/roles/create`, data);
};

export const UPDATEROLE = (data: any): Promise<any> => {
  return API.put(`/roles/update`, data);
};

export const DELETEROLE = (uuid: string): Promise<any> => {
  return API.delete(`/roles/delete/${uuid}`);
};

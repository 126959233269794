// export const BASE_URL = "http://localhost:3000/"

//UAT
// export const BASE_URL = "http://3.128.0.228/podiorama"

export const BASE_URL = "https://leaderx.api.ldrx.ai/api";//"http://65.0.9.242:8000/api";

const appConfig = {
  BASE_URL: BASE_URL,
};
export default appConfig;

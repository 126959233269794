import axios from "axios";
import appConfig from "../config/constant";

const API = axios.create({
  baseURL: appConfig.BASE_URL,
});
const loginUserData: any = sessionStorage.getItem("loginUserData");

// if (loginUserData) {
API.interceptors.request.use(function(config: any) {
  // const token = localStorage.getItem("user_session");
  config.headers["Content-Type"] = "application/json";
  //   config.headers.Session = token ? `${token}` : "";
  return config;
});

API.interceptors.response.use(
  (response) => {
    if (response.data.data) {
      return response.data;
    } else {
      return response.data ? response.data : {};
    }
  },
  (error: any) => {
    // if (error.response.status === 401 && error?.response?.data?.message !== 'invalid user credentials') {
    //     window.location.href = '/';
    // }

    if (
      error?.response?.data?.error ===
        "Un Authorized Access, In-valid Token!" ||
      error?.response?.data?.error === "Session Token Is Missing!"
    ) {
      localStorage.clear();
      const userToken: any = sessionStorage.getItem("userToken");
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
      if (!userToken) {
        window.location.href = "/";
      }
    }
    return error.response && error.response.data ? error.response.data : {};
  }
);
// }
export default API;

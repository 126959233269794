import React, { useEffect, useState } from "react";
import { Search } from "../../../../components/search/search";
import { DataTable } from "../../../../components/data-table";
import { PendingUsersDataGridCols } from "./pending-uses-data-grid-cols";
import { DELETEEDITORS, GETUSERS } from "../../../../services/users.service";
import { toast } from "react-toastify";
import CustomModel from "../../../../components/modal/custom-model";
import { SENDINVITATION } from "../../../../services/common.service";

export const InActiveUsersList = () => {
  const [loading, setLoading] = useState(false);
  const [pendingUserData, setPendingUserData] = useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
    is_active: "pending",
  });
  useEffect(() => {
    getPendingUserData();
  }, []);

  const getPendingUserData = () => {
    setLoading(true);
    GETUSERS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setPendingUserData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const onActions = (data: any) => {
    if (data?.type === "delete") {
      setSelectedUser(data?.data);
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.user_name} user!`
      );
      setShowDeleteModal(true);
    } else if (data?.type === "resend") {
      const postData = { user_email: data?.data?.user_email };
      setLoading(true);
      SENDINVITATION(postData).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getPendingUserData();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getPendingUserData();
  };

  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getPendingUserData();
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETEEDITORS(selectedUser?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getPendingUserData();
          setLoading(false);
        }
      }
    });
  };
  return (
    <div className="container-fluid px-0">
      <div className="row px-0">
        <div className="col-12 px-0">
          <div className="ps-2">
            <Search onSearch={(e) => onSearch(e)} />
          </div>
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <DataTable
              tableData={pendingUserData}
              TableCols={PendingUsersDataGridCols}
              onActions={(e) => onActions(e)}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
          )}
        </div>
      </div>
      {showDeleteModal && (
        <CustomModel
          modalTitle={"Delete"}
          ismodalShow={showDeleteModal}
          modalStatus={getModalStatus}
          modalHeaderText={deleteModalBody}
          modalType={"delete"}
          deleteStatus={onDeleteUser}
        />
      )}
    </div>
  );
};

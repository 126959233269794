import API from "../utils/axios";

export const GET_EVENTS = (data: any): Promise<any> => {
  return API.get(`/events/fetch`, { params: data });
};

export const CREATE_EVENT = (data: any): Promise<any> => {
  return API.post(`/events/create`, data);
};

export const UPDATE_EVENT = (data: any): Promise<any> => {
  return API.put(`/events/update`, data);
};

export const DELETE_EVENT = (uuid: string): Promise<any> => {
  return API.delete(`/events/delete/${uuid}`);
};

import { Breadcum } from "../../../../components/breadcum";
import { NavLink, useLocation } from "react-router-dom";
import { EditorPanelRoutes } from "../../../../Routes/editor-panel";
import { GETSOCIALFEEDS } from "../../../../services/editor-panel.service";
import { toast } from "react-toastify";
import { useState } from "react";
import { SERPRoutes } from "../../../../Routes/SERP";

export const SERPPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const urlPath = location?.pathname.split("/");

  const breadcumInfo = [
    {
      label: "Editor Panel",
      value: "Editor Panel",
      routerLink: "/dashboard/editor-panel",
      isActive: true,
    },
  ];

  const onRfresh = () => {
    setLoading(true);
    GETSOCIALFEEDS().then((res: any) => {
      if (res.status === "fail") {
        setLoading(false);
        toast.error(res.error);
      } else {
        if (res) {
          toast.success(res.message);
          setLoading(false);
        }
      }
    });
  };
  return (
    <div className="container-fluid">
      {/* <div className="row">
        <div className="col-6">
          <Breadcum breadcumList={breadcumInfo} />
        </div>
        <div className="col-6 text-end ">
          <button
            className="btn btn-outline-dark me-2 my-2"
            type="submit"
            onClick={onRfresh}
          >
            <i className="bi bi-arrow-clockwise" title="Refresh"></i>
          </button>
        </div>
      </div> */}
      <div className="col-12">
        <div className="px-2 mx-1">
          <ul className="nav nav-tabs my-3 ">
            {/* <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/new-stories`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                New Stories
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/approved-stories`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                Approved Stories
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/SREP-stories/feeds`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/SREP-stories/approved`}
                className="nav-link text-decoration-none text-dark"
              >
                Approved
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/SREP-stories/rejected`}
                className="nav-link text-decoration-none text-dark"
              >
                Rejected
              </NavLink>
            </li>
          </ul>
          <div>
            {loading && (
              <div className="text-center p-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {!loading && <SERPRoutes />}
          </div>
        </div>
      </div>
    </div>
  );
};

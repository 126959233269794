import { useEffect, useState } from "react";
import { DymamicForm } from "../../../../../components/form-builder/form";
import { SubUserFormControls } from "../../../interests/form-controls";
import { NavLink } from "react-router-dom";
import { GETNEWSTORIES } from "../../../../../services/editor-panel.service";
import { toast } from "react-toastify";
import { EditorPanel } from "../../../../../components/editor-panel/editor-panel";
import { GETINTERESTS } from "../../../../../services/interests.service";
import { GETSUBINTERESTS } from "../../../../../services/sub-interests.service";

export const InsightRejectedPage = () => {
  const [approvedStoriesList, setApprovedStoriesList] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [interestsList, setInterestsList] = useState<any>([]);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [searchForm, setSearchForm] = useState<any>([
    {
      title: "input",
      control: "",
      type: "input",
      label: "Search by Title",
      name: "feed_title",
      placeholder: "Search by Title",
      value: "",
      regex: "",
      required: false,
      submitButtonName: "SEARCH",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Interests",
      name: "feed_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: false,
      placeholder: "Please select interests",
      error: "Please select interests",
      submitButtonName: "SEARCH",
      isParendDropDown: true,
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Sub Interests",
      name: "feed_subinterest_uuid",
      option: [],
      value: "",
      regex: "",
      required: false,
      placeholder: "Please select sub interests",
      error: "Please select sub interests",
    },
    {
      title: "input",
      control: "",
      type: "checkbox",
      label: "Interests ",
      name: "is_interest_empty",
      value: "",
      regex: "",
      required: false,
      column: 1,
    },
    {
      title: "input",
      control: "",
      type: "checkbox",
      label: "Sub Interests",
      name: "is_sub_interest_empty",
      value: "",
      regex: "",
      required: false,
      column: 2,
    },
    // {
    //   title: "input",
    //   control: "",
    //   type: "checkbox",
    //   label: "Double Thumbs Up",
    //   name: "status",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   column: 2,
    // },
    // {
    //   title: "input",
    //   control: "",
    //   type: "checkbox",
    //   label: "Star Feed",
    //   name: "is_star_feed",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   column: 1,
    // },
  ]);
  const loginUserData: any = sessionStorage.getItem("loginUserData");
  const loginUserInfo = JSON.parse(loginUserData);
  useEffect(() => {
    getApprovedStoriesList(pageCount);
    getInterestsList();
  }, []);

  const onSubmitForm = (e: any) => {
    let pageData: any = pageCount;
    pageData = { ...pageData, ...e?.formData };
    setPageCount(pageData);
    getApprovedStoriesList(pageData);
  };

  const getApprovedStoriesList = (pageCount: any) => {
    setLoading(true);
    const data = pageCount;
    data.is_insight_news = true;
    data.status = 'REJECTED';
    // data.feed_source_platform = 'SOCIAL'
    data.system_user_uuid =
      loginUserInfo?.user_role === "EDITOR" ? loginUserInfo?.uuid : "";
    GETNEWSTORIES(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setApprovedStoriesList(res.data);
          setLoading(false);
        }
      }
    });
  };

  const getInterestsList = () => {
    setLoading(true);
    GETINTERESTS({ limit: 0, page: 1 }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          const data = res.data?.data;
          data.unshift({
            id: -1,
            interest_keywords: [],
            interest_name: "ALL",
            is_active: 1,
            label: "ALL",
            sub_interest_count: -1,
            uuid: "ALL",
            value: "ALL",
          });
          setInterestsList(data);
          data.forEach((element: any) => {
            element.label = element?.interest_name;
            element.value = element?.uuid;
          });
          const searchFormData = searchForm;
          searchFormData[0].option = res.data?.data;
          setSearchForm([...searchFormData]);
          setLoading(false);
        }
      }
    });
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getApprovedStoriesList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getApprovedStoriesList(pageData);
  };
  const onSelectIntrest = (event: any) => {
    if (event) {
      setLoading(true);
      GETSUBINTERESTS({ interest_uuid: event }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            const data = res.data?.data;
            data.forEach((element: any) => {
              element.label = element?.sub_interest_name;
              element.value = element?.uuid;
            });
            const searchFormData = searchForm;
            searchFormData[1].option = data;
            setSearchForm([...searchFormData]);
            setLoading(false);
          }
        }
      });
    }
  };
  return (
    <div className="container-fluid px-0">
      <div className="row mb-4">
        <div className="col-12 px-2 mx-1 mb-3">
          <DymamicForm
            formControls={""}
            userFormData={searchForm}
            columns={6}
            cancelRoute={""}
            isEdit={false}
            onChildEvent={(e) => onSubmitForm(e)}
            onParentDropdownEvent={(e) => onSelectIntrest(e)}
            isNotEmptyFormAfterSubmit={true}
          />
          {/* <span>
            <input type="checkbox" /> Interest Empty
          </span>
          <span>
            <input type="checkbox" /> Interest Empty
          </span> */}
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <EditorPanel
            storiesData={approvedStoriesList}
            intrestList={interestsList}
            onUpdateStory={() => getApprovedStoriesList(pageCount)}
            onPageChange={(e) => onPageChange(e)}
            pageCount={pageCount}
            onSelectPageChange={(e) => onSelectPageinator(e)}
          />
        )}
      </div>
    </div>
  );
};

import { DymamicForm } from "../../../components/form-builder/form";
import { SubUserFormControls } from "./form-controls";

interface Props {}

export const SubUserForm = (props: Props) => {
  const userFormData = [
    {
      title: "input",
      control: "",
      type: "text",
      label: "name",
      name: "name",
      value: "",
      required: "1",
      placeholder: "Please eneter user name",
      error: "Pease enter valid user name",
    },
    {
      title: "input",
      control: "",
      type: "email",
      label: "User email",
      name: "user_email",
      value: "",
      required: "1",
      placeholder: "Please enter user email",
      error: "Pease enter valid user email",
    },
    {
      title: "input",
      control: "",
      type: "number",
      label: "Phone Number",
      name: "phone_number",
      value: "",
      required: "1",
      placeholder: "Please enter phone number",
      error: "Pease enter valid phone number",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "city",
      name: "city",
      value: "",
      required: "1",
      placeholder: "Please enter the city",
      error: "Pease enter valid city",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Select State",
      name: "state",
      value: "",
      required: "1",
      placeholder: "Please select state",
      error: "Pease enter valid city",
    },
  ];

  const onSubmitForm = () => {};
  return (
    <div className="conatiner-fluid">
      <div className="row">
        <div className="col-12 text-start my-2">
          <h3>Create Sub User</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DymamicForm
            formControls={SubUserFormControls}
            userFormData={userFormData}
            columns={4}
            cancelRoute={"/dashboard/sub-accounts"}
            isEdit={false}
            onChildEvent={() => onSubmitForm()}
          />
          {/* <div className='d-flex justify-content-end my-2'>
                        <button className='custom-bg-btn rounded border-0 text-white me-2' onClick={handleSubmit}>Submit</button>
                        <button className='custom-cancel-btn rounded border-0 px-4 py-1' onClick={onClickCancel}>Cancel</button>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

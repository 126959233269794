import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { GETUSERS } from "../../../services/users.service";

export const ReportsPage = () => {
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>();
  const pageCount = {
    limit: 0,
    page: 1,
  };
  const [activeUserData, setActiveUserData] = useState<any>([]);

  const breadcumInfo = [
    {
      label: "Reports",
      value: "Reports",
      routerLink: "/dashboard/reports",
      isActive: true,
    },
  ];

  useEffect(() => {
    getActiveUserData();
  }, []);

  const getActiveUserData = () => {
    setLoading(true);
    GETUSERS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          const data = res.data?.data;
          data.unshift({
            id: -1,
            label: "ALL",
            uuid: "ALL",
            value: "ALL",
            user_name: 'ALL'
          });
          data.forEach((element: any) => {
            element.user_name = element?.user_name ? element?.user_name : `${element?.user_phone} `
            element.label = element?.name;
            element.value = element?.uuid;
          });
          setActiveUserData([...data]);
          setLoading(false);
        }
      }
    });
  };

  const onSelectInterest = (
    selectedDataList: any,
    selectedData: any,
  ) => {
    const data: any = [];
    if (selectedData.uuid === "ALL") {

    } else {
      selectedDataList.forEach((element: any) => {
        data.push(element.uuid);
      });
      setSelectedUsers([...data])
    }
  };
  const onRemoveInterest = (
    selectedDataList: any,
    selectedData: any,
  ) => {
    const data: any = [];
    if (selectedData.uuid === "ALL") {

    } else {
      selectedDataList.forEach((element: any) => {
        data.push(element.uuid);
      });
      setSelectedUsers([...data])
    }
  };
  const downloadExcel = () => {
    const payload = {
      user_uuids: selectedUsers
    };
    setLoading(true);
    fetch(
      "https://leaderx.api.ldrx.ai/api/users/export_for_you_user",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),

      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = `users_feeds_reports.xlsx`;
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        setLoading(false);
        toast.success(
          "Report Generated successfully", { autoClose: 2000 }
        );
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          "An error occurred while generating reports. Please try again later.", { autoClose: 2000 }
        );
      });
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {activeUserData.length > 0 && <Multiselect
                placeholder={'Select User'}
                options={activeUserData}
                // selectedValues={[]}
                onSelect={(selectedList, selectedItem) =>
                  onSelectInterest(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  onRemoveInterest(selectedList, selectedItem)
                }
                isObject={true}
                avoidHighlightFirstOption={true}
                showCheckbox={true}
                hidePlaceholder={true}
                displayValue="user_name"
                style={{
                  chips: { background: "#074795" },
                  searchBox: {
                    height: "38px",
                    overflow: "hidden",
                    padding: "0px 10px 0px 10px",
                  },
                }}
              />}
            </div>
            <div className="col-6">
              <button
                className="custom-bg-btn rounded border-0 text-white me-2"
                onClick={downloadExcel}
              >
                Download Excel
              </button>
            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">

          </div>
        )}
      </div>
    </div>
  );
};

import { DataTableCol } from "../../../components/data-table/types";

export const SocialSourcesDataGridCols: DataTableCol[] = [
  {
    title: "Serial No ",
    control: "serial_no",
    sortable: true,
    canShowColumn: true,
  },
  //   {
  //     title: "Interest",
  //     control: "intrest",
  //     sortable: true,
  //     canShowColumn: true,
  //   },
  {
    title: "Account Platform ",
    control: "social_platform",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Account name",
    control: "social_account_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    isRefresh: true,
    canShowColumn: true,
  },
];

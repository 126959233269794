import React from "react";
import { Route, Routes } from "react-router-dom";
import { SERPFeedsPage } from "../pages/Dashboard/editor-panel/SERP-feeds/feeds/feeds-stories";
import { SERPApprovedPage } from "../pages/Dashboard/editor-panel/SERP-feeds/approved/SERP-approved-stories";
import { SERPRejectedPage } from "../pages/Dashboard/editor-panel/SERP-feeds/rejected/SERP-rejected-stories";

export const SERPRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<SERPFeedsPage />}></Route>

        <Route
          path="feeds"
          element={<SERPFeedsPage />}
        >
        </Route>
        <Route
          path="approved"
          element={<SERPApprovedPage />}
        ></Route>
        <Route
          path="rejected"
          element={<SERPRejectedPage />}
        ></Route>
      </Routes>
    </div >
  );
};

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  location?: any;
}

export const SidebarPage = (props: Props) => {
  const loginUserData: any = sessionStorage.getItem("loginUserData");
  const [sidebarData, setSidebarData] = useState<any>([]);
  useEffect(() => {
    const loginUserInfo = JSON.parse(loginUserData);
    if (loginUserInfo?.user_role === "EDITOR") {
      const sidebarData: any = [
        {
          name: "Editor Panel",
          route: "/dashboard/editors-panel",
        },
      ];
      setSidebarData([...sidebarData]);
    } else {
      const sidebarData: any = [
        {
          name: "Dashboard",
          route: "/dashboard/home",
        },
        {
          name: "Users",
          route: "/dashboard/users",
        },
        {
          name: "Interests",
          route: "/dashboard/interests",
        },
        // {
        //   name: "Social Sources",
        //   route: "/dashboard/social-sources",
        // },
        // {
        //   name: "News Sources",
        //   route: "/dashboard/news-sources",
        // },
        {
          name: "Editor Panel",
          route: "/dashboard/editors-panel",
        },
        {
          name: "Roles",
          route: "/dashboard/roles",
        },
        {
          name: "Spin Types",
          route: "/dashboard/spin-types",
        },
        {
          name: "Events",
          route: "/dashboard/events",
        },
        {
          name: "Fetch Social Posts",
          route: "/dashboard/fetch-social-posts",
        },
        {
          name: "Waitlist Users",
          route: "/dashboard/waitlist-users",
        },
        {
          name: "Concierge Users",
          route: "/dashboard/concierge",
        },
        {
          name: "Reports",
          route: "/dashboard/reports",
        },
        // {
        //   name: "Fetch Posts",
        //   route: "/dashboard/fetch-posts",
        // },
        // {
        //   name: "Profession",
        //   route: "/dashboard/profession",
        // },
        // {
        //   name: "subscription list",
        //   route: "/dashboard/subscription-list",
        // },
      ];
      setSidebarData([...sidebarData]);
    }
  }, []);
  // const sidebarData: any = [
  //   {
  //     name: "Users",
  //     route: "/dashboard/users",
  //   },
  //   {
  //     name: "Interests",
  //     route: "/dashboard/interests",
  //   },
  //   {
  //     name: "Social Sources",
  //     route: "/dashboard/social-sources",
  //   },
  //   {
  //     name: "News Sources",
  //     route: "/dashboard/news-sources",
  //   },
  //   {
  //     name: "Editor Panel",
  //     route: "/dashboard/editors-panel",
  //   },
  //   {
  //     name: "Roles",
  //     route: "/dashboard/roles",
  //   },
  // ];

  return (
    <div>
      <div className="sidebar">
        <ul className="nav-list px-0">
          {sidebarData.map((data: any, index: number) => (
            <NavLink
              key={index}
              to={data.route}
              className="text-decoration-none  text-dark sidemenu-list-item"
            >
              <li className="py-2 pe-5 text-start ps-3 sidemenu-item">
                {data.name}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

import { DataTableCol } from "../data-table/types";

export const UserPostReportGridCols: DataTableCol[] = [
    {
        title: "Name",
        control: "user_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Email",
        control: "user_email",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Phone",
        control: "user_phone",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Count",
        control: "post_count",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    }
];

import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { DataTable } from "../../../components/data-table";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../components/form-builder/form";
import CustomModel from "../../../components/modal/custom-model";
import { EventsDataGridCols } from "./events-data-grid-cols";
import {
  CREATE_EVENT,
  DELETE_EVENT,
  GET_EVENTS,
  UPDATE_EVENT,
} from "../../../services/events.service";

export const EventsPage = () => {
  const [eventsData, setEventsData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eventsName, setEventsName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [eventFormData, setEventFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Title",
      name: "event_title",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter title",
      error: "Please enter title",
      submitButtonName: "ADD EVENT",
    },
    {
      title: "input",
      control: "",
      type: "number",
      label: "Title",
      name: "order_no",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter order number",
      error: "Please enter order number",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Date",
      name: "event_date",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter event date",
      error: "Please enter event date",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Description",
      name: "event_description",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter description",
      error: "Please enter description",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
  ]);

  const breadcumInfo = [
    {
      label: "Events",
      value: "Events",
      routerLink: "/dashboard/events",
      isActive: true,
    },
  ];

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Title",
        name: "event_title",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter title",
        error: "Please enter title",
        submitButtonName: "ADD EVENT",
      },
      {
        title: "input",
        control: "",
        type: "number",
        label: "Order",
        name: "order_no",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter order number",
        error: "Please enter order number",
      },
      {
        title: "input",
        control: "",
        type: "date",
        label: "Date",
        name: "event_date",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter event date",
        error: "Please enter event date",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Description",
        name: "event_description",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter description",
        error: "Please enter description",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setEventFormData(formDataFields);
    getEventsList(pageCount);
  }, []);

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const getEventsList = (pageCount: any) => {
    setLoading(true);
    GET_EVENTS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setEventsData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const ShowPopup = () => {
    const newData = eventFormData;
    newData[0].submitButtonName = "ADD EVENT";
    newData.forEach((element: any) => {
      element.value = "";
    });
    setEventFormData([...newData]);
    setIsEdit(false);
    setShowModal(true);
  };

  const onActions = (data: any) => {
    setSelectedEvent(data?.data);
    if (data?.type === "edit") {
      setIsEdit(true);
      const newData = eventFormData;
      newData[0].submitButtonName = "UPDATE EVENT";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setEventFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.event_title} event!`
      );
      setShowDeleteModal(true);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getEventsList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getEventsList(pageData);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    if (isEdit) {
      formData.uuid = selectedEvent.uuid;
      setLoading(true);
      UPDATE_EVENT(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getEventsList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      CREATE_EVENT(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getEventsList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETE_EVENT(selectedEvent?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getEventsList(pageCount);
          setLoading(false);
        }
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
            <div>
              <div className="mt-3 pe-1">
                <button
                  className="custom-bg-btn rounded border-0 text-white me-2"
                  onClick={ShowPopup}
                >
                  Create Event
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">
            <DataTable
              tableData={eventsData}
              TableCols={EventsDataGridCols}
              onActions={onActions}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
            {showDeleteModal && (
              <CustomModel
                modalTitle={"Delete"}
                ismodalShow={showDeleteModal}
                modalStatus={getModalStatus}
                modalHeaderText={deleteModalBody}
                modalType={"delete"}
                deleteStatus={onDeleteUser}
              />
            )}
            <div
              className="modal"
              style={{ display: showModal ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content modal-box-shadow">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? "Update Event" : "Create Event"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleModalClosed}
                    ></button>
                  </div>
                  <div className="modal-body text-start">
                    <DymamicForm
                      formControls={""}
                      userFormData={eventFormData}
                      columns={1}
                      cancelRoute={""}
                      isEdit={isEdit}
                      onChildEvent={(e) => onSubmitForm(e)}
                      customClass={"display-block text-center col-12"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import { DataTableCol } from "../../../../components/data-table/types";

export const SubInterestsDataGridCols: DataTableCol[] = [
  {
    title: "Serial No",
    control: "serial_no",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Sub Interest Name",
    control: "sub_interest_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Ranking",
    control: "ranking",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Keywords",
    control: "sub_interest_keywords",
    sortable: true,
    canShowColumn: true,
    isArrayToString: true,
  },

  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];

import { DataTableCol } from "../../../../components/data-table/types";

export const InterestsDataGridCols: DataTableCol[] = [
  {
    title: "Serial No ",
    control: "serial_no",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Interest Name",
    control: "interest_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Keywords",
    control: "interest_keywords",
    sortable: true,
    canShowColumn: true,
    isArrayToString: true,
  },
  {
    title: "Sub Interests",
    control: "sub_interest_count",
    sortable: true,
    canShowColumn: true,
    isLink: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];

import API from "../utils/axios";

export const GETINTERESTS = (data: any): Promise<any> => {
  return API.get(`/interests/fetch`, { params: data });
};

export const ADDINTEREST = (data: any): Promise<any> => {
  return API.post(`/interests/create`, data);
};

export const UPDATEINTERESTS = (data: any): Promise<any> => {
  return API.put(`/interests/update`, data);
};

export const DELETEINTERESTS = (uuid: string): Promise<any> => {
  return API.delete(`/interests/delete/${uuid}`);
};

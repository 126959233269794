import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { DataTable } from "../../../components/data-table";
import { NewsSourcesDataGridCols } from "./news-sources-data-grid-cols";
import { GETNEWSPLATFORMTYPE } from "../../../services/lookup.service";
import { toast } from "react-toastify";
import {
  ADDNEWSSOURCE,
  DELETENEWSSOURCE,
  GETNEWSSOURCES,
  UPDATENEWSSOURCE,
} from "../../../services/news-sources.service";
import { DymamicForm } from "../../../components/form-builder/form";
import CustomModel from "../../../components/modal/custom-model";
import { Search } from "../../../components/search/search";

export const TransactionsPage = () => {
  const breadcumInfo = [
    {
      label: "News Sources",
      value: "News Sources",
      routerLink: "/dashboard/news-sources",
      isActive: true,
    },
  ];
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [newsPlatFormTypesList, setNewsPlatFormTypesList] = useState<any>([]);
  const [newsSourcesData, setNewsSourcesData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [newsSourcesFormData, setNewsSourcesFormData] = useState<any>([
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Platform type",
      name: "news_platform_type",
      value: "",
      required: true,
      regex: "",
      option: [],
      maxLength: "",
      placeholder: "Select platform type",
      error: "Please select platform type",
      submitButtonName: "ADD NEWS SOURCE",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Platform",
      name: "news_platform",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Please enter platform",
      error: "Please enter valid platform",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
  ]);

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Platform type",
        name: "news_platform_type",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Select platform type",
        error: "Please select platform type",
        submitButtonName: "ADD NEWS SOURCE",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Platform",
        name: "news_platform",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter platform",
        error: "Please enter valid platform",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setNewsSourcesFormData(formDataFields);
    getNewsSourcesList();
    getNewsPlatFormTypes();
  }, []);

  const getNewsSourcesList = () => {
    setLoading(true);
    GETNEWSSOURCES(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setNewsSourcesData(res.data);
        }
        setLoading(false);
      }
    });
  };
  const getNewsPlatFormTypes = () => {
    setLoading(true);
    GETNEWSPLATFORMTYPE().then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          const data = res.data;
          const formData = newsSourcesFormData;
          data.forEach((element: any) => {
            if (formData[0].option) {
              formData[0].option.push({ label: element, value: element });
            }
          });
          setNewsSourcesFormData([...formData]);
          setNewsPlatFormTypesList(res.data);
          setLoading(false);
        }
      }
    });
  };
  const onActions = (data: any) => {
    setSelectedData(data?.data);
    if (data.type === "edit") {
      setIsEdit(true);
      const newData = newsSourcesFormData;
      newData[0].submitButtonName = "UPDATE NEWS SOURCE";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setNewsSourcesFormData([...newData]);
    } else if (data.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.news_platform} news source!`
      );
      setShowDeleteModal(true);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getNewsSourcesList();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getNewsSourcesList();
  };
  const onSubmitForm = (event: any) => {
    if (event.type === "create") {
      setLoading(true);
      ADDNEWSSOURCE(event?.formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            getNewsSourcesList();
            setIsEdit(false);
            setLoading(false);
          }
        }
      });
    } else {
      event.uuid = selectedData?.uuid;
      setLoading(true);
      UPDATENEWSSOURCE(event?.formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            getNewsSourcesList();
            setIsEdit(false);
            setLoading(false);
          }
        }
      });
    }
  };

  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getNewsSourcesList();
  };

  const onDelete = () => {
    setLoading(true);
    DELETENEWSSOURCE(selectedData.uuid).then((res: any) => {
      if (res.code === 200) {
        toast.success(res?.message);
        getNewsSourcesList();
        setLoading(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Breadcum breadcumList={breadcumInfo} />
        </div>
        <div className="col-12 px-4">
          {newsSourcesFormData && (
            <DymamicForm
              formControls={""}
              userFormData={newsSourcesFormData}
              columns={4}
              cancelRoute={""}
              isEdit={isEdit}
              onChildEvent={(e) => onSubmitForm(e)}
            />
          )}
          {/* <form>
            <div className="row">
              <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mt-1">
                  Select Interest
                </label>
                <select className="form-select">
                  <option className="">Select Interest</option>
                  <option className="Interest1">Interest 1</option>
                  <option className="Interest2">Interest 2</option>
                </select>
              </div>
              <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mb-1">
                  Select Sub Interest
                </label>
                <select className="form-select">
                  <option className="">Select Sub Interest</option>
                  <option className="Sub Interest1"> Sub Interest 1</option>
                  <option className="Sub Interest2"> Sub Interest 2</option>
                </select>
              </div>
              <div className="col-md-3 my-2 my-md-0">
                <label className="d-flex justify-content-start mb-1">
                  Select News Type
                </label>
                <select className="form-select">
                  <option className="">Select News Type</option>
                  <option className="Online">Online</option>
                  <option className="Offline">Offline</option>
                  <option className="Blog">Blog</option>
                </select>
              </div>
              <div className="col-md-3 my-3 my-md-0">
                <label className="d-flex justify-content-start mb-1">
                  Enter Account/URL
                </label>
                <input
                  type="text"
                  name="account"
                  className="form-control"
                  placeholder="Enter Account/URL"
                />
              </div>
              <div className="col-md-3 my-3">
                <div className="d-flex justify-content-start">
                  <button className="btn btn-dark px-4 py-1">ADD</button>
                </div>
              </div>
            </div>
          </form> */}
        </div>
        {/* <div className="col-12 px-4 my-2">
          <div className="d-flex justify-content-start">
            Places already added :
          </div>
          <div className="d-flex justify-content-start">
            <input type="radio" className="form-check me-2" />
            NY Times List.
            <input type="radio" className="form-check mx-2" />
            NY Times List.
            <input type="radio" className="form-check mx-2" />
            NY Times List.
          </div>
        </div>
        <div className="d-flex justify-content-start ms-2 ps-3 mb-3">
          <button className="btn btn-dark px-4">UPDATE</button>
        </div> */}
        <div className="col-12 mt-2 ms-3">
          <Search onSearch={(e) => onSearch(e)} />
        </div>
        <div className="row">
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <div className="col-12">
              <DataTable
                tableData={newsSourcesData}
                TableCols={NewsSourcesDataGridCols}
                onActions={(e) => onActions(e)}
                onPageChange={(e) => onPageChange(e)}
                pageCount={pageCount}
                onSelectPageChange={(e) => onSelectPageinator(e)}
              />
              {showDeleteModal && (
                <CustomModel
                  modalTitle={"Delete"}
                  ismodalShow={showDeleteModal}
                  modalStatus={getModalStatus}
                  modalHeaderText={deleteModalBody}
                  modalType={"delete"}
                  deleteStatus={onDelete}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_USER_POSTED_POSTS } from "../../services/report.service";
import { DataTable } from "../data-table";
import { UserPostedPostReportGridCols } from "./user-posted-post-report-grid-cols";

interface Props {
  selectedUserData: any;
  onBackButton: (data: any) => void;
}

export const UserPostedPostsReportsPage = (props: Props) => {
  const [userPostedPostsData, setUserPostedPostsData] = useState<any>();
  const [selectedUserDate, setSelectedUserDate] = useState<any>();
  const [userPostedPostReportPageCount, setUserPostedPostReportPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  useEffect(() => {
    setSelectedUserDate(props.selectedUserData);
    const params = userPostedPostReportPageCount;
    params.user_uuid = props.selectedUserData?.uuid;
    getUserPostedPostReport(params);
  }, []);

  const getUserPostedPostReport = (params: any) => {
    GET_USER_POSTED_POSTS(params).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          res?.data?.data.forEach((element: any) => {
            element.post_type = !element?.feed_exists ? 'Direct' : '';
          });
          setUserPostedPostsData(res?.data)
          // setLoading(false);
        }
      }
    });
  };

  const onUserPostedPosteReportPageChange = (data: any) => {
    const pageData = userPostedPostReportPageCount;
    pageData.limit = data;
    pageData.user_uuid = selectedUserDate.uuid;
    setUserPostedPostReportPageCount(pageData);
    getUserPostedPostReport(pageData);
  };

  const onUserPostedPosteReportSelectPageinator = (data: any) => {
    const pageData = userPostedPostReportPageCount;
    pageData.page = data;
    pageData.user_uuid = selectedUserDate.uuid;
    setUserPostedPostReportPageCount(pageData);
    getUserPostedPostReport(pageData);
  };

  const onBackToUserPost = () => {
    props.onBackButton(true);
  }

  return (
    <div className="row">
      <div className="text-start mb-2 d-flex">
        <i className="bi bi-arrow-left-short" style={{ fontSize: '20px', cursor: 'pointer', position: 'relative', top: '-4px' }} onClick={() => onBackToUserPost()}></i> <h5 className="mb-0">{selectedUserDate?.user_name}</h5>
      </div>
      <div>
        <DataTable
          tableData={userPostedPostsData}
          TableCols={UserPostedPostReportGridCols}
          onPageChange={(e) => onUserPostedPosteReportPageChange(e)}
          pageCount={userPostedPostReportPageCount}
          onSelectPageChange={(e) => onUserPostedPosteReportSelectPageinator(e)}
        />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_USER_POST_REPORT } from "../../services/report.service";
import { DataTable } from "../data-table";
import { UserPostReportGridCols } from "./user-post-report-grid-cols";
import { Search } from "../search/search";
import moment from "moment";
import dayjs from "dayjs";
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

interface Props {
  onSelectedUser: (data: any) => void;
  selectedPage: (data: any) => void;
  selectedPageNumber: any;
}

export const UserPostsReportsPage = (props: Props) => {
  const [userPostData, setUserPostData] = useState<any>();

  const startDate = dayjs(moment().add(-30, 'days').format('MM-DD-YYYY'));
  const endDate = dayjs(moment().format('MM-DD-YYYY'));

  const [articleStartDate, setArticleStartDate] = useState<any>(startDate);
  const [articleEndDate, setArticleEndDate] = useState<any>(endDate);

  const [userPostReportPageCount, setUserPostReportPageCount] = useState<any>({
    limit: 10,
    page: props?.selectedPageNumber ? props?.selectedPageNumber : 1,
    user_name: '',
    start_date: dayjs(articleStartDate).format('YYYY-MM-DD'),
    end_date: dayjs(articleEndDate).format('YYYY-MM-DD')
  });

  useEffect(() => {
    getUserPostReport();
  }, []);

  const getUserPostReport = () => {
    const data = userPostReportPageCount
    data.start_date = dayjs(data.start_date).format('YYYY-MM-DD');
    data.end_date = dayjs(data.end_date).format('YYYY-MM-DD');
    // setLoading(true);
    GET_USER_POST_REPORT(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          setUserPostData(res?.data)
          // setLoading(false);
        }
      }
    });
  };

  const onPageChange = (data: any) => {
    const pageData = userPostReportPageCount;
    pageData.limit = data;
    setUserPostReportPageCount(pageData);
    getUserPostReport();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = userPostReportPageCount;
    pageData.page = data;
    props.selectedPage(data)
    setUserPostReportPageCount(pageData);
    getUserPostReport();
  };

  const onActions = (data: any) => {
    if (data.type === "link") {
      props.onSelectedUser(data?.data);
    }
  }
  const onSearch = (value: string) => {
    const pageData = userPostReportPageCount;
    pageData.user_name = value;
    pageData.limit = 10;
    pageData.page = 1;
    setUserPostReportPageCount(pageData);
    getUserPostReport();
  };


  const onChangeUserPostDateTime = (date: any) => {
    if (date[0] && date[1]) {
      const pageData = userPostReportPageCount;
      pageData.start_date = dayjs(date[0]).format('MM-DD-YYYY');
      pageData.end_date = dayjs(date[1]).format('MM-DD-YYYY');
      setUserPostReportPageCount(pageData);
      getUserPostReport();
    }
  }
  return (
    <div className="row">
      <div className="ps-2" style={{ position: 'relative' }}>
        <Search onSearch={(e) => onSearch(e)} />
        <div style={{
          position: 'absolute',
          top: '0px',
          left: '25%'
        }}>
          {/* <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['SingleInputDateRangeField']}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  name="allowedRange"
                  onChange={(newValue) => onChangeUserPostDateTime(newValue)}
                  value={[articleStartDate, articleEndDate]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </StyledEngineProvider> */}
        </div>
      </div>
      <DataTable
        tableData={userPostData}
        TableCols={UserPostReportGridCols}
        onActions={onActions}
        onPageChange={(e) => onPageChange(e)}
        pageCount={userPostReportPageCount}
        onSelectPageChange={(e) => onSelectPageinator(e)}
      />
    </div>
  );
};

import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DymamicForm } from "../../components/form-builder/form";
import { LOGIN } from "../../services/system-users.service";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/LdrX Logo Horiz.png"

export const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loginFormData = [
    {
      title: "input",
      control: "",
      type: "text",
      label: "Email",
      name: "user_email",
      value: "",
      required: true,
      regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
      maxLength: "",
      placeholder: "Enter email",
      error: "Please enter email",
      submitButtonName: "Login",
      hideMandatoryStar: true,
    },
    {
      title: "input",
      control: "",
      type: "password",
      label: "Password",
      name: "user_password",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Please enter password",
      error: "Please enter valid password",
      hideMandatoryStar: true,
    },
  ];

  const onSubmitForm = (event: any) => {
    setLoading(true);
    LOGIN(event?.formData).then((res: any) => {
      if (res.status === "error") {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res) {
          sessionStorage.setItem("loginUserData", JSON.stringify(res.data));
          toast.success(res.message);
          if (res.data?.user_role === "EDITOR") {
            navigate("/dashboard/editors-panel");
          } else if (res.data?.user_role === "ADMIN") {
            navigate("/dashboard/home");
          } else {
            navigate("/");
          }
          setLoading(false);
        }
      }
    });
  };
  return (
    <>
      <div className="modal" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="login-form">
              <div className="d-flex flex-column justify-content-center">
                <div className="">
                  <form className="m-4">
                    <div className="Login-form-content">
                      <h2 className="Login-form-title login_logo text-center fw-bold">
                        <img src={LOGO} alt="" className="" />
                        {/* LeaderX */}
                      </h2>
                      <h4 className="Login-form-title text-center">Log In</h4>
                      <DymamicForm
                        formControls={""}
                        userFormData={loginFormData}
                        columns={1}
                        cancelRoute={""}
                        isEdit={false}
                        custom_button_text={'Login'}
                        onChildEvent={(e) => onSubmitForm(e)}
                        customClass={"display-block"}
                        loading={loading}
                        isNotEmptyFormAfterSubmit={true}
                      />
                      <div className="d-flex flex-column justify-content-center ">
                        <div className="my-2">
                          <NavLink
                            to="/forgot-password"
                            className="text-decoration-none text-dark"
                          >
                            Forgot password?
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { DataTableCol } from "../../../components/data-table/types";

export const FetchPostsDataGridCols: DataTableCol[] = [
  {
    title: "User Name",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Subscription Status",
    control: "subscription_status",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Started On",
    control: "started_on",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Ending On",
    control: "ending_on",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Reactions",
    control: "reactions",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Daily Talking Points",
    control: "daily_talking_points",
    sortable: true,
    canShowColumn: true,
  },
  // {
  //   title: "Actions",
  //   control: "both",
  //   sortable: false,
  //   isEdit: true,
  //   // isDelete: true,
  //   canShowColumn: true,
  // },
];

import React, { useEffect, useState } from "react";
import { DataTableCol } from "./types";
import sendIcon from "./../../assets/images/send.svg";
import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";
import moment from "moment";
interface Props {
  tableData: any;
  TableCols: DataTableCol[];
  onActions?: (data: string) => void;
  onPageChange: (data: any) => void;
  pageCount: any;
  onSelectPageChange?: (data: any) => void;
}

export const DataTable: React.FC<Props> = (props: Props) => {
  const [canShowDefaultArrow, setCanShowDefaultArrow] = useState(true);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [itemOffset, setItemOffset] = useState(props?.pageCount?.page);
  const [itemsPerPage, setItemsPerPage] = useState(props?.pageCount?.limit);
  // const [pageCount, setPageCount] = useState(props.tableData?.total_count);

  const items: any[] = props?.tableData?.data || [];
  // const endOffset = itemOffset + itemsPerPage;
  // const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math?.ceil(props?.tableData?.total_count / itemsPerPage);
  const itemPerPage = [2, 5, 10, 25, 50, 100, 500];
  const [commingDaya, setCommingDaya] = useState<any>([]);


  useEffect(() => {
    getNextFourDays();
    // const data = props.tableData;
  }, []);
  const onChangeItemPerPage = (value: any) => {
    props.onPageChange(value);
    setItemsPerPage(value);
  };
  const onSortingField = (data: any) => {
    setCanShowDefaultArrow(false);
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      props?.tableData?.data?.sort(
        (a: any, b: any) =>
          reversed * a[data]?.toString().localeCompare(b[data])
      );
    }
  };

  const handlePageClick = (e: any, value: any) => {
    // const newOffset = (e.selected * itemsPerPage) % items?.length;
    if (props.onSelectPageChange) {
      props.onSelectPageChange(value);
    }
    setItemOffset(value);
  };
  const onAction = (data: any, type: string) => {
    const actionData: any = { data, type };
    if (props.onActions) {
      props.onActions(actionData);
    }
  };

  const onActionDailyTalkingPoints = (data: any, type: string, dateData: any) => {
    const actionData: any = { data, type, dateData };
    if (props.onActions) {
      props.onActions(actionData);
    }
  };

  const getNextFourDays = () => {
    const currentMoment = moment();
    const nextFourDays = [];

    for (let i = 1; i <= 4; i++) {
      const nextDay = currentMoment.clone().add(i, 'days');
      nextFourDays.push({ date: nextDay.format('DD'), month: nextDay.format('MMMM') });
    }
    setCommingDaya([...nextFourDays]);
  }

  const showData = (data: any) => {
    if (data) {
      var date = moment.utc(data).format("YYYY-MM-DD hh:mm");
      var stillUtc = moment.utc(date).toDate();
      var local = moment(stillUtc)
        .local()
        .format("YYYY-MM-DD hh:mm");
      return local;
    } else {
      return "";
    }
  };

  const htmlToText = (htmlContent: any) => {
    const singleLineContent = htmlContent.replace(/<\/?p>/g, ' ').replace(/<\/?div>/g, '');
    return singleLineContent;
  }

  // const handleClick = (data: any) => {};
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table_new_ui">
                <thead className="text-center">
                  <tr>
                    {props?.TableCols?.map((el, index) => (
                      <React.Fragment key={index}>
                        {el.canShowColumn && (
                          <th
                            onClick={() =>
                              el.sortable ? onSortingField(el.control) : null
                            }
                          >
                            {<span>{el.title}</span>}
                            {canShowDefaultArrow &&
                              index === 0 &&
                              el.sortable && (
                                <i className="bi bi-arrow-down cursor-pointer p-2 "></i>
                              )}
                            {sortingField && sortingField === el.control && (
                              <i
                                className={`${sortingOrder === "asc"
                                  ? "bi bi-arrow-down cursor-pointer p-2"
                                  : "bi bi-arrow-up cursor-pointer p-2"
                                  }`}
                              ></i>
                            )}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items &&
                    items?.map((data: any, i: number) => (
                      <tr key={i}>
                        {props?.TableCols?.map((el: any, index: number) => (
                          <React.Fragment key={index}>
                            <td>
                              {el.control === "serial_no" && (
                                <span>{i + 1}</span>
                              )}
                              {el.control === "is_active" && (
                                <span>
                                  {data[el.control] === 1
                                    ? "Active"
                                    : "In-Active"}
                                </span>
                              )}
                              {el.isArrayToString && (
                                <span>{data[el.control]?.toString()}</span>
                              )}
                              {el.control !== "is_active" &&
                                el.control !== "interest_id" &&
                                !el.isArrayToString &&
                                !el.isLink &&
                                !el.isTextOverflowHide &&
                                !el?.isDate &&
                                !el?.isHtml &&
                                el.control !== "is_feed_type" &&
                                el.control !== "reject_message" && (
                                  <span>{data[el.control]}</span>
                                )}
                              {el.control === "is_feed_type" && (
                                <span>
                                  {data['is_web_view'] ? 'Web View' : data['is_insight'] ? 'Insights' : 'React'}
                                </span>
                              )}
                              {el.control === "reject_message" && (
                                <>
                                  {data[el.control] ? <Tooltip
                                    title={data[el.control]}
                                    placement="top"
                                    arrow
                                    className="custom-tooltip-position"
                                  >
                                    <label className="user_status_feedback reject_status"></label>
                                  </Tooltip> :
                                    data['is_user_posted'] ?
                                      <label className="user_status_feedback success_status"></label> :
                                      <label className="user_status_feedback pending_status"></label>
                                  }
                                </>
                              )}

                              {el.isLink && (
                                <span
                                  className="custom_link"
                                  onClick={() => onAction(data, "link")}
                                >
                                  {data[el.control]?.toString()}
                                </span>
                              )}

                              {el?.isDate && (
                                <span>{showData(data[el.control])}</span>
                              )}
                              {el?.isHtml && (
                                // <div dangerouslySetInnerHTML={{ __html: data[el.control] }}>{htmlToText(data[el.control])}</div>
                                <div className="table_text_ellipsis">{htmlToText(data[el.control])}</div>

                              )}
                              {el.isTextOverflowHide && (
                                <Tooltip
                                  title={data[el.control]}
                                  placement="top"
                                  arrow
                                  className="custom-tooltip-position"
                                >
                                  <span>
                                    {data[el.control]?.length > 100
                                      ? `${data[el.control].slice(0, 100)}...`
                                      : `${data[el.control]
                                        ? data[el.control]
                                        : ""
                                      }`}
                                  </span>
                                </Tooltip>
                              )}
                              {el.title === "Actions" && (
                                <div className="actions-view">
                                  <span>
                                    {el.isResend && (
                                      <img
                                        src={sendIcon}
                                        className="send-icon"
                                        alt="send icon"
                                        onClick={() => onAction(data, "resend")}
                                      />
                                    )}
                                    {el.isView && data['is_web_view'] && (
                                      <span>
                                        <i className="bi bi-eye-fill cursor-pointer p-2" onClick={() => onAction(data, "view")}></i>
                                      </span>
                                    )}
                                    {el.isEdit && (
                                      <i
                                        className="bi bi-pencil-square  cursor-pointer edit-icon p-2"
                                        onClick={() => onAction(data, "edit")}
                                      ></i>
                                    )}
                                    {el.control === "is_feed_type" && <span>{data['is_web_view']}</span>}

                                    {el.isEvent && (
                                      <span>
                                        <i className="bi bi-calendar-event-fill cursor-pointer p-2" onClick={() => onAction(data, "event")}></i>
                                      </span>
                                    )}
                                    {el.isDelete && (
                                      <i
                                        className="bi bi-trash text-danger fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => onAction(data, "delete")}
                                      ></i>
                                    )}

                                    {el.isRefresh && (
                                      <span>
                                        <i
                                          className="bi bi-arrow-clockwise cursor-pointer p-2"
                                          onClick={() =>
                                            onAction(data, "refresh")
                                          }
                                        ></i>
                                      </span>
                                    )}
                                  </span>
                                </div>
                              )}
                              {el.title === "Daily Talking Points" && (
                                <div className="d-flex">
                                  {commingDaya.map((day: any, dayIndex: number) => {
                                    return <div key={dayIndex} >
                                      <span className={`daily_talking_point 
                                      ${dayIndex === 0 && 'daily_talking_point_green'} 
                                      ${dayIndex === 1 && 'daily_talking_point_yellow'} 
                                      ${(dayIndex === 2 || dayIndex === 3) && 'daily_talking_point_red'}`} onClick={() =>
                                          onActionDailyTalkingPoints(data, "dailyTalkingPoints", day)
                                        }>{day?.date}</span>
                                    </div>
                                  })}
                                </div>
                              )}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {items?.length === 0 && (
              <div className="mt-3 text-center">No records found.</div>
            )}
            <div className="row">
              <div className="col-md-4 text-start">
                <span className="me-2"> Item per page</span>
                <select
                  onChange={(e) => onChangeItemPerPage(e.target.value)}
                  value={itemsPerPage}
                >
                  {itemPerPage.map((page: number, index: number) => {
                    return (
                      <option key={index} value={page}>
                        {page}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-8 d-flex justify-content-end mb-4">
                {items?.length > 0 && (
                  <div>
                    <Pagination
                      count={pageCount}
                      defaultPage={itemOffset}
                      showFirstButton
                      showLastButton
                      variant="outlined"
                      shape="rounded"
                      onChange={(e: any, value) => handlePageClick(e, value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

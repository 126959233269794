import API from "../utils/axios";

export const GETEDITORS = (data: any): Promise<any> => {
  return API.get(`/systemUsers/fetch`, { params: data });
};

export const GETUSERS = (data: any): Promise<any> => {
  return API.get(`/users/fetch`, { params: data });
};

export const DELETEEDITORS = (uuid: string): Promise<any> => {
  return API.delete(`/users/delete/${uuid}`);
};

import { DataTableCol } from "../../../components/data-table/types";

export const SpinTypeDataGridCols: DataTableCol[] = [
  {
    title: "Spin Name ",
    control: "spin_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];

import { useEffect, useState } from "react";
import { Search } from "../../../../components/search/search";
import { DataTable } from "../../../../components/data-table";
import { toast } from "react-toastify";
import { DELETEEDITORS, GETUSERS } from "../../../../services/users.service";
import CustomModel from "../../../../components/modal/custom-model";
import { ActiveUsersEventsDataGridCols } from "./acrive-users-events-data-grid-cols";
import { DymamicForm } from "../../../../components/form-builder/form";
import { ADD_USER_EVENTS, DELETE_USER_EVENTS, GET_USER_EVENTS, UPDATE_USER_EVENTS } from "../../../../services/users-events.service";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import LOGO from "../../../../assets/images/LdrX Logo Horiz.png"
import moment from "moment";
import { FETCH_BY_URL } from "../../../../services/editor-panel.service";

export const ActiveUsersEventList = () => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [selectedFieldType, setSelectedFieldType] = useState<any>("feed_url");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [currentEventUserData, setCurrentEventUserData] = useState<any>();

  const [activeUserData, setActiveUserData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [showModal, setShowModal] = useState(false);


  const [searchForm, setSearchForm] = useState<any>([
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Feed Type",
      name: "feed_type",
      option: [
        { label: "Web View", value: 'is_web_view' },
        { label: "Insights", value: 'is_insight' },
        { label: "React", value: 'is_post' },

      ],
      value: '',
      regex: "",
      required: false,
      placeholder: "Please select feed type",
      error: "Please select feed type",
      submitButtonName: "SEARCH",
    }, {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: '',
      regex: "",
      required: false,
      placeholder: "Please select status",
      error: "Please select status",
    }
  ])
  const [editorFormData, setEditorFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Title",
      name: "title",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Enter event title",
      error: "Please enter event title",
      submitButtonName: "ADD",
    },
    {
      title: "datetime",
      control: "",
      type: "datetime-local",
      label: "Date",
      name: "event_date",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Please select date",
      error: "Please select date",
    },
    {
      title: "radio",
      control: "",
      type: "radio",
      label: "Feed URL",
      name: "reminder_message_type",
      value: 'feed_url',
      required: false,
      maxLength: "",
      placeholder: "Please select date",
      error: "",
      column: 2,
      checked: true,
      isChageRadioButton: true
    },
    {
      title: "radio",
      control: "",
      type: "radio",
      label: "Insight",
      name: "reminder_message_type",
      value: "insight",
      required: false,
      maxLength: "",
      placeholder: "Please select date",
      error: "",
      column: 2,
      checked: false,
      isChageRadioButton: true
    },
    {
      title: "radio",
      control: "",
      type: "radio",
      label: "react",
      name: "reminder_message_type",
      value: "react",
      required: false,
      maxLength: "",
      placeholder: "Please select date",
      error: "",
      column: 2,
      checked: false,
      isChageRadioButton: true
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Feed URL",
      name: "reminder_message",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Enter feed URL",
      error: "Please enter feed URL",
      submitButtonName: "ADD",
      column: 12
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
    {
      title: "file",
      control: "",
      type: "file",
      label: "Media",
      name: "post_media",
      value: "",
      required: false,
      maxLength: "",
      error: "Please select media",
      submitButtonName: "ADD",
      column: 12
    },
  ]);

  const [updateStatusFormData, setUpdateStatusFormData] = useState<any>([
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
      submitButtonName: "UPDATE",
    }
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const handleModalClosed = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const eventUserData = sessionStorage.getItem('eventUserUUID');
    if (eventUserData) {
      const data = JSON.parse(eventUserData);
      setCurrentEventUserData(data);
      getActiveUserData({ user_uuid: data.uuid });

    }
  }, []);

  const getActiveUserData = (userData: any) => {
    setLoading(true);
    let data = { ...pageCount, ...userData };
    // data.user_uuid = userData?.uuid;//sessionStorage.getItem('eventUserUUID');
    GET_USER_EVENTS(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setActiveUserData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const onActions = (data: any) => {
    setSelectedUser(data?.data);
    if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this message!`
      );
      setShowDeleteModal(true);
    } else if (data?.type === "edit") {
      setIsEdit(true);
      let isSelectedFieldType = '';
      if (data?.data?.is_web_view) {
        isSelectedFieldType = 'feed_url';
      } else if (data?.data?.is_insight) {
        isSelectedFieldType = 'insight';
      } else if (data?.data?.is_post) {
        isSelectedFieldType = 'react';
      }
      setSelectedFieldType(isSelectedFieldType);
      const newData: any = editorFormData;

      // let newData: any =  [
      //   {
      //     title: "input",
      //     control: "",
      //     type: "text",
      //     label: "Title",
      //     name: "title",
      //     value: "",
      //     required: true,
      //     maxLength: "",
      //     placeholder: "Enter event title",
      //     error: "Please enter event title",
      //     submitButtonName: "ADD",
      //   },
      //   {
      //     title: "datetime",
      //     control: "",
      //     type: "datetime-local",
      //     label: "Date",
      //     name: "event_date",
      //     value: "",
      //     required: true,
      //     maxLength: "",
      //     placeholder: "Please select date",
      //     error: "Please select date",
      //   },
      //   {
      //     title: "radio",
      //     control: "",
      //     type: "radio",
      //     label: "Feed URL",
      //     name: "reminder_message_type",
      //     value: 'feed_url',
      //     required: false,
      //     maxLength: "",
      //     placeholder: "Please select date",
      //     error: "",
      //     column: 2,
      //     checked: true,
      //     isChageRadioButton: true
      //   },
      //   {
      //     title: "radio",
      //     control: "",
      //     type: "radio",
      //     label: "Insight",
      //     name: "reminder_message_type",
      //     value: "insight",
      //     required: false,
      //     maxLength: "",
      //     placeholder: "Please select date",
      //     error: "",
      //     column: 2,
      //     checked: false,
      //     isChageRadioButton: true
      //   },
      //   {
      //     title: "radio",
      //     control: "",
      //     type: "radio",
      //     label: "react",
      //     name: "reminder_message_type",
      //     value: "react",
      //     required: false,
      //     maxLength: "",
      //     placeholder: "Please select date",
      //     error: "",
      //     column: 2,
      //     checked: false,
      //     isChageRadioButton: true
      //   },
      //   {
      //     title: "input",
      //     control: "",
      //     type: "text",
      //     label: "Feed URL",
      //     name: "reminder_message",
      //     value: "",
      //     required: true,
      //     maxLength: "",
      //     placeholder: "Enter feed URL",
      //     error: "Please enter feed URL",
      //     submitButtonName: "ADD",
      //     column: 12
      //   },
      //   {
      //     title: "dropdown",
      //     control: "",
      //     type: "text",
      //     label: "Status",
      //     name: "is_active",
      //     option: [
      //       { label: "Active", value: 1 },
      //       { label: "InActive", value: 0 },
      //     ],
      //     value: 1,
      //     regex: "",
      //     required: true,
      //     placeholder: "Please select status",
      //     error: "Please select status",
      //   }
      // ];

      newData[0].submitButtonName = "UPDATE EVENT";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });

      newData[2].checked = false;
      newData[3].checked = false;
      newData[4].checked = false;
      newData[2].value = 'feed_url';
      newData[3].value = 'insight';
      newData[4].value = 'react';

      if (data?.data?.is_insight) {
        if (newData[6].name === "post_to_platform") {
          newData.splice(6, 1);
        }
        newData[3].checked = true;
        newData[5] = {
          title: "ckEditor",
          control: "",
          type: "text",
          label: "Event Message",
          name: "reminder_message",
          value: data?.data?.reminder_message,
          required: true,
          maxLength: "",
          placeholder: "Enter event message",
          error: "Please enter event message",
          submitButtonName: "ADD",
          column: 12
        }
      } else if (data?.data?.is_web_view) {
        if (newData[6].name === "post_to_platform") {
          newData.splice(6, 1);
        }
        newData[2].checked = true;
        newData[5] = {
          title: "input",
          control: "",
          type: "text",
          label: "Feed URL",
          name: "reminder_message",
          value: data?.data?.reminder_message,
          required: true,
          maxLength: "",
          placeholder: "Enter feed URL",
          error: "Please enter feed URL",
          submitButtonName: "ADD",
          column: 12
        }
      } else if (data?.data?.is_post) {
        newData[4].checked = true;
        newData[5] = {
          title: "textarea",
          control: "",
          type: "text",
          label: "React",
          name: "reminder_message",
          value: data?.data?.reminder_message,
          required: true,
          maxLength: "",
          placeholder: "Enter react",
          error: "Please enter react",
          submitButtonName: "ADD",
          column: 12
        }
        newData[6] = {
          title: "dropdown",
          control: "",
          type: "text",
          label: "Post Platform",
          name: "post_to_platform",
          option: [
            { label: "TWITTER", value: 'TWITTER' },
            { label: "LINKEDIN", value: 'LINKEDIN' },
          ],
          value: data?.data?.post_to_platform,
          regex: "",
          required: true,
          placeholder: "Please select Post Platform",
          error: "Please select Post Platform",
        }
        newData[7] = {
          title: "dropdown",
          control: "",
          type: "text",
          label: "Status",
          name: "is_active",
          option: [
            { label: "Active", value: 1 },
            { label: "InActive", value: 0 },
          ],
          value: data?.data?.is_active,
          regex: "",
          required: true,
          placeholder: "Please select status",
          error: "Please select status",
        }

        newData[8] = {
          title: "file",
          control: "",
          type: "file",
          label: "Media",
          name: "post_media",
          value: data?.data?.post_media ? data?.data?.post_media[0] : '',
          required: false,
          maxLength: "",
          error: "Please select media",
          submitButtonName: "ADD",
          column: 6
        }
      }
      setEditorFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "view") {
      const updateStatusForm = updateStatusFormData;
      updateStatusForm[0].value = data?.data?.is_active;
      setUpdateStatusFormData([...updateStatusForm]);
      setShowDetailsModal(true);
      FETCH_BY_URL({ feed_url: data?.data?.reminder_message }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setSelectedData(res?.data[0])
            setLoading(false);
            handleModalClosed();
          }
        }
      });
      // const [showDetailsModal, setShowDetailsModal] = useState(false);
      // const [selectedData, setSelectedData] = useState<any>();
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getActiveUserData({ user_uuid: currentEventUserData?.uuid });
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getActiveUserData({ user_uuid: currentEventUserData?.uuid });
  };
  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getActiveUserData({ user_uuid: currentEventUserData?.uuid });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETE_USER_EVENTS(selectedUser?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getActiveUserData({ user_uuid: currentEventUserData?.uuid });
          setLoading(false);
        }
      }
    });
  };

  const ShowPopup = () => {
    setSelectedUser('');
    const fromData = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Title",
        name: "title",
        value: "",
        required: true,
        maxLength: "",
        placeholder: "Enter event title",
        error: "Please enter event title",
        submitButtonName: "ADD",
      },

      {
        title: "datetime",
        control: "",
        type: "datetime-local",
        label: "Date",
        name: "event_date",
        value: "",
        required: true,
        maxLength: "",
        placeholder: "Please select date",
        error: "Please select date",
      },
      {
        title: "radio",
        control: "",
        type: "radio",
        label: "Feed URL",
        name: "reminder_message_type",
        value: 'feed_url',
        required: false,
        maxLength: "",
        placeholder: "Please select date",
        error: "",
        column: 2,
        checked: true,
        isChageRadioButton: true
      },
      {
        title: "radio",
        control: "",
        type: "radio",
        label: "Insight",
        name: "reminder_message_type",
        value: "insight",
        required: false,
        maxLength: "",
        placeholder: "Please select date",
        error: "",
        column: 2,
        checked: false,
        isChageRadioButton: true
      },
      {
        title: "radio",
        control: "",
        type: "radio",
        label: "react",
        name: "reminder_message_type",
        value: "react",
        required: false,
        maxLength: "",
        placeholder: "Please select date",
        error: "",
        column: 2,
        checked: false,
        isChageRadioButton: true
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Feed URL",
        name: "reminder_message",
        value: "",
        required: true,
        maxLength: "",
        placeholder: "Enter feed URL",
        error: "Please enter feed URL",
        submitButtonName: "ADD",
        column: 12
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
      {
        title: "file",
        control: "",
        type: "file",
        label: "Media",
        name: "post_media",
        value: "",
        required: false,
        maxLength: "",
        error: "Please select media",
        submitButtonName: "ADD",
        column: 6
      },
    ];
    setEditorFormData([...fromData])
    setIsEdit(false);
    setShowModal(true);
  };


  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    formData.user_uuid = currentEventUserData?.uuid;//sessionStorage.getItem('eventUserUUID');
    formData.is_active = Number(formData.is_active);
    formData.is_web_view = selectedFieldType === 'feed_url' ? true : false;
    formData.is_insight = selectedFieldType === 'insight' ? true : false;
    formData.is_post = selectedFieldType === 'react' ? true : false;
    formData.post_media = formData.post_media ? [formData.post_media] : '';

    if (selectedFieldType === 'react') {
      formData.post_to_platform = formData.post_to_platform ? formData.post_to_platform : 'TWITTER';
    } else {
      formData.post_to_platform = null;
    }
    if (isEdit) {
      formData.uuid = selectedUser.uuid;
      setLoading(true);
      UPDATE_USER_EVENTS(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getActiveUserData({ user_uuid: currentEventUserData?.uuid });
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      ADD_USER_EVENTS(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getActiveUserData({ user_uuid: currentEventUserData?.uuid });
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onChangeRadioButton = (field: any) => {
    setSelectedFieldType(field)
    const fromData = editorFormData;
    if (fromData[6].name === "post_to_platform") {
      fromData.splice(6, 1)
    }
    if (field === 'insight') {
      fromData[5] = {
        title: "ckEditor",
        control: "",
        type: "text",
        label: "Event Message",
        name: "reminder_message",
        value: selectedUser?.is_insight ? selectedUser?.reminder_message : '',
        required: true,
        maxLength: "",
        placeholder: "Enter event message",
        error: "Please enter event message",
        submitButtonName: "ADD",
        column: 12
      }
    } else if (field === 'feed_url') {
      fromData[5] = {
        title: "input",
        control: "",
        type: "text",
        label: "Feed URL",
        name: "reminder_message",
        value: selectedUser?.is_web_view ? selectedUser?.reminder_message : '',
        required: true,
        maxLength: "",
        placeholder: "Enter feed URL",
        error: "Please enter feed URL",
        submitButtonName: "ADD",
        column: 12
      }
    } else {
      fromData[5] = {
        title: "textarea",
        control: "",
        type: "text",
        label: "React",
        name: "reminder_message",
        value: selectedUser?.is_post ? selectedUser?.reminder_message : '',
        required: true,
        maxLength: "",
        placeholder: "Enter react",
        error: "Please enter react",
        submitButtonName: "ADD",
        column: 12
      }
      fromData[6] = {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Post Platform",
        name: "post_to_platform",
        option: [
          { label: "TWITTER", value: 'TWITTER' },
          { label: "LINKEDIN", value: 'LINKEDIN' },
        ],
        value: selectedUser?.is_post ? selectedUser?.post_to_platform : 'TWITTER',
        regex: "",
        required: true,
        placeholder: "Please select Post Platform",
        error: "Please select Post Platform",
      }
      fromData[7] = {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: selectedUser?.isis_active_post ? selectedUser?.isis_active_post : 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      }
      fromData[8] = {
        title: "file",
        control: "",
        type: "file",
        label: "Media",
        name: "post_media",
        value: selectedUser?.post_media ? selectedUser?.post_media[0] : '',
        required: false,
        maxLength: "",
        error: "Please select media",
        submitButtonName: "ADD",
        column: 6
      }
    }
    setEditorFormData([...fromData])
  }

  const onSearchEvent = (event: any) => {
    // formData.is_web_view = selectedFieldType === 'feed_url' ? true : false;
    // formData.is_insight = selectedFieldType === 'insight' ? true : false;
    // formData.is_post = selectedFieldType === 'react' ? true : false;
    let search: any = {};
    if (event?.formData?.feed_type === 'is_web_view') {
      search.is_web_view = true;
    } else if (event?.formData?.feed_type === 'is_insight') {
      search.is_insight = true;
    } else if (event?.formData?.feed_type === 'is_post') {
      search.is_post = true;
    }
    if (event?.formData?.is_active) {
      search.is_active = event?.formData?.is_active;
    }
    search.user_uuid = currentEventUserData?.uuid;
    getActiveUserData(search);
  }

  const onCloseViewDetails = () => {
    setSelectedData("");
    const id: any = document.getElementById("myVideo");
    if (id) {
      id.pause();
    }
    setShowDetailsModal(false);
  };

  const onErrorImage = (error: any) => {
    error.target.src = LOGO;
  };

  const showData = (data: any) => {
    if (data) {
      var date = moment.utc(data).format("YYYY-MM-DD HH:mm:ss");
      var stillUtc = moment.utc(date).toDate();
      var local = moment(stillUtc)
        .local()
        .format("DD-MMM-YYYY hh:mm a");
      return local;
    } else {
      return "";
    }
  };

  const onSubmitStatusForm = (data: any) => {
    const formData = { ...selectedUser, ...data?.formData, };
    formData.uuid = selectedUser.uuid;
    formData.is_active = Number(formData.is_active)
    setLoading(true);
    UPDATE_USER_EVENTS(formData).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setShowModal(false);
          getActiveUserData({ user_uuid: currentEventUserData?.uuid });
          toast.success(res.message);
          setLoading(false);
        }
      }
    });
  }
  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 px-0">
          <div className="ps-2 row">
            <div className="col-12">
              {searchForm && !isEdit && (
                <DymamicForm
                  formControls={""}
                  userFormData={searchForm}
                  columns={4}
                  cancelRoute={""}
                  isEdit={false}
                  onChildEvent={(e) => onSearchEvent(e)}
                  isNotEmptyFormAfterSubmit={true}
                />
              )}
            </div>
            <div className="col-6">
              <h5 className="text-start mt-2">{currentEventUserData?.user_name}</h5>
            </div>
            <div className="col-6 text-end mb-2">
              <button
                className="custom-bg-btn rounded border-0 text-white me-2"
                onClick={() => ShowPopup()}
              >
                Create Event
              </button>
            </div>
          </div>
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <DataTable
              tableData={activeUserData}
              TableCols={ActiveUsersEventsDataGridCols}
              onActions={(e) => onActions(e)}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
          )}
        </div>
      </div>
      {showDeleteModal && (
        <CustomModel
          modalTitle={"Delete"}
          ismodalShow={showDeleteModal}
          modalStatus={getModalStatus}
          modalHeaderText={deleteModalBody}
          modalType={"delete"}
          deleteStatus={onDeleteUser}
        />
      )}

      {showModal && <div
        className="modal bd-example-modal-lg"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">
                {isEdit ? "Update Event" : "Create Event"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClosed}
              ></button>
            </div>
            <div className="modal-body text-start">
              {editorFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={editorFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={isEdit}
                  onChildEvent={(e) => onSubmitForm(e)}
                  onChangeRadioButton={(e) => onChangeRadioButton(e)}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
          </div>
        </div>
      </div>}

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showDetailsModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">View</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onCloseViewDetails()}
              ></button>
            </div>
            <div className="modal-body text-start">
              <React.Fragment>
                <div className="card p-3 my-2 position-relative">
                  <div className="row">
                    <div className="col-3 post_img mb-4">
                      <p className="text-start mb-1">
                        Source: {selectedData?.feed_source_platform} /{" "}
                        {selectedData?.feed_owner_username}
                      </p>
                      <p className="text-start mb-2">
                        Created at: {showData(selectedData?.feed_created_time)}
                      </p>
                      <div className="details_view">
                        {selectedData?.feed_media?.videos.length > 0 && (
                          <Carousel showArrows={true} showThumbs={false}>
                            {selectedData?.feed_media?.videos?.map(
                              (video: any) => {
                                return (
                                  <div className="vide_player">
                                    <video
                                      controls
                                      autoPlay
                                      width={"100%"}
                                      id="myVideo"
                                    >
                                      <source src={video} type="video/mp4" />
                                    </video>
                                  </div>
                                );
                              }
                            )}
                          </Carousel>
                        )}{" "}
                        {selectedData?.feed_media?.images.length > 0 && (
                          <>
                            <Carousel showArrows={true} showThumbs={false}>
                              {selectedData?.feed_media?.images?.map(
                                (image: any) => {
                                  return (
                                    <div>
                                      <img
                                        src={image}
                                        className="d-block w-100 carousel_image"
                                        onError={onErrorImage}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </Carousel>
                          </>
                        )}
                      </div>
                      <div className="mt-3">
                        {updateStatusFormData && !isEdit && (
                          <DymamicForm
                            formControls={""}
                            userFormData={updateStatusFormData}
                            columns={1}
                            cancelRoute={""}
                            isEdit={false}
                            onChildEvent={(e) => onSubmitStatusForm(e)}
                            // onChangeRadioButton={(e) => onChangeRadioButton(e)}
                            customClass={"display-block text-center col-12"}
                            isNotEmptyFormAfterSubmit={true}
                          />
                        )}

                      </div>
                    </div>
                    <div className="col-9">
                      <div className="d-flex">
                        <div className="fw-bold fs-5 text-dark text-start feed_text">
                          {selectedData?.feed_title}
                        </div>
                      </div>
                      {selectedData?.feed_source_platform === "NEWS" ? (
                        <div className="card-body px-0">
                          <div
                            className="text-start source_data"
                            dangerouslySetInnerHTML={{
                              __html: selectedData?.feed_body,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <div className="card-body px-0">
                          <div
                            className="text-start source_data"
                            dangerouslySetInnerHTML={{
                              __html: selectedData?.feed_description,
                            }}
                          ></div>
                        </div>
                      )}{" "}
                      <a href={selectedData?.feed_url} target="_blanck">
                        {selectedData?.feed_url}
                      </a>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { DataTableCol } from "../../../components/data-table/types";

export const EventsDataGridCols: DataTableCol[] = [
  {
    title: "Title",
    control: "event_title",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Order Number",
    control: "order_no",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Date",
    control: "event_date",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Description",
    control: "event_description",
    sortable: true,
    canShowColumn: true,
    isTextOverflowHide: true,
  },
  {
    title: "Status",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];

import React, { useEffect, useState } from "react";
import { DataTable } from "../../../../components/data-table";
import { Search } from "../../../../components/search/search";
import { EditorsDataGridCols } from "./editors-data-grid-cols";
import { GETEDITORS } from "../../../../services/users.service";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../../components/form-builder/form";
import { GETROLES } from "../../../../services/roles.service";
import { GETINTERESTS } from "../../../../services/interests.service";
import {
  SYSTEMUSERCREATE,
  SYSTEMUSERDELETE,
  SYSTEMUSERUPDATE,
} from "../../../../services/system-users.service";
import { GETSUBINTERESTS } from "../../../../services/sub-interests.service";
import CustomModel from "../../../../components/modal/custom-model";

export const EditorsPage = () => {
  const loginUserData: any = sessionStorage.getItem("loginUserData");
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [interestsList, setInterestsList] = useState<any>([]);
  const [editorsList, setEditorsList] = useState<any>();
  const [loginUserInfo, setLoginUserInfo] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editorFormData, setEditorFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "user_name",
      value: "",
      required: true,
      regex: /^[A-Za-z ,.'-]+$/,
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "ADD",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Email",
      name: "user_email",
      value: "",
      required: true,
      regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
      maxLength: "",
      placeholder: "Please enter email",
      error: "Please enter valid email",
    },
    {
      title: "input",
      control: "",
      type: "password",
      label: "Password",
      name: "user_password",
      value: "",
      required: true,
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      maxLength: "",
      placeholder: "Please enter password",
      error:
        "Please enter valid password, Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Phone",
      name: "user_phone",
      value: "",
      required: true,
      regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      maxLength: "",
      placeholder: "Please enter phone",
      error: "Please enter valid phone",
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Interest",
      name: "user_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select interest",
      error: "Please select interest",
      isParendDropDown: true,
      isApiCall: true,
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Sub interest",
      name: "user_sub_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select sub interest",
      error: "Please select sub interest",
      isApiCall: false,
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Role",
      name: "user_role_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select role",
      error: "Please select role",
    },
    // {
    //   title: "input",
    //   control: "",
    //   type: "file",
    //   label: "Image",
    //   name: "user_image",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   placeholder: "Please select image",
    //   error: "Please select image",
    // },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
  ]);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const handleModalClosed = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (loginUserData) {
      setLoginUserInfo(JSON.parse(loginUserData));
    }
    getEditorsList();
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Name",
        name: "user_name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        submitButtonName: "ADD",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Email",
        name: "user_email",
        value: "",
        required: true,
        regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
        maxLength: "",
        placeholder: "Please enter email",
        error: "Please enter valid email",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Password",
        name: "user_password",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter password",
        error: "Please enter valid password",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Phone",
        name: "user_phone",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter phone",
        error: "Please enter valid phone",
      },
      {
        title: "multiDropdown",
        control: "",
        type: "text",
        label: "Interest",
        name: "user_interest_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select interest",
        error: "Please select interest",
        isParendDropDown: true,
        isApiCall: true,
      },
      {
        title: "multiDropdown",
        control: "",
        type: "text",
        label: "Sub interest",
        name: "user_sub_interest_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select sub interest",
        error: "Please select sub interest",
        isApiCall: false,
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Role",
        name: "user_role_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select role",
        error: "Please select role",
      },
      // {
      //   title: "input",
      //   control: "",
      //   type: "file",
      //   label: "Image",
      //   name: "user_image",
      //   value: "",
      //   regex: "",
      //   required: false,
      //   placeholder: "Please select image",
      //   error: "Please select image",
      // },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setEditorFormData(formDataFields);
    getInterestsList();
    getRolesList();
  }, []);

  const getInterestsList = () => {
    GETINTERESTS({ limit: 0, page: 1 }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
      } else {
        if (res) {
          setInterestsList(res.data);
          const data = res.data?.data;
          data.unshift({
            id: -1,
            interest_keywords: [],
            interest_name: "ALL",
            is_active: 1,
            label: "ALL",
            sub_interest_count: -1,
            uuid: "ALL",
            value: "ALL",
          });
          data.forEach((element: any) => {
            element.label = element?.interest_name;
            element.value = element?.uuid;
          });
          const formData = editorFormData;
          if (formData) {
            formData[4].option = data;
            setEditorFormData([...formData]);
          }
        }
      }
    });
  };
  const getRolesList = () => {
    GETROLES("").then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
      } else {
        if (res) {
          const data = res.data?.data;
          data.forEach((element: any) => {
            element.label = element?.role_name;
            element.value = element?.uuid;
          });
          const formData = editorFormData;
          if (formData) {
            formData[6].option = data;
            setEditorFormData([...formData]);
          }
          setRolesData(res.data?.data);
        }
      }
    });
  };
  const getEditorsList = () => {
    setLoading(true);
    GETEDITORS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setEditorsList(res.data);
          setLoading(false);
        }
      }
    });
  };

  const onActions = (data: any) => {
    const editorData = data?.data;
    setSelectedUser(editorData);
    if (data?.type === "edit") {
      setIsEdit(true);
      let selectedIntrests: any;
      // result = firstArray.filter(o => secondArray.some(({id,name}) => o.id === id && o.name === name));
      if (editorData?.user_interest_uuid[0] === "ALL") {
        selectedIntrests = interestsList?.data;
      } else {
        selectedIntrests = interestsList?.data.filter((o: any) =>
          editorData?.user_interest_uuid.some((uuid: any) => o.uuid === uuid)
        );
      }
      onSelectIntrest(
        { data: selectedIntrests, isApi: true },
        true,
        editorData
      );
      data.data.user_interest_uuid = selectedIntrests;
      const newData = editorFormData;
      newData[0].submitButtonName = "UPDATE EDITOR";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setEditorFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${editorData?.user_name} user!`
      );
      setShowDeleteModal(true);
    }
  };
  const onSelectIntrest = (value: any, isEdit: any, selectedUser: any) => {
    if (value.isApi) {
      const multipleIntrests: any = [];
      if (value?.data) {
        value?.data?.forEach((element: any) => {
          multipleIntrests.push(element.uuid);
        });
      }
      GETSUBINTERESTS({
        multiple_interest_uuid: multipleIntrests.toString(),
        limit: 0,
        page: 1,
      }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
        } else {
          if (res) {
            const data = res.data?.data;
            var selectedSubIntrests;
            const formData = editorFormData;
            if (isEdit) {
              if (selectedUser?.user_sub_interest_uuid[0] === "ALL") {
                selectedSubIntrests = data;
              } else {
                selectedSubIntrests = data.filter((o: any) =>
                  selectedUser?.user_sub_interest_uuid.some(
                    (uuid: any) => o.uuid === uuid
                  )
                );
              }
            }
            data.unshift({
              id: -1,
              uuid: "ALL",
              interest_uuid: "ALL",
              sub_interest_name: "ALL",
              sub_interest_keywords: [],
              is_active: 1,
            });
            data.forEach((element: any) => {
              element.label = element?.sub_interest_name;
              element.value = element?.uuid;
            });
            if (formData) {
              formData[4].value = [...value?.data];
              formData[5].option = data;
              formData[5].value = selectedSubIntrests;
              setEditorFormData([...formData]);
            }
          }
        }
      });
    } else {
      const formData = editorFormData;
      if (formData) {
        formData[5].value = [...value?.data];
        setEditorFormData([...formData]);
      }
    }
  };
  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    const roleType = rolesData?.find(
      (element: any) => element.uuid === formData.user_role_uuid
    );
    const intrestList: any = [];
    const isAllIntrests = formData.user_interest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllIntrests) {
      intrestList.push("ALL");
    } else {
      formData.user_interest_uuid.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
    }
    const subIntrestList: any = [];
    const isAllSubIntrests = formData.user_sub_interest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllSubIntrests) {
      subIntrestList.push("ALL");
    } else {
      formData.user_sub_interest_uuid.forEach((element: any) => {
        subIntrestList.push(element?.uuid);
      });
    }
    formData.verified = 1;
    formData.user_type = roleType?.role_name;
    formData.verified_by = loginUserInfo?.uuid;
    formData.user_interest_uuid = intrestList;
    formData.user_sub_interest_uuid = subIntrestList;
    formData.user_image = "";
    if (isEdit) {
      formData.uuid = selectedUser.uuid;
      setLoading(true);
      SYSTEMUSERUPDATE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getEditorsList();
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      SYSTEMUSERCREATE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getEditorsList();
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const ShowPopup = () => {
    setShowModal(true);
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getEditorsList();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getEditorsList();
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };

  const onDeleteUser = () => {
    setLoading(true);
    SYSTEMUSERDELETE(selectedUser?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getEditorsList();
          setLoading(false);
        }
      }
    });
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-md-12 px-0">
          <Search />
        </div>
        <div className="col-md-12 d-flex justify-content-end editor-position mb-3 mb-md-0">
          <button
            className="custom-bg-btn rounded border-0 text-white me-2"
            onClick={ShowPopup}
          >
            Create Editor
          </button>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12 px-0 users-editors-table">
            <DataTable
              tableData={editorsList}
              TableCols={EditorsDataGridCols}
              onActions={(e) => onActions(e)}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
          </div>
        )}
      </div>

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">
                {isEdit ? "Update Editor" : "Create Editor"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClosed}
              ></button>
            </div>
            <div className="modal-body text-start">
              {editorFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={editorFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={isEdit}
                  onChildEvent={(e) => onSubmitForm(e)}
                  onParentDropdownEvent={(e) => onSelectIntrest(e, false, "")}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn custom-bg-btn-outline-light px-4 py-1 me-2"
                data-bs-dismiss="modal"
                onClick={handleModalClosed}
              >
                Close
              </button>
              <button
                type="button"
                className="custom-bg-btn text-white border-0 px-4 py-1 me-2"
                onClick={onSubmit}
              >
                Send
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <CustomModel
          modalTitle={"Delete"}
          ismodalShow={showDeleteModal}
          modalStatus={getModalStatus}
          modalHeaderText={deleteModalBody}
          modalType={"delete"}
          deleteStatus={onDeleteUser}
        />
      )}
    </div>
  );
};

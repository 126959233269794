import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { ConciergeUsers } from "../../../Routes/concierge-users";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATE_CONCIERGE_USER } from "../../../services/concierge-users.service";
import { DymamicForm } from "../../../components/form-builder/form";

export const ConciergeUsersPage = () => {
  const location = useLocation();
  const urlPath = location?.pathname.split("/");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const breadcumInfo = [
    {
      label: "CONCIERGE USER",
      value: "CONCIERGE USER",
      routerLink: "/dashboard/concierge-users",
      isActive: true,
    },
  ];
  const navigate = useNavigate();
  const [conciergeUserFormData, setConciergeUserFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "name",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "ADD CONCIERGE USER",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "email",
      name: "email",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter email",
      error: "Please enter email",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Phone",
      name: "phone",
      value: "",
      required: true,
      regex: /^[6-9]\d{9}$/i,
      maxLength: "",
      placeholder: "Enter phone",
      error: "Please enter phone",
    },
  ]);

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Name",
        name: "name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        submitButtonName: "ADD CONCIERGE USER",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Email",
        name: "email",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter email",
        error: "Please enter email",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Phone",
        name: "phone",
        value: "",
        required: true,
        regex: /^[6-9]\d{9}$/i,
        maxLength: "",
        placeholder: "Enter phone",
        error: "Please enter phone",
      },
    ];
    setConciergeUserFormData(formDataFields);
  }, []);


  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    setLoading(true);
    CREATE_CONCIERGE_USER(formData).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          navigate(`/dashboard/concierge`);
          setShowModal(false);
          toast.success(res.message);
          setLoading(false);
        }
      }
    });
  };

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const ShowPopup = () => {
    const newData = conciergeUserFormData;
    newData[0].submitButtonName = "ADD CONCIERGE USER";
    newData.forEach((element: any) => {
      element.value = "";
    });
    setConciergeUserFormData([...newData]);
    setShowModal(true);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
            <div className="mt-3 pe-1">
              <button
                className="custom-bg-btn rounded border-0 text-white me-2"
                onClick={ShowPopup}
              >
                Create Concierge Users
              </button>
            </div>
            {/* <div>
              <div>
                {searchForm && (
                  <DymamicForm
                    formControls={""}
                    userFormData={searchForm}
                    columns={4}
                    cancelRoute={""}
                    isEdit={false}
                    onChildEvent={(e) => onSearchEvent(e)}
                    isNotEmptyFormAfterSubmit={true}
                  />
                )}
              </div>
              <div className="mt-3 pe-1">
                <button
                  className="custom-bg-btn rounded border-0 text-white me-2"
                  onClick={ShowPopup}
                >
                  Create Concierge Users
                </button>
              </div>
            </div> */}
          </div>
          <div className="px-2 mx-1">
            <ul className="nav nav-tabs">

              <li className="nav-item">
                <NavLink
                  to={`/dashboard/concierge/pending`}
                  className={`nav-link text-decoration-none text-dark ${(urlPath.length ===
                    3 &&
                    urlPath[2] === "concierge") &&
                    "active"} ${urlPath[3] === "events" && "active"}`}
                >
                  Pending
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={`/dashboard/concierge/scheduled`}
                  className="nav-link text-decoration-none text-dark"
                >
                  Scheduled {(
                    urlPath[2] === "concierge")}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={`/dashboard/concierge/completed`}
                  className="nav-link text-decoration-none text-dark"
                >
                  Completed
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={`/dashboard/concierge/subscribed`}
                  className="nav-link text-decoration-none text-dark"
                >
                  Subscribed
                </NavLink>
              </li>
            </ul>
            <div className="px-0">
              <ConciergeUsers />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">
                Create Concierge User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClosed}
              ></button>
            </div>
            <div className="modal-body text-start">
              <DymamicForm
                formControls={""}
                userFormData={conciergeUserFormData}
                columns={1}
                cancelRoute={""}
                isEdit={false}
                onChildEvent={(e) => onSubmitForm(e)}
                customClass={"display-block text-center col-12"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

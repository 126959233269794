import API from "../utils/axios";

export const GETSOCIALSOURCES = (data: any): Promise<any> => {
  return API.get(`/socialSources/fetch`, { params: data });
};

export const ADDSOCIALSOURCE = (data: any): Promise<any> => {
  return API.post(`/socialSources/create`, data);
};

export const UPDATESOCIALSOURCE = (data: any): Promise<any> => {
  return API.put(`/socialSources/update`, data);
};

export const DELETESOCIALSOURCE = (uuid: string): Promise<any> => {
  return API.delete(`/socialSources/delete/${uuid}`);
};

export const FETCH_INDIVIDUAL_FEED = (data: any): Promise<any> => {
  return API.post(`/socialSources/fetch_individual_feed`, data);
};

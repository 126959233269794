import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { S3Helper } from "../../../utils/s3-helper";
import Multiselect from "multiselect-react-dropdown";
import { makeStyles } from "@material-ui/core/styles";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GENERATE_PRESIGNED_URL } from "../../../services/editor-panel.service";
import ThumbsUp from "../../../assets/images/thumbs-up.svg";
import ThumbsDown from "../../../assets/images/thumbs-down.svg";
import ThumbsUpYellow from "../../../assets/images/thumbs-up-yellow.svg";
import ThumbsDownYellow from "../../../assets/images/thumbs-down-yellow.svg";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

interface Props {
  columns: number;
  formControls: any;
  userFormData: any;
  cancelRoute: any;
  isEdit: boolean;
  onChildEvent: (data: string) => void;
  customClass?: any;
  loading?: any;
  custom_button_text?: any;
  isNotEmptyFormAfterSubmit?: boolean;
  onParentDropdownEvent?: (data: any) => void;
  onChangeRadioButton?: (data: any) => void;
}

export const DymamicForm = (props: Props) => {
  const [formData, setFormData] = useState<any>({});
  const [formValues, setFormValues] = useState<any>({});
  const [isformSubmit, setIsFormSubmit] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [formFieldsArray, setFormFieldsArray] = useState<any[]>([]);
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [uploadedImage, setUploadedImage] = useState<any>({ url: '' });
  const [image, setImage] = useState<any>('');


  useEffect(() => {
    const formsValues: any = {};
    props?.userFormData?.forEach((element: any) => {
      formsValues[element.name] = element.value ? element.value : "";
    });
    setFormData(formsValues);
    setFormFieldsArray([...props.userFormData]);
  }, [props]);

  useEffect(() => {
    setImage('')
  }, [props?.isEdit]);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 300,
    },
    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps: any = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };
  const classes = useStyles();
  const handleInput = (e: any, index: number, data: any) => {
    const formData = formFieldsArray;
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: data?.type === "checkbox" ? e.target.checked : value,
    }));
    // Validate field based on regex pattern
    const field = formFieldsArray[index];
    const { regex, required, label, error } = field;
    const newErrors: any = { ...errors };

    // if (regex && value && !RegExp(regex).test(value)) {
    //   newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    // } else {
    //   delete newErrors[name]; // Remove the error if the input is valid
    // }

    if (required && (!value || value === "")) {
      newErrors[name] = `Please enter a ${label.toLowerCase()}`;
      // isValid = false;
    } else if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = field?.onChangeError
        ? field?.onChangeError
        : error || `Please enter a valid ${label.toLowerCase()}`;
      // isValid = false;
    } else {
      delete newErrors[name];
    }
    formData[index].value =
      data?.type === "checkbox" ? e.target.checked : value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
    if (field?.isParendDropDown) {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent(value);
      }
    }
  };

  const handleImageChange = (e: any, index: any, data: any) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleInputFileUpload = (e: any, index: number, data: any) => {
    const imageData = e.target.files[0];
    let fileName = imageData?.name;
    fileName = fileName.split('.');
    fileName = `${fileName[0]}${Date.now()}.${fileName[1]}`;
    GENERATE_PRESIGNED_URL({ file_name: fileName }).then((res: any) => {
      if (res) {
        const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_url,
          imageData,
          imageData.type,
        );
        const formData = formFieldsArray;
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: `${res.data?.s3_url}/${res.data?.folder_path}`,
        }));
        const field = formFieldsArray[index];
        const { regex, label } = field;
        const newErrors: any = { ...errors };

        if (regex && value && !RegExp(regex).test(value)) {
          newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
        } else {
          delete newErrors[name]; // Remove the error if the input is valid
        }
        formData[index].value = `${res.data?.s3_url}/${res.data?.folder_path}`;

        const file = e.target.files[0];

        if (file) {
          const reader = new FileReader();

          reader.onloadend = () => {
            setImage(reader.result);
          };

          reader.readAsDataURL(file);
        }
        // setUploadedImage(`${res.data?.s3_url}/${res.data?.folder_path}`)
        setFormFieldsArray([...formData]);
        setErrors(newErrors);
      }
    })
  };

  const onChangeCkEditor = (event: any, editor: any, name: string, index: number) => {
    const data = editor.getData();
    const value = data
    const formData = formFieldsArray;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const field = formFieldsArray[index];
    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name]; // Remove the error if the input is valid
    }
    formData[index].value = value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
  }

  const onSelect = (
    selectedList: any,
    selectedItem: any,
    index: number,
    element: string
  ) => {
    const formFields = formFieldsArray;
    if (selectedItem.value === "ALL") {
      formFields[index].value = formFields[index].option;
      setFormData((prevData: any) => ({
        ...prevData,
        [element]: "",
      }));
      // if (formFields[index]?.isParendDropDown) {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: formFields[index].option,
          isApi: formFields[index].isApiCall,
        });
      }
      // }
      setFormFieldsArray([...formFields]);
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [element]: [...selectedList],
      }));
      // if (formFields[index]?.isParendDropDown) {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: selectedList,
          isApi: formFields[index].isApiCall,
        });
      }
      // }
    }
  };

  const onRemove = (
    selectedList: any,
    removedItem: any,
    index: number,
    element: string
  ) => {
    if (removedItem.value === "ALL") {
      const formFields = formFieldsArray;
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: "",
          isApi: formFields[index].isApiCall,
        });
      }
      setFormFieldsArray([...formFields]);
    } else {
      const formFields = formFieldsArray;
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: selectedList,
          isApi: formFields[index].isApiCall,
        });
      }
      setFormFieldsArray([...formFields]);
    }
  };

  const onClickSubmit = (e: any) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setErrors({});
      const data: any = { formData, type: props.isEdit ? "edit" : "create" };
      props.onChildEvent(data);
      if (!props?.isNotEmptyFormAfterSubmit) {
        formFieldsArray.forEach((element: any) => {
          element.value = "";
        });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};
    formFieldsArray?.forEach((field: any) => {
      const { name, label, required, regex, error } = field;
      const value =
        typeof formData[name] === "string"
          ? formData[name]?.trim() || field.value
          : formData[name] || field.value;
      if (required && (!value || value === "")) {
        newErrors[name] = error || `Please enter a ${label?.toLowerCase()}`;
        isValid = false;
      }
      if (regex && value && !RegExp(regex).test(value)) {
        newErrors[name] =
          error || `Please enter a valid ${label?.toLowerCase()}`;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const onClickCancel = () => {
    navigate(props.cancelRoute);
  };

  const onLike = (index: number, name: string, value: any) => {
    const formData = formFieldsArray;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const field = formFieldsArray[index];
    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name]; // Remove the error if the input is valid
    }
    formData[index].value = value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
  };

  const onDisLike = (index: number, name: string, value: any) => {

    const formData = formFieldsArray;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const field = formFieldsArray[index];
    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name]; // Remove the error if the input is valid
    }
    formData[index].value =
      value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
  };

  const onDoubleLike = (index: number, name: string, value: any) => {

    const formData = formFieldsArray;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const field = formFieldsArray[index];
    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name]; // Remove the error if the input is valid
    }
    formData[index].value =
      value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
  };
  const onChangeDateTime = (index: number, name: string, value: any) => {

    const formData = formFieldsArray;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    const field = formFieldsArray[index];
    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name]; // Remove the error if the input is valid
    }
    formData[index].value =
      value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
  }

  const handleRadioInput = (e: any, index: number, data: any) => {
    const formData = formFieldsArray;
    formData.forEach((element: any) => {
      element.checked = false;
    });
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: e.target.checked
    }));
    const field = formFieldsArray[index];
    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name];
    }
    formData[index].checked = e.target.checked;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
    if (field?.isChageRadioButton) {
      if (props?.onChangeRadioButton) {
        props?.onChangeRadioButton(value);
      }
    }
  };
  return (
    <div className="row" >
      {/* <div className="row" style={{
        maxHeight: "calc(100vh - 225px)",
        overflow: "auto"
      }}> */}
      {formFieldsArray?.map((data: any, index: number) => (
        <React.Fragment key={index}>
          {data.title === "input" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <input
                type={data.type}
                name={data.name}
                className={`${data.type === "checkbox" ? "" : "form-control"}`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                maxLength={data.maxLength}
                value={data.value}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}

          {data.title === "textarea" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <textarea
                name={data.name}
                className={`${data.type === "checkbox" ? "" : "form-control"}`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                maxLength={data.maxLength}
                value={data.value}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}

          {data.title === "radio" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <input
                type={data.type}
                name={data.name}
                className={``}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleRadioInput(event, index, data);
                }}
                required={data.required}
                value={data.value}
                checked={data.checked}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}

          {data.title === "dropdown" && (
            <div className={`col-md-${12 / props?.columns} mb-2`}>
              <label className="form-label d-flex justify-content-start">
                {data.label} {data.required}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <select
                name={data.name}
                className="form-select custom-select"
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                value={data.value}
              >
                <option value="" disabled={data?.required}>
                  {data.placeholder}
                </option>
                {data.option?.map((option: any, i: number) => {
                  return (
                    <option key={i} value={option?.value}>
                      {option?.label}
                    </option>
                  );
                })}
              </select>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "multiDropdown" && (
            <>
              <div className={`col-md-${12 / props?.columns} mb-2`}>
                <label className="form-label d-flex justify-content-start">
                  {data.label} {data.required}
                  {data.required && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}
                </label>
                <Multiselect
                  placeholder={data.placeholder}
                  options={data.option}
                  selectedValues={data?.value}
                  onSelect={(selectedList, selectedItem) =>
                    onSelect(selectedList, selectedItem, index, data?.name)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    onRemove(selectedList, selectedItem, index, data?.name)
                  }
                  isObject={true}
                  avoidHighlightFirstOption={true}
                  showCheckbox={true}
                  hidePlaceholder={true}
                  displayValue="label"
                  style={{
                    chips: { background: "#074795" },
                    searchBox: {
                      height: "65px",
                      overflow: "hidden",
                      padding: "5px 10px 0px 10px",
                    },
                  }}
                />
                {Object.keys(errors).length > 0 && (
                  <div className="text-danger text-start">
                    {errors[data.name] || ""}
                  </div>
                )}
              </div>
            </>
          )}

          {data.title === "file" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >

              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>

              {(image || data?.value) && data.accept !== 'application/pdf' && <div style={{ height: '150px', width: "200px" }}>
                <img src={image || data?.value} alt="image" key={image || data?.value} className="rounded" style={{ height: '100%', width: "100%" }} />
              </div>}
              <input className="mt-2" type="file" name={data.name} accept={data.accept} onChange={(event) => {
                handleInputFileUpload(event, index, data);
              }} />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "ckEditor" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <CKEditor
                editor={ClassicEditor}
                config={
                  { toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'] }
                }
                data={data.value}
                onChange={(event, editor) => onChangeCkEditor(event, editor, data.name, index)}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>)}
          {data.title === "range" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}: {data.value}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <input
                type={data.type}
                name={data.name}
                min={data.min}
                max={data.max}
                id="impact_meter"
                className={`slider`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                maxLength={data.maxLength}
                value={data.value}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "like" &&
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}: {data.value}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <div className="mx-1 mx-sm-3 mx-md-1 mx-lg-2 pt-2 px-0 px-lg-0">
                {data?.value ? (
                  <img
                    src={ThumbsUpYellow}
                    className={`mb-1 pb-1 cursor-pointer`}
                    onClick={() => onLike(index, data.name, false)}
                  />
                ) : (
                  <img
                    src={ThumbsUp}
                    className={`mb-1 pb-1 cursor-pointer`}
                    onClick={() => onLike(index, data.name, true)}
                  />
                )}
              </div>
            </div>}
          {data.title === "disLike" &&
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}: {data.value}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <div className="mx-1 mx-sm-3 mx-md-1 mx-lg-2 pt-2 px-0 px-lg-0">
                {data?.value ? (
                  <img
                    src={ThumbsDownYellow}
                    className={`pb-1 cursor-pointer`}
                    onClick={() => onDisLike(index, data.name, false)}
                  />
                ) : (
                  <img
                    src={ThumbsDown}
                    className={`pb-1 cursor-pointer`}
                    onClick={() => onDisLike(index, data.name, true)}
                  />
                )}
              </div>
            </div>}
          {data.title === "doubleLike" &&
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}: {data.value}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <div className="mx-0 mx-sm-3 mx-md-1 mx-lg-0pt-2 px-0 px-lg-0 mt-3">
                <div style={{ width: "100px" }}>
                  {data?.value ? (
                    <img
                      src={ThumbsUpYellow}
                      className={`mb-1 pb-1 cursor-pointer`}
                      onClick={() => onDoubleLike(index, data.name, false)}
                    />
                  ) : (
                    <img
                      src={ThumbsUp}
                      className={`mb-1 pb-1 cursor-pointer`}
                      onClick={() => onDoubleLike(index, data.name, true)}
                    />
                  )}{" "}
                  <span className="mt-1 my-0">2</span>
                </div>
              </div>
            </div>}
          {data.title === "datetime" &&
            <div className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
              } mb-2 text-start`}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DemoItem label={data.label}>
                    <DateTimePicker disablePast value={dayjs(data?.value)}
                      onChange={(newValue) => onChangeDateTime(index, data.name, newValue)} />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>}
        </React.Fragment>
      ))}
      {/* </div> */}
      {/* <div className="row"> */}
      <div
        className={`${props?.customClass
          ? props?.customClass
          : `d-flex col-md-${12 / props?.columns}`
          } ${formFieldsArray[0]?.label && 'mt-4'} pt-1 `}
      >
        <button
          className={
            formFieldsArray[0]?.submitButtonName
              ? `custom-bg-btn rounded border-0 text-white me-2`
              : "border-0 bg-transparent"
          }
          onClick={onClickSubmit}
        >
          {formFieldsArray[0]?.submitButtonName === "Login" ||
            formFieldsArray[0]?.submitButtonName === "SUBMIT" ? (
            <>
              {props.loading ? "" : formFieldsArray[0]?.submitButtonName}
              {props.loading && (
                <div className="text-center px-3">
                  <div
                    className="spinner-border login-spinner-border text-white"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>{formFieldsArray[0]?.submitButtonName}</>
          )}
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

import { useEffect, useState } from "react";
import { ActiveUsersDataGridCols } from "./acrive-users-data-grid-cols";
import { Search } from "../../../../components/search/search";
import { DataTable } from "../../../../components/data-table";
import { toast } from "react-toastify";
import { DELETEEDITORS, GETUSERS } from "../../../../services/users.service";
import CustomModel from "../../../../components/modal/custom-model";
import { useNavigate } from "react-router-dom";

export const ActiveUsersList = () => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [activeUserData, setActiveUserData] = useState<any>([]);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
    is_active: "active",
  });
  useEffect(() => {
    getActiveUserData();
  }, []);

  const getActiveUserData = () => {
    setLoading(true);
    GETUSERS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setActiveUserData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const onActions = (data: any) => {
    if (data?.type === "delete") {
      setSelectedUser(data?.data);
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.user_name} user!`
      );
      setShowDeleteModal(true);
    } else if (data?.type === "event") {
      sessionStorage.setItem('eventUserUUID', JSON.stringify(data?.data))
      navigate("/dashboard/users/events");
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getActiveUserData();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getActiveUserData();
  };
  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getActiveUserData();
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETEEDITORS(selectedUser?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getActiveUserData();
          setLoading(false);
        }
      }
    });
  };
  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 px-0">
          <div className="ps-2">
            <Search onSearch={(e) => onSearch(e)} />
          </div>
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <DataTable
              tableData={activeUserData}
              TableCols={ActiveUsersDataGridCols}
              onActions={(e) => onActions(e)}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
          )}
        </div>
      </div>
      {showDeleteModal && (
        <CustomModel
          modalTitle={"Delete"}
          ismodalShow={showDeleteModal}
          modalStatus={getModalStatus}
          modalHeaderText={deleteModalBody}
          modalType={"delete"}
          deleteStatus={onDeleteUser}
        />
      )}
    </div>
  );
};

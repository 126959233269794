import { DataTableCol } from "../data-table/types";

export const MostPostedFeedUserInfoReportGridCols: DataTableCol[] = [
    {
        title: "User Name",
        control: "user_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "User Phone",
        control: "user_phone",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Body",
        control: "post_body",
        sortable: true,
        canShowColumn: true,
        isHtml: true,
    },
    {
        title: "Post Platform",
        control: "post_to_platform",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Emotion",
        control: "post_emotion",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Length",
        control: "post_length",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Sentiment",
        control: "post_sentiment",
        sortable: true,
        canShowColumn: true,
    }
];

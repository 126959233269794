import { DataTableCol } from "../../../components/data-table/types";

export const FetchPostsDataGridCols: DataTableCol[] = [
  {
    title: "Social Platform",
    control: "social_platform",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Social Media Post Url",
    control: "social_media_post_url",
    sortable: true,
    canShowColumn: true,
  },
  // {
  //   title: "Actions",
  //   control: "both",
  //   sortable: false,
  //   isEdit: true,
  //   // isDelete: true,
  //   canShowColumn: true,
  // },
];

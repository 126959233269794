import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { DashboardRoutes } from "../../Routes/dashboard";
import { SidebarPage } from "../../components/sidebar/sidebar";
import headerImage from "./../../assets/images/Primary Logo.png";
import CustomModel from "../../components/modal/custom-model";
import { GETSOCIALFEEDS } from "../../services/editor-panel.service";
import { toast } from "react-toastify";
import { DymamicForm } from "../../components/form-builder/form";
import {
  CHANGE_PASSWORD,
  SYSTEMUSERUPDATE,
} from "../../services/system-users.service";
import { GETSUBINTERESTS } from "../../services/sub-interests.service";
import { GETINTERESTS } from "../../services/interests.service";
import { GETEDITORS } from "../../services/users.service";
import LOGO from "../../assets/images/LdrX Logo Horiz.png"

export const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [showlogoutModal, setShowLogoutModal] = useState(false);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [interestsList, setInterestsList] = useState<any>([]);
  const navigate = useNavigate();
  const loginUserData: any = sessionStorage.getItem("loginUserData");
  const [editorFormData, setEditorFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "user_name",
      value: "",
      required: true,
      regex: /^[A-Za-z ,.'-]+$/,
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "SUBMIT",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Email",
      name: "user_email",
      value: "",
      required: true,
      regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,5}$/,
      maxLength: "",
      placeholder: "Please enter email",
      error: "Please enter valid email",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Phone",
      name: "user_phone",
      value: "",
      required: true,
      regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      maxLength: "",
      placeholder: "Please enter phone",
      error: "Please enter valid phone",
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Interest",
      name: "user_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select interest",
      error: "Please select interest",
      isParendDropDown: true,
      isApiCall: true,
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Sub interest",
      name: "user_sub_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select sub interest",
      error: "Please select sub interest",
      isApiCall: false,
    },
  ]);

  const [changePasswordFormData, setChangePasswordFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Current password",
      name: "current_password",
      value: "",
      required: true,
      regex: "", ///^[A-Za-z ,.'-]+$/,
      maxLength: "",
      placeholder: "Enter current password",
      error: "Please enter current password",
      submitButtonName: "SUBMIT",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "New password",
      name: "new_password",
      value: "",
      required: true,
      regex: "", ///^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{3}$/,
      maxLength: "",
      placeholder: "Please enter new password",
      error: "Please enter valid new password",
    },
  ]);
  const [loginUserInfo, setLoginUserInfo] = useState<any>();
  const [loginUserDataObj, setLoginUserDataObj] = useState<any>();

  useEffect(() => {
    if (!loginUserData) {
      navigate("/");
    }
    if (loginUserData) {
      const userData = JSON.parse(loginUserData);
      setLoginUserInfo(userData);
      getLoginUserDetails(userData?.uuid);
    }
    getInterestsList();
  }, []);

  const getLoginUserDetails = (uuid: string) => {
    setLoading(true);
    GETEDITORS({ limit: 0, page: 1, uuid }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setLoginUserDataObj(res?.data?.data[0]);
          setLoading(false);
        }
      }
    });
  };
  const getInterestsList = () => {
    setLoading(true);
    GETINTERESTS({ limit: 0, page: 1 }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setInterestsList(res.data);
          const data = res.data?.data;
          data.unshift({
            id: -1,
            interest_keywords: [],
            interest_name: "ALL",
            is_active: 1,
            label: "ALL",
            sub_interest_count: -1,
            uuid: "ALL",
            value: "ALL",
          });
          data.forEach((element: any) => {
            element.label = element?.interest_name;
            element.value = element?.uuid;
          });
          const formData = editorFormData;
          if (formData) {
            formData[3].option = data;
            setEditorFormData([...formData]);
          }
          setLoading(false);
        }
      }
    });
  };
  const onClickLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const getModalStatus = (status: any) => {
    setShowLogoutModal(false);
  };
  const onSubmitChangePasswordForm = (data: any) => {
    const formData = data?.formData;
    formData.user_email = loginUserInfo?.user_email;
    setLoading(true);
    CHANGE_PASSWORD(formData).then((res: any) => {
      if (res.status === "error") {
        toast.error(res.message);
        setLoading(false);
        // setShowModalChangePassword(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowModalChangePassword(false);
          setLoading(false);
        }
      }
    });
  };
  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    // const roleType = rolesData?.find(
    //   (element: any) => element.uuid === formData.user_role_uuid
    // );
    const intrestList: any = [];
    const isAllIntrests = formData.user_interest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllIntrests) {
      intrestList.push("ALL");
    } else {
      formData.user_interest_uuid.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
    }
    const subIntrestList: any = [];
    const isAllSubIntrests = formData.user_sub_interest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllSubIntrests) {
      subIntrestList.push("ALL");
    } else {
      formData.user_sub_interest_uuid.forEach((element: any) => {
        subIntrestList.push(element?.uuid);
      });
    }
    formData.verified = 1;
    formData.user_type = loginUserInfo?.user_type;
    formData.user_password = loginUserDataObj?.user_password;
    formData.user_role_uuid = loginUserInfo?.user_role_uuid;
    formData.is_active = loginUserInfo?.is_active;
    formData.verified_by = loginUserInfo?.uuid;
    formData.user_interest_uuid = intrestList;
    formData.user_sub_interest_uuid = subIntrestList;
    formData.user_image = "";
    formData.uuid = loginUserInfo?.uuid;
    setLoading(true);
    SYSTEMUSERUPDATE(formData).then((res: any) => {
      if (res.status === "error") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setShowModalProfile(false);
          toast.success(res.message);
          getLoginUserDetails(loginUserInfo?.uuid);
          setLoading(false);
        }
      }
    });
  };

  const onSelectIntrest = (value: any, isEdit: any, selectedUser: any) => {
    if (value.isApi) {
      const multipleIntrests: any = [];
      if (value?.data) {
        value?.data?.forEach((element: any) => {
          multipleIntrests.push(element.uuid);
        });
      }
      setLoading(true);
      GETSUBINTERESTS({
        multiple_interest_uuid: multipleIntrests.toString(),
        limit: 0,
        page: 1,
      }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            const data = res.data?.data;
            var selectedSubIntrests;
            const formData = editorFormData;
            if (isEdit) {
              if (
                selectedUser?.user_sub_interest_uuid &&
                selectedUser?.user_sub_interest_uuid[0] === "ALL"
              ) {
                selectedSubIntrests = data;
              } else {
                selectedSubIntrests = data?.filter((o: any) =>
                  selectedUser?.user_sub_interest_uuid?.some(
                    (uuid: any) => o.uuid === uuid
                  )
                );
              }
            }
            data.unshift({
              id: -1,
              uuid: "ALL",
              interest_uuid: "ALL",
              sub_interest_name: "ALL",
              sub_interest_keywords: [],
              is_active: 1,
            });
            data.forEach((element: any) => {
              element.label = element?.sub_interest_name;
              element.value = element?.uuid;
            });
            if (formData) {
              formData[3].value = [...value?.data];
              formData[4].option = data;
              formData[4].value = selectedSubIntrests;
              setEditorFormData([...formData]);
            }
          }
          setLoading(false);
        }
      });
    } else {
      const formData = editorFormData;
      if (formData) {
        formData[4].value = [...value?.data];
        setEditorFormData([...formData]);
      }
    }
  };
  const onShowProfile = () => {
    let selectedIntrests: any;
    const editorData = loginUserDataObj;
    var data: any = {};
    // result = firstArray.filter(o => secondArray.some(({id,name}) => o.id === id && o.name === name));
    if (
      editorData?.user_interest_uuid &&
      editorData?.user_interest_uuid[0] === "ALL"
    ) {
      selectedIntrests = interestsList?.data;
    } else {
      selectedIntrests = interestsList?.data?.filter((o: any) =>
        editorData?.user_interest_uuid?.some((uuid: any) => o.uuid === uuid)
      );
    }
    onSelectIntrest({ data: selectedIntrests, isApi: true }, true, editorData);
    data.user_interest_uuid = selectedIntrests;
    const newData = editorFormData;
    newData[0].submitButtonName = "UPDATE EDITOR";
    newData.forEach((element: any) => {
      element.value =
        element?.name === "interest_keywords"
          ? editorData[element?.name]
            ? editorData[element?.name]?.toString()
            : ""
          : editorData[element?.name];
    });
    setEditorFormData([...newData]);
    setShowModalProfile(true);
  };
  return (
    <div className="container-fluid">
      <div className="row px-0">
        <div className="col-12 px-0">
          <nav className="navbar navbar-expand-md navbar-light custom-background-color">
            <div className="container-fluid">
              <a className="navbar-brand ps-1" style={{ height: '42px' }}>
                <img src={LOGO} alt="" className="" style={{
                  position: 'relative',
                  height: '100px',
                  top: '-30px'
                }} />

                {/* LeaderX */}
              </a>
              <button
                className="navbar-toggler "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon bg-white rounded"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-block d-md-none">
                  <aside>
                    <SidebarPage />
                  </aside>
                </ul>
                <div className="d-flex justify-content-center d-block d-md-none ">
                  <button className="btn" type="submit" onClick={onClickLogout}>
                    {/* btn-outline-light */}
                    Logout
                  </button>
                </div>
              </div>
              <div style={{ display: "contents" }}>
                <button
                  className="btn btn-outline-dark me-2 show_setting"
                  type="submit"
                >
                  <i className="bi bi-gear"></i>
                </button>
                <div className="hide_setting">
                  <div className="sidebar_settings">
                    <ul className="nav-list px-0">
                      <span className="text-decoration-none  text-dark sidemenu-list-item">
                        <li
                          className="py-2 pe-5 text-start ps-3 sidemenu-item"
                          onClick={() => onShowProfile()}
                        >
                          Profile
                        </li>
                      </span>
                      <span className="text-decoration-none  text-dark sidemenu-list-item">
                        <li
                          className="py-2 pe-5 text-start ps-3 sidemenu-item"
                          onClick={() => setShowModalChangePassword(true)}
                        >
                          Change Password
                        </li>
                      </span>
                    </ul>
                  </div>
                </div>
                {/* <button
                  className="btn btn-outline-dark me-2"
                  type="submit"
                  onClick={onRfresh}
                >
                  <i className="bi bi-arrow-clockwise" title="Refresh"></i>
                </button> */}
                <button
                  className="btn btn-outline-dark"
                  type="submit"
                  onClick={onClickLogout}
                >
                  {/* btn-outline-light */}
                  Logout
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 d-none d-md-block px-0" style={{ zIndex: 3 }}>
          <div className="text-center">
            <SidebarPage />
          </div>
        </div>
        <div className="col-md-10 content-scroll">
          <div className="d-flex flex-column justify-content-center">
            <DashboardRoutes />
          </div>
        </div>
      </div>
      {showlogoutModal && (
        <CustomModel
          ismodalShow={showlogoutModal}
          modalStatus={getModalStatus}
          modalHeaderText={""}
          modalType={""}
          deleteStatus={""}
        />
      )}

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showModalProfile ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">Profile</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModalProfile(false)}
              ></button>
            </div>
            <div className="modal-body text-start">
              {editorFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={editorFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={false}
                  onChildEvent={(e) => onSubmitForm(e)}
                  onParentDropdownEvent={(e) => onSelectIntrest(e, false, "")}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showModalChangePassword ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">Change Password</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModalChangePassword(false)}
              ></button>
            </div>
            <div className="modal-body text-start">
              {changePasswordFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={changePasswordFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={false}
                  onChildEvent={(e) => onSubmitChangePasswordForm(e)}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row custom-background-color fixed-bottom">
        <div className="col-12 py-1">
          <div>Copyright © 2023 LeaderX.</div>
        </div>
      </div>
    </div>
  );
};

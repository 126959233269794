import { DataTableCol } from "../../../../components/data-table/types";

export const ActiveUsersEventsDataGridCols: DataTableCol[] = [
  {
    title: "Title",
    control: "title",
    sortable: true,
    canShowColumn: true,
    isHtml: true,
  },
  {
    title: "Message",
    control: "reminder_message",
    sortable: true,
    canShowColumn: true,
    isHtml: true,
  },
  {
    title: "Date",
    control: "event_date",
    sortable: true,
    canShowColumn: true,
    isDate: true
  },
  {
    title: "Feed Type",
    control: "is_feed_type",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Satus",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "",
    control: "reject_message",
    sortable: false,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isView:true,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,

  },
];

import { useEffect, useState } from "react";
import { WeekReportsPage } from "./week-reports";
import { UserPostsReportsPage } from "./user-posts-reports";
import { UserPostedPostsReportsPage } from "./user-posted-posts-reports";
import { MostPostedFeedsReportsPage } from "./most-posted-feeds-reports";
import { MostPostedFeedUserInfoReportsPage } from "./most-posted-feed-user-info-reports";

export const ReportsTablePage = () => {
  const [selectedUserDate, setSelectedUserDate] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<any>('user_reports');
  const [selectedUserPostedReports, setSelectedUserPostedReports] = useState<any>(false);
  const [selectedUserReportPageNumber, setSelectedUserReportPageNumber] = useState<any>(1);
  const [selectedMostPostedPageNumber, setSelectedMostPostedPageNumber] = useState<any>(1);

  useEffect(() => {
    setSelectedUserPostedReports(false);
  }, []);

  const onSelectTab = (tab: string) => {
    setSelectedUserReportPageNumber(1);
    setSelectedMostPostedPageNumber(1);
    setSelectedUserPostedReports(false);
    setSelectedTab(tab);
  }

  const onBackToUserPost = () => {
    setSelectedUserPostedReports(false);
  }

  const onSelectedUserData = (selectedUser: any) => {
    setSelectedUserDate(selectedUser);
    setSelectedUserPostedReports(true);
  }

  const onSelectPage = (event: any) => {
    setSelectedUserReportPageNumber(event)
  }

  const onSelectMostPostedPage = (event: any) => {
    setSelectedMostPostedPageNumber(event)
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-tabs my-3 ">
            <li className="nav-item">
              <span
                className={`nav-link text-decoration-none text-dark ${selectedTab === 'user_reports' ? 'active' : ''}`}
                onClick={() => onSelectTab('user_reports')}
              >
                User Posts Report
              </span>
            </li>
            <li className="nav-item">
              <span
                className={`nav-link text-decoration-none text-dark ${selectedTab === 'most_posted_feeds' ? 'active' : ''}`}
                onClick={() => onSelectTab('most_posted_feeds')}
              >
                Most Posted Feeds
              </span>
            </li>
            <li className="nav-item">
              <span
                className={`nav-link text-decoration-none text-dark ${selectedTab === 'week_reports' ? 'active' : ''}`}
                onClick={() => onSelectTab('week_reports')}
              >
                Week Report
              </span>
            </li>
          </ul>
        </div>
        {selectedTab === 'user_reports' && !selectedUserPostedReports && <div>
          <UserPostsReportsPage onSelectedUser={(e) => onSelectedUserData(e)} selectedPage={(e) => onSelectPage(e)} selectedPageNumber={selectedUserReportPageNumber} />
        </div>}
        {selectedTab === 'user_reports' && selectedUserPostedReports && <div>
          <UserPostedPostsReportsPage selectedUserData={selectedUserDate} onBackButton={() => onBackToUserPost()} />
        </div>}
        {selectedTab === 'most_posted_feeds' && !selectedUserPostedReports && <div>
          <MostPostedFeedsReportsPage onSelectedUser={(e) => onSelectedUserData(e)} selectedPage={(e) => onSelectMostPostedPage(e)} selectedPageNumber={selectedMostPostedPageNumber}/>
        </div>}
        {selectedTab === 'most_posted_feeds' && selectedUserPostedReports && <div>
          <MostPostedFeedUserInfoReportsPage selectedUserData={selectedUserDate} onBackButton={() => onBackToUserPost()} />
        </div>}
        {selectedTab === 'week_reports' && <div>
          <WeekReportsPage />
        </div>}
      </div>
    </div>
  );
};

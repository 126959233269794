import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../../components/breadcum";
import { DataTable } from "../../../../components/data-table";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../../components/form-builder/form";
import CustomModel from "../../../../components/modal/custom-model";
import { ConciergeUsersDataGridCols } from "./concierge-users-data-grid-cols";
import { CREATE_CONCIERGE_USER, GET_CONCIERGE_USERS, UPDATE_CONCIERGE_USER } from "../../../../services/concierge-users.service";

export const ConciergePendingUsersPage = () => {
  const [conciergeUsersData, setConciergeUsersData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedConciergeUser, setSelectedConciergeUser] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [conciergeUserFormData, setConciergeUserFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "name",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "ADD CONCIERGE USER",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "email",
      name: "email",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter email",
      error: "Please enter email",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Phone",
      name: "phone",
      value: "",
      required: true,
      regex: /^[6-9]\d{9}$/i,
      maxLength: "",
      placeholder: "Enter phone",
      error: "Please enter phone",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "status",
      option: [
        { label: "Pending", value: "PENDING" },
        { label: "Scheduled", value: "SCHEDULED" },
        // { label: "Completed", value: "COMPLETED" },
        // { label: "Subscribed", value: "SUBSCRIBED" },
      ],
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    }
  ]);
  const [searchForm, setSearchForm] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "name",
      value: "",
      required: false,
      regex: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "SEARCH",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "email",
      name: "email",
      value: "",
      required: false,
      regex: "",
      maxLength: "",
      placeholder: "Enter email",
      error: "Please enter email",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Phone",
      name: "phone",
      value: "",
      required: false,
      regex: /^[6-9]\d{9}$/i,
      maxLength: "",
      placeholder: "Enter phone",
      error: "Please enter phone",
    },
  ])

  const breadcumInfo = [
    {
      label: "CONCIERGE PENDING USER",
      value: "CONCIERGE PENDING USER",
      routerLink: "/dashboard/concierge-users",
      isActive: true,
    },
  ];

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Name",
        name: "name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        submitButtonName: "ADD CONCIERGE USER",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Email",
        name: "email",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter email",
        error: "Please enter email",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Phone",
        name: "phone",
        value: "",
        required: true,
        regex: /^[6-9]\d{9}$/i,
        maxLength: "",
        placeholder: "Enter phone",
        error: "Please enter phone",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "status",
        option: [
          { label: "Pending", value: "PENDING" },
          { label: "Scheduled", value: "SCHEDULED" },
          // { label: "Completed", value: "COMPLETED" },
          // { label: "Subscribed", value: "SUBSCRIBED" },
        ],
        value: '',
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      }
    ];
    setConciergeUserFormData(formDataFields);
    getConciergeUsersList(pageCount);
  }, []);

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const getConciergeUsersList = (pageCount: any) => {
    setLoading(true);
    pageCount.status = "PENDING";
    GET_CONCIERGE_USERS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setConciergeUsersData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const ShowPopup = () => {
    const newData = conciergeUserFormData;
    newData[0].submitButtonName = "ADD CONCIERGE USER";
    newData.forEach((element: any) => {
      element.value = "";
    });
    setConciergeUserFormData([...newData]);
    setIsEdit(false);
    setShowModal(true);
  };

  const onActions = (data: any) => {
    setSelectedConciergeUser(data?.data);
    if (data?.type === "edit") {

      setIsEdit(true);
      const newData = conciergeUserFormData;
      // newData.push({
      //   title: "dropdown",
      //   control: "",
      //   type: "text",
      //   label: "Status",
      //   name: "status",
      //   option: [
      //     { label: "Pending", value: "PENDING" },
      //     { label: "Scheduled", value: "SCHEDULED" },
      //     { label: "Completed", value: "COMPLETED" },
      //     { label: "Subscribed", value: "SUBSCRIBED" },
      //   ],
      //   value: '',
      //   regex: "",
      //   required: true,
      //   placeholder: "Please select status",
      //   error: "Please select status",
      // },)
      newData[0].submitButtonName = "UPDATE";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setConciergeUserFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.name} concierge users!`
      );
      setShowDeleteModal(true);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getConciergeUsersList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getConciergeUsersList(pageData);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    // formData.user_score = "30";
    if (isEdit) {
      formData.uuid = selectedConciergeUser.uuid;
      setLoading(true);
      UPDATE_CONCIERGE_USER(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getConciergeUsersList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      CREATE_CONCIERGE_USER(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getConciergeUsersList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onDeleteUser = () => {
    setLoading(true);
    GET_CONCIERGE_USERS(selectedConciergeUser?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getConciergeUsersList(pageCount);
          setLoading(false);
        }
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };


  const onSearchEvent = (event: any) => {
    const pageData = pageCount;
    if (event?.formData?.is_active) {
      pageData.is_active = event?.formData?.is_active;
    }
    getConciergeUsersList({ ...pageData, ...event?.formData });
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              {/* <Breadcum breadcumList={breadcumInfo} /> */}
            </div>
            <div className="mt-3 pe-1">
              {/* <button
                className="custom-bg-btn rounded border-0 text-white me-2"
                onClick={ShowPopup}
              >
                Create Concierge Users
              </button> */}
            </div>
          </div>
          <div className="row px-3 pb-3">
            <div className="col-12">
              <div>
                {searchForm && (
                  <DymamicForm
                    formControls={""}
                    userFormData={searchForm}
                    columns={4}
                    cancelRoute={""}
                    isEdit={false}
                    onChildEvent={(e) => onSearchEvent(e)}
                    isNotEmptyFormAfterSubmit={true}
                  // onChangeRadioButton={(e) => onChangeRadioButton(e)}
                  // customClass={"display-block text-center col-12"}
                  />
                )}
              </div>

            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">
            <DataTable
              tableData={conciergeUsersData}
              TableCols={ConciergeUsersDataGridCols}
              onActions={onActions}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
            {showDeleteModal && (
              <CustomModel
                modalTitle={"Delete"}
                ismodalShow={showDeleteModal}
                modalStatus={getModalStatus}
                modalHeaderText={deleteModalBody}
                modalType={"delete"}
                deleteStatus={onDeleteUser}
              />
            )}
            <div
              className="modal"
              style={{ display: showModal ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content modal-box-shadow">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? "Update Concierge User" : "Create Concierge User"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleModalClosed}
                    ></button>
                  </div>
                  <div className="modal-body text-start">
                    <DymamicForm
                      formControls={""}
                      userFormData={conciergeUserFormData}
                      columns={1}
                      cancelRoute={""}
                      isEdit={isEdit}
                      onChildEvent={(e) => onSubmitForm(e)}
                      customClass={"display-block text-center col-12"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from "../pages/login/login";
import { RegisterPage } from "../pages/login/register";
import { ForgotPasswordPage } from "../pages/login/forgot-password";
import { DashboardPage } from "../pages/Dashboard/dashboard";
import { UsersPage } from "../pages/Dashboard/users/users";
import { SocialServicesPage } from "../pages/Dashboard/social-sources/social-sources";
import { UserForm } from "../pages/Dashboard/users/form";
import { SubUserForm } from "../pages/Dashboard/interests/form";
import { TransactionsPage } from "../pages/Dashboard/news-sources/news-sources";
import { EditorPanelPage } from "../pages/Dashboard/editor-panel/editor-panel";
import { ActiveUsersList } from "../pages/Dashboard/users/active-users/active-users-list";
import { InterestsListPage } from "../pages/Dashboard/interests/interests/intrests-list";
import { SubInterestsPage } from "../pages/Dashboard/interests/sub-interests/sub-interests";
import { NewStoriesPage } from "../pages/Dashboard/editor-panel/new-stories/new-stories";
import { ApprovedStoriesPage } from "../pages/Dashboard/editor-panel/approved-stories/approved-stories";
import { RejectedStoriesPage } from "../pages/Dashboard/editor-panel/rejected-stories/rejected-stories";
import { EditorsPage } from "../pages/Dashboard/users/editors/editors";
import { RolesPage } from "../pages/Dashboard/roles/roles";
import { InActiveUsersList } from "../pages/Dashboard/users/pending-users/pending-users-list";
import { SpinTypePage } from "../pages/Dashboard/spin-type/spin-type";
import { IndividualFeedsPage } from "../pages/Dashboard/editor-panel/individual-feeds/individual-feeds";
import { HomePage } from "../pages/Dashboard/home/home";

interface Props { }

export const RoutesPage = (props: Props) => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>
          
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/register" element={<RegisterPage />}></Route>
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route path="/dashboard/*" element={<DashboardPage />}>

            <Route path="users" element={<UsersPage />}>
              <Route path="users/active" element={<ActiveUsersList />}></Route>
              <Route
                path="users/pending"
                element={<InActiveUsersList />}
              ></Route>
              <Route path="users/editors" element={<EditorsPage />}></Route>
            </Route>

            <Route path="users/form" element={<UserForm />}></Route>
            <Route path="users/form:id" element={<UsersPage />}></Route>
            <Route
              path="social-sources"
              element={<SocialServicesPage />}
            ></Route>
            {/* <Route path="interests" element={<IntrestsPage />}> */}
            <Route path="interests" element={<InterestsListPage />}></Route>
            <Route
              path="sub-interests/:id"
              element={<SubInterestsPage />}
            ></Route>
            {/* </Route> */}
            <Route
              path="interests/form"
              element={<SubUserForm {...props} />}
            ></Route>
            <Route path="news-sources" element={<TransactionsPage />}></Route>
            <Route path="editors-panel" element={<EditorPanelPage />}>
              <Route
                path="editors-panel/new-stories"
                element={<NewStoriesPage />}
              ></Route>
              <Route
                path="editors-panel/approved-stories"
                element={<ApprovedStoriesPage />}
              ></Route>
              <Route
                path="editors-panel/rejected-stories"
                element={<RejectedStoriesPage />}
              ></Route>
              <Route
                path="editors-panel/individual-feeds"
                element={<IndividualFeedsPage />}
              ></Route>
            </Route>
            <Route path="roles" element={<RolesPage />}></Route>
            <Route path="spin-types" element={<SpinTypePage />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

import API from "../utils/axios";

export const GET_USER_COUNT = (): Promise<any> => {
  return API.get(`/reports/users_count_report`); //
};

export const GET_LAST_WEEKS_REPORT = (): Promise<any> => {
  return API.get(`/reports/get_last_weeks_report`); // week report 3rd tab
};

export const GET_TOTAL_POSTS_PLATFORM = (data: any): Promise<any> => {
  return API.post(`/reports/get_total_posts_platform`, data); // pie chart
};

export const GET_POSTS_REPORT = (data: any): Promise<any> => {
  return API.post(`/reports/get_posts_report`, data); // bar chart
};

export const GET_TOTAL_FEEDS = (data: any): Promise<any> => {
  return API.post(`/reports/get_total_feeds`, data); // approved rejected
};

export const GET_USER_POST_REPORT = (data: any): Promise<any> => {
  return API.post(`/reports/get_user_post_report`, data); // user grid
};

export const GET_USER_POSTED_POSTS = (data: any): Promise<any> => {
  return API.post(`/reports/get_user_posted_posts`, data); //  user grid count on click
};

export const EXPORT_FOR_YOU_USER = (data: any): Promise<any> => {
  return API.post(`/users/export_for_you_user`, data);
};

export const GET_MOST_POSTED_FEEDS = (data: any): Promise<any> => {
  return API.post(`/reports/get_most_posted_feeds`, data); // user grid
};

export const GET_USERS_INFO_FOR_MOST_POSTED_FEED = (
  data: any
): Promise<any> => {
  return API.post(`/reports/get_users_info_for_most_posted_feed`, data); //  user grid count on click
};

export const GET_VIEWS_REACT_POSTS = (data: any): Promise<any> => {
  return API.post(`/reports/get_views_react_posts`, data); // week report 3rd tab
};

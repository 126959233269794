import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { DataTable } from "../../../components/data-table";
import { SpinTypeDataGridCols } from "./spin-type-data-grid-cols";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../components/form-builder/form";
import CustomModel from "../../../components/modal/custom-model";
import {
  CREATE_SPIN_TYPE,
  DELETE_SPIN_TYPE,
  GET_SPIN_TYPES,
  UPDATE_SPIN_TYPE,
} from "../../../services/spin-type.service";

export const SpinTypePage = () => {
  const [spinTypesData, setSpinTypesData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [spinTypeName, setSpinTypesName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSpinType, setSelectedSpinType] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [spinTypeFormData, setSpinTypeFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "spin_name",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "ADD SPIN TYPE",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
  ]);

  const breadcumInfo = [
    {
      label: "Spin Types",
      value: "Spin Types",
      routerLink: "/dashboard/spin-types",
      isActive: true,
    },
  ];

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Name",
        name: "spin_name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        submitButtonName: "ADD SPIN TYPE",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setSpinTypeFormData(formDataFields);
    getSpinTypesList(pageCount);
  }, []);

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const getSpinTypesList = (pageCount: any) => {
    setLoading(true);
    GET_SPIN_TYPES(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setSpinTypesData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const ShowPopup = () => {
    setShowModal(true);
  };

  const onActions = (data: any) => {
    setSelectedSpinType(data?.data);
    if (data?.type === "edit") {
      setIsEdit(true);
      const newData = spinTypeFormData;
      newData[0].submitButtonName = "UPDATE SPIN TYPE";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setSpinTypeFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.spin_name} spin type!`
      );
      setShowDeleteModal(true);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getSpinTypesList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getSpinTypesList(pageData);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    if (isEdit) {
      formData.uuid = selectedSpinType.uuid;
      setLoading(true);
      UPDATE_SPIN_TYPE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getSpinTypesList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      CREATE_SPIN_TYPE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getSpinTypesList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETE_SPIN_TYPE(selectedSpinType?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getSpinTypesList(pageCount);
          setLoading(false);
        }
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
            <div>
              <div className="mt-3 pe-1">
                <button
                  className="custom-bg-btn rounded border-0 text-white me-2"
                  onClick={ShowPopup}
                >
                  Create Spin Type
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">
            <DataTable
              tableData={spinTypesData}
              TableCols={SpinTypeDataGridCols}
              onActions={onActions}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
            {showDeleteModal && (
              <CustomModel
                modalTitle={"Delete"}
                ismodalShow={showDeleteModal}
                modalStatus={getModalStatus}
                modalHeaderText={deleteModalBody}
                modalType={"delete"}
                deleteStatus={onDeleteUser}
              />
            )}
            <div
              className="modal"
              style={{ display: showModal ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content modal-box-shadow">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? "Update Spin Type" : "Create Spin Type"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleModalClosed}
                    ></button>
                  </div>
                  <div className="modal-body text-start">
                    <DymamicForm
                      formControls={""}
                      userFormData={spinTypeFormData}
                      columns={1}
                      cancelRoute={""}
                      isEdit={isEdit}
                      onChildEvent={(e) => onSubmitForm(e)}
                      customClass={"display-block text-center col-12"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

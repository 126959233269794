import { Breadcum } from "../../../components/breadcum";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useState } from "react";
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import { GET_POSTS_REPORT, GET_TOTAL_FEEDS, GET_TOTAL_POSTS_PLATFORM, GET_USER_COUNT, GET_VIEWS_REACT_POSTS } from "../../../services/report.service";
import { toast } from "react-toastify";
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { ReportsTablePage } from "../../../components/reports-table/reports-table";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

export const HomePage = () => {
  const startDate = dayjs(moment().add(-30, 'days').format('MM-DD-YYYY'));
  const endDate = dayjs(moment().format('MM-DD-YYYY'));

  const [articleStartDate, setArticleStartDate] = useState<any>(startDate);
  const [articleEndDate, setArticleEndDate] = useState<any>(endDate);
  const [postsStartDate, setPostsStartDate] = useState<any>(startDate);
  const [postsEndDate, setPostsEndDate] = useState<any>(endDate);
  const [postedPlotFormStartDate, setPostedPlotFormStartDate] = useState<any>(startDate);
  const [postedPlotFormEndDate, setPostedPlotFormEndDate] = useState<any>(endDate);

  const [triangleStartDate, setTriangleStartDate] = useState<any>(startDate);
  const [triangleEndDate, setTriangleEndDate] = useState<any>(endDate);
  const [triangleDate, setTriangleDate] = useState<any>();


  const [userCounts, setUserCounts] = useState<any>();
  const [totalFeeds, setTotalFeeds] = useState<any>();
  const [totalFeedsData, setTotalFeedsData] = useState<any>();

  const [postsReports, setPostsReports] = useState<any>();

  const [totalPostsPlatForm, setTotalPostsPlatForm] = useState<any>();

  const breadcumInfo = [
    {
      label: "Home",
      value: "Home",
      routerLink: "/dashboard/home",
      isActive: true,
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    getUserCount();
    getTotalFeeds(articleStartDate, articleEndDate);
    getTotalPostsPlatform(postedPlotFormStartDate, postedPlotFormEndDate);
    getTriangleData(triangleStartDate, triangleEndDate)
    getPostsReport(postsStartDate, postsEndDate);
  }, []);

  const getUserCount = () => {
    // setLoading(true);
    GET_USER_COUNT().then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          setUserCounts(res?.data)
          // setLoading(false);
        }
      }
    });
  };
  const getTotalFeeds = (articleStartDate: any, articleEndDate: any) => {
    // setLoading(true);
    GET_TOTAL_FEEDS({
      "start_date": dayjs(articleStartDate).format('YYYY-MM-DD'),
      "end_date": dayjs(articleEndDate).format('YYYY-MM-DD')
    }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          setTotalFeedsData(res?.data)
          const feedsData = {
            labels: ['Approved', 'Pending', 'Rejected'],
            datasets: [
              {
                label: 'Feeds',
                data: [res?.data?.approved_feeds, res?.data?.pending_feeds, res?.data?.rejected_feeds,],
                backgroundColor: [
                  '#27c87a',
                  '#349fdd',
                  'red',
                ],
                borderColor: [
                  '#27c87a',
                  '#349fdd',
                  'red',
                ],
                borderWidth: 1,
              },
            ],
          };
          setTotalFeeds(feedsData)
          // setLoading(false);
        }
      }
    });
  };

  const getTotalPostsPlatform = (postedPlotFormStartDate: any, postedPlotFormEndDate: any) => {
    // setLoading(true);
    GET_TOTAL_POSTS_PLATFORM({
      "start_date": dayjs(postedPlotFormStartDate).format('YYYY-MM-DD'),
      "end_date": dayjs(postedPlotFormEndDate).format('YYYY-MM-DD')
    }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          const data: any = [];
          const labelData: any = [];
          const colors: any = [];

          res?.data?.platform_data.forEach((element: any) => {
            data.push(element?.count);
            labelData.push(element?.platform);
            colors.push(element?.color)
          });
          const postsPlatFormData = {
            labels: labelData,
            datasets: [
              {
                label: 'Platform',
                data: data,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
              },
            ],
          };
          setTotalPostsPlatForm(postsPlatFormData)
          // setLoading(false);
        }
      }
    });
  };

  const getPostsReport = (postsStartDate: any, postsEndDate: any) => {
    // setLoading(true);
    GET_POSTS_REPORT({
      "start_date": dayjs(postsStartDate).format('YYYY-MM-DD'),
      "end_date": dayjs(postsEndDate).format('YYYY-MM-DD')
    }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          const labels: any = [];
          const data: any = [];
          res?.data?.forEach((element: any) => {
            labels.push(element?.date);
            data.push(element?.count);
          });
          const postsReports = {
            labels,
            datasets: [
              {
                label: 'Posts',
                data: data,
                backgroundColor: '#27c87a',
              }
            ],
          };
          setPostsReports(postsReports)
          // setLoading(false);
        }
      }
    });
  };

  const onChangeArticlesDateTime = (date: any) => {
    if (date[0] && date[1]) {
      getTotalFeeds(dayjs(date[0]).format('MM-DD-YYYY'), dayjs(date[1]).format('MM-DD-YYYY'));
    }
  }

  const onChangePostDateTime = (date: any) => {
    if (date[0] && date[1]) {
      getPostsReport(dayjs(date[0]).format('MM-DD-YYYY'), dayjs(date[1]).format('MM-DD-YYYY'));
    }
  }

  const onChangePostedPlotformDateTime = (date: any) => {
    if (date[0] && date[1]) {
      getTotalPostsPlatform(dayjs(date[0]).format('MM-DD-YYYY'), dayjs(date[1]).format('MM-DD-YYYY'));
    }
  }
  const onChangeTriangleDateTime = (date: any) => {
    if (date[0] && date[1]) {
      getTriangleData(dayjs(date[0]).format('MM-DD-YYYY'), dayjs(date[1]).format('MM-DD-YYYY'));
    }
  }


  const getTriangleData = (triangleStartDate: any, triangleEndDate: any) => {
    GET_VIEWS_REACT_POSTS({
      "start_date": dayjs(triangleStartDate).format('YYYY-MM-DD'),
      "end_date": dayjs(triangleEndDate).format('YYYY-MM-DD')
    }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
      } else {
        if (res) {
          setTriangleDate(res?.data)
        }
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <Breadcum breadcumList={breadcumInfo} />
          </div>
          <div className="col-12 p-3">
            <div className="d-flex justify-content-between mb-1">
              <div style={{ width: '32%' }}>
                <div className="dashboard_box p-2 text-center" style={{ width: '100%' }}>
                  <h4>Users</h4>
                  <div className="d-flex justify-content-between">
                    <div className="text-center dashboard_box_child">
                      <h2>{userCounts?.total_users}</h2>
                      <p>Total</p>
                    </div>
                    <div className="vr"></div>
                    <div className="text-center dashboard_box_child">
                      <h2>{userCounts?.total_concierge_users}</h2>
                      <p>Concierge</p>
                    </div>
                  </div>
                </div>
                <div className="dashboard_box p-2 text-center" style={{ width: '100%', marginTop: '20%' }}>
                  <h4>New Registrations</h4>
                  <div className="d-flex justify-content-between">
                    <div className="text-center dashboard_box_child">
                      <h2>{userCounts?.user_registered_in_month}</h2>
                      <p>Month</p>
                    </div>
                    <div className="vr"></div>
                    <div className="text-center dashboard_box_child">
                      <h2>{userCounts?.user_registered_in_week}</h2>
                      <p>Week</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard_box p-2 text-center">
                <div className="d-flex justify-content-between">
                  <h4>Articles </h4>
                  <StyledEngineProvider injectFirst>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['SingleInputDateRangeField']}>
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          name="allowedRange"
                          onChange={(newValue) => onChangeArticlesDateTime(newValue)}
                          value={[articleStartDate, articleEndDate]}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </StyledEngineProvider>

                </div>
                <div style={{ height: '300px', margin: 'auto 10%' }} className="text-center">
                  {totalFeeds && <Doughnut data={totalFeeds} />}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-start">Total Articles: {totalFeedsData?.total_feeds}</div>
                  <div className="text-start">Approved Articles: {totalFeedsData?.approved_feeds}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-start">Pending Articles: {totalFeedsData?.pending_feeds}</div>
                  <div className="text-start">Reject Articles: {totalFeedsData?.rejected_feeds}</div>
                </div>
              </div>
              <div className="dashboard_box p-2 text-center">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <h4>Platform </h4>
                    <StyledEngineProvider injectFirst>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['SingleInputDateRangeField']}>
                          <DateRangePicker
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            onChange={(newValue) => onChangePostedPlotformDateTime(newValue)}
                            value={[postedPlotFormStartDate, postedPlotFormEndDate]}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </StyledEngineProvider>
                  </div>
                  <div style={{ height: '300px', margin: 'auto 10%' }} className="text-center">
                    {totalPostsPlatForm && <Pie data={totalPostsPlatForm} />}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="d-flex justify-content-center mb-3">
                <StyledEngineProvider injectFirst>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['SingleInputDateRangeField']}>
                      <DateRangePicker
                        slots={{ field: SingleInputDateRangeField }}
                        name="allowedRange"
                        onChange={(newValue) => onChangeTriangleDateTime(newValue)}
                        value={[triangleStartDate, triangleEndDate]}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </StyledEngineProvider>
                {/* <button className="me-3 custom_blue_btn">Sort by Total Users</button>
                <button className="custom_blue_btn">Sort by Individual Users</button> */}
              </div>
              <div className="triangle">
                <div style={{ padding: '25px 0px' }}>
                  Number of Stories Viewed
                  <br />
                  {triangleDate?.views}
                </div>
                <div style={{ padding: '25px 0px' }}>Number of React Hits
                  <br />
                  {triangleDate?.reacts}
                </div>
                <div className="platform_text">Number of Platform Selected
                  <br />
                  {triangleDate?.platform}
                </div>
                <div className="post_text">Number of <br /> posts/shares
                  <br />
                  {triangleDate?.posts}
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-2">
              <h4 className="text-start">Posts</h4>
              <div className="mb-2">
                <StyledEngineProvider injectFirst>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['SingleInputDateRangeField']}>
                      <DateRangePicker
                        slots={{ field: SingleInputDateRangeField }}
                        name="allowedRange"
                        onChange={(newValue) => onChangePostDateTime(newValue)}
                        value={[postsStartDate, postsEndDate]}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </StyledEngineProvider>
              </div>
              {postsReports && <Bar options={options} data={postsReports} />}
            </div>
            <hr />
            <div>
              <ReportsTablePage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  modalTitle?: any;
  ismodalShow: any;
  modalStatus: any;
  modalHeaderText: any;
  modalType: any;
  deleteStatus: any;
}

function CustomModel(props: Props) {
  const [show, setShow] = useState(props.ismodalShow);
  const navigate = useNavigate();

  const handleClose = () => {
    if (props.modalStatus) {
      props.modalStatus(false);
    }
  };

  const handleModalConfirmation = () => {
    setShow(false);
    if (props.modalType === "logout") {
      localStorage.clear();
      navigate("/login");
    } else if (props.modalType === "delete") {
      props.deleteStatus(true);
    }
  };

  return (
    <>
      {show && (
        <div
          className={`modal ${show ? "show" : "false"} ${
            show ? "modal-backdrop" : ""
          } ${show ? "modal-show " : "modal-hide"}`}
          tab-index="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content modal-bg">
              <div className="modal-header">
                <h5 className="modal-title">{props?.modalTitle}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <h5 className="modal-title mx-5">{props.modalHeaderText}</h5>
              </div>
              <div className="text-center pb-3">
                <button
                  type="button"
                  className="custom-bg-btn rounded border-0 px-4 text-white py-1 me-2"
                  onClick={handleModalConfirmation}
                >
                  Yes
                </button>
                {/* <button
                  type="button"
                  className="custom-cancel-btn text-dark rounded border-0 px-3 py-1"
                  onClick={handleClose}
                >
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomModel;

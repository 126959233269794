import API from "../utils/axios";

export const GETSUBINTERESTS = (data: any): Promise<any> => {
  return API.get(`/subInterests/fetch`, { params: data });
};

export const ADDSUBINTEREST = (data: any): Promise<any> => {
  return API.post(`/subInterests/create`, data);
};

export const UPDATESUBINTEREST = (data: any): Promise<any> => {
  return API.put(`/subInterests/update`, data);
};

export const DELETESUBINTEREST = (uuid: string): Promise<any> => {
  return API.delete(`/subInterests/delete/${uuid}`);
};

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_USERS_INFO_FOR_MOST_POSTED_FEED } from "../../services/report.service";
import { DataTable } from "../data-table";
import { MostPostedFeedUserInfoReportGridCols } from "./most-posted-feed-user-infio-report-grid-cols";

interface Props {
  selectedUserData: any;
  onBackButton: (data: any) => void;
}

export const MostPostedFeedUserInfoReportsPage = (props: Props) => {
  const [userPostedPostsData, setUserPostedPostsData] = useState<any>();
  const [selectedUserDate, setSelectedUserDate] = useState<any>();
  const [userPostedPostReportPageCount, setUserPostedPostReportPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  useEffect(() => {
    setSelectedUserDate(props.selectedUserData);
    const params = userPostedPostReportPageCount;
    params.feed_uuid = props.selectedUserData?.feed_uuid;
    getUserPostedPostReport(params);
  }, []);

  const getUserPostedPostReport = (params: any) => {
    GET_USERS_INFO_FOR_MOST_POSTED_FEED(params).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          setUserPostedPostsData(res?.data)
          // setLoading(false);
        }
      }
    });
  };

  const onUserPostedPosteReportPageChange = (data: any) => {
    const pageData = userPostedPostReportPageCount;
    pageData.limit = data;
    pageData.user_uuid = selectedUserDate.uuid;
    setUserPostedPostReportPageCount(pageData);
    getUserPostedPostReport(pageData);
  };

  const onUserPostedPosteReportSelectPageinator = (data: any) => {
    const pageData = userPostedPostReportPageCount;
    pageData.page = data;
    pageData.user_uuid = selectedUserDate.uuid;
    setUserPostedPostReportPageCount(pageData);
    getUserPostedPostReport(pageData);
  };

  const onBackToUserPost = () => {
    props.onBackButton(true);
  }

  return (
    <div className="row">
      <div className="text-start mb-2 d-flex">
        <i className="bi bi-arrow-left-short" style={{ fontSize: '20px', cursor: 'pointer', position: 'relative', top: '-4px' }} onClick={() => onBackToUserPost()}></i> <h5 className="mb-0">{selectedUserDate?.feed_title}</h5>
      </div>
      <div>
        <DataTable
          tableData={userPostedPostsData}
          TableCols={MostPostedFeedUserInfoReportGridCols}
          onPageChange={(e) => onUserPostedPosteReportPageChange(e)}
          pageCount={userPostedPostReportPageCount}
          onSelectPageChange={(e) => onUserPostedPosteReportSelectPageinator(e)}
        />
      </div>
    </div>
  );
};

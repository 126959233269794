import React from "react";
import { Route, Routes } from "react-router-dom";
import { InsightApprovedPage } from "../pages/Dashboard/editor-panel/insight-feeds/approved/insight-approved-stories";
import { InsightRejectedPage } from "../pages/Dashboard/editor-panel/insight-feeds/rejected/insight-rejected-stories";
import { InsightFeedsPage } from "../pages/Dashboard/editor-panel/insight-feeds/feeds/feeds-stories";

export const InsightRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<InsightFeedsPage />}></Route>
        <Route
          path="feeds"
          element={<InsightFeedsPage />}
        >
        </Route>
        <Route
          path="approved"
          element={<InsightApprovedPage />}
        ></Route>
        <Route
          path="rejected"
          element={<InsightRejectedPage />}
        ></Route>
      </Routes>
    </div >
  );
};

import { useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { NavLink, useLocation } from "react-router-dom";
import { NestedUsers } from "../../../Routes/nested-users";
import { SENDINVITATION } from "../../../services/common.service";
import { toast } from "react-toastify";

export const UsersPage = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const urlPath = location?.pathname.split("/");
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const breadcumInfo = [
    {
      label: "Users",
      value: "Users",
      routerLink: "/dashboard/users",
      isActive: true,
    },
  ];

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const onChangeEmail = (event: any) => {
    if (event.target.value) {
      setUserEmail(event.target.value);
    }
  };

  const onSendInvitation = () => {
    const data = { user_email: userEmail };
    setLoading(true)
    SENDINVITATION(data).then((res: any) => {
      if (res.code === 200) {
        toast.success(res?.message);
        handleModalClosed();
        setLoading(false)
      } else {
        toast.error(res?.message);
        setLoading(false)
      }
    });
  };
  const showInvitationModal = () => {
    setUserEmail("");
    setShowModal(true);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-end  d-flex justify-content-between">
                <div>
                  <Breadcum breadcumList={breadcumInfo}></Breadcum>
                </div>
                <div className="mt-3 pe-1">
                  {/* <NavLink to='/dashboard/users/form'> */}
                  <button
                    className="custom-bg-btn rounded border-0 text-white me-2"
                    onClick={() => showInvitationModal()}
                  >
                    Send invitation
                  </button>
                  {/* </NavLink> */}
                </div>
              </div>
            </div>
            <div className="px-2 mx-1">
              <ul className="nav nav-tabs my-3 ">
                <li className="nav-item">
                  <NavLink
                    to={`/dashboard/users/active`}
                    className={`nav-link text-decoration-none text-dark ${(urlPath.length ===
                      3 &&
                      urlPath[2] === "users") &&
                      "active"} ${urlPath[3] === "events" && "active"}`}
                  >
                    Active {(
                      urlPath[2] === "users")}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/dashboard/users/pending`}
                    className="nav-link text-decoration-none text-dark"
                  >
                    Pending / In-Active
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/dashboard/users/editors`}
                    className="nav-link text-decoration-none text-dark"
                  >
                    Editors
                  </NavLink>
                </li>
              </ul>
              <div className="px-0">
                <NestedUsers />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" style={{ display: showModal ? "block" : "none" }}>
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">Send invitation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClosed}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email"
                  onChange={(e) => onChangeEmail(e)}
                  value={userEmail}
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn custom-bg-btn-outline-light px-4 py-1 me-2"
                data-bs-dismiss="modal"
                onClick={handleModalClosed}
              >
                Close
              </button>
              <button
                type="button"
                className="custom-bg-btn rounded border-0 text-white me-2"
                onClick={onSendInvitation}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

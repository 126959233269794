import { Breadcum } from "../../../../components/breadcum";
import { NavLink, useLocation } from "react-router-dom";
import { EditorPanelRoutes } from "../../../../Routes/editor-panel";
import { GETSOCIALFEEDS } from "../../../../services/editor-panel.service";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { SERPRoutes } from "../../../../Routes/SERP";
import { DymamicForm } from "../../../../components/form-builder/form";
import { InsightRoutes } from "../../../../Routes/insight";
import { GETINTERESTS } from "../../../../services/interests.service";
import { GETSUBINTERESTS } from "../../../../services/sub-interests.service";
import { CREATE_INSIGHT_FEED } from "../../../../services/insight-feed.service";

export const InsightPanelPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const urlPath = location?.pathname.split("/");
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [editorFormData, setEditorFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Title",
      name: "feed_title",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Enter title",
      error: "Please enter title",
      submitButtonName: "ADD",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Description",
      name: "feed_description",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter description",
      error: "Please enter description",
    },

    {
      title: "ckEditor",
      control: "",
      type: "text",
      label: "Body",
      name: "feed_body",
      value: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      column: 12
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Interest",
      name: "feed_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select interest",
      error: "Please select interest",
      isParendDropDown: true,
      isApiCall: true,
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Sub interest",
      name: "feed_subinterest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select sub interest",
      error: "Please select sub interest",
      isApiCall: false,
    },
    {
      title: "file",
      control: "",
      type: "file",
      label: "Image",
      name: "image_url",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Upload image",
      error: "Please upload image",
    },
    // {
    //   title: "range",
    //   control: "",
    //   type: "range",
    //   label: "Impact meter",
    //   name: "impact_meter",
    //   value: 0,
    //   min: "0",
    //   max: "10",
    //   required: true,
    //   maxLength: "",
    //   placeholder: "Select impact meter",
    //   error: "Please select impact meter",
    //   column: 3
    // },
    {
      title: "like",
      control: "",
      type: "range",
      label: "Like",
      name: "like",
      value: false,
      required: false,
      maxLength: "",
      placeholder: "Select like",
      error: "Please select like",
      column: 1
    },
    {
      title: "disLike",
      control: "",
      type: "range",
      label: "dislike",
      name: "dislike",
      value: false,
      required: false,
      maxLength: "",
      placeholder: "Select dislike",
      error: "Please select dislike",
      column: 1
    },
    {
      title: "doubleLike",
      control: "",
      type: "range",
      label: "double like",
      name: "double_like",
      value: false,
      required: false,
      maxLength: "",
      placeholder: "Select double like",
      error: "Please select double like",
      column: 2
    }
  ]);

  useEffect(() => {

    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Title",
        name: "feed_title",
        value: "",
        required: true,
        maxLength: "",
        placeholder: "Enter title",
        error: "Please enter title",
        submitButtonName: "ADD",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Description",
        name: "feed_description",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter description",
        error: "Please enter description",
      },

      {
        title: "ckEditor",
        control: "",
        type: "text",
        label: "Body",
        name: "feed_body",
        value: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        column: 12
      },
      {
        title: "multiDropdown",
        control: "",
        type: "text",
        label: "Interest",
        name: "feed_interest_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select interest",
        error: "Please select interest",
        isParendDropDown: true,
        isApiCall: true,
      },
      {
        title: "multiDropdown",
        control: "",
        type: "text",
        label: "Sub interest",
        name: "feed_subinterest_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select sub interest",
        error: "Please select sub interest",
        isApiCall: false,
      },
      {
        title: "file",
        control: "",
        type: "file",
        label: "Image",
        name: "image_url",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Upload image",
        error: "Please upload image",
      },
      // {
      //   title: "range",
      //   control: "",
      //   type: "range",
      //   label: "Impact meter",
      //   name: "impact_meter",
      //   value: 0,
      //   min: "0",
      //   max: "10",
      //   required: true,
      //   maxLength: "",
      //   placeholder: "Select impact meter",
      //   error: "Please select impact meter",
      // },
      {
        title: "like",
        control: "",
        type: "range",
        label: "like",
        name: "like",
        value: false,
        required: true,
        maxLength: "",
        placeholder: "Select like",
        error: "Please select like",
      },
      {
        title: "disLike",
        control: "",
        type: "range",
        label: "dislike",
        name: "dislike",
        value: false,
        required: true,
        maxLength: "",
        placeholder: "Select dislike",
        error: "Please select dislike",
      },
      {
        title: "doubleLike",
        control: "",
        type: "range",
        label: "double like",
        name: "double_like",
        value: false,
        required: true,
        maxLength: "",
        placeholder: "Select double like",
        error: "Please select double like",
        column: 2
      }
    ];
    setEditorFormData(formDataFields);
    getInterestsList();
  }, []);

  const getInterestsList = () => {
    GETINTERESTS({ limit: 0, page: 1 }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
      } else {
        if (res) {
          const data = res.data?.data;
          data.unshift({
            id: -1,
            interest_keywords: [],
            interest_name: "ALL",
            is_active: 1,
            label: "ALL",
            sub_interest_count: -1,
            uuid: "ALL",
            value: "ALL",
          });
          data.forEach((element: any) => {
            element.label = element?.interest_name;
            element.value = element?.uuid;
          });
          const formData = editorFormData;
          if (formData) {
            formData[3].option = data;
            setEditorFormData([...formData]);
          }
        }
      }
    });
  };

  const onSelectIntrest = (value: any, isEdit: any, selectedUser: any) => {
    if (value.isApi) {
      const multipleIntrests: any = [];
      if (value?.data) {
        value?.data?.forEach((element: any) => {
          multipleIntrests.push(element.uuid);
        });
      }
      GETSUBINTERESTS({
        multiple_interest_uuid: multipleIntrests.toString(),
        limit: 0,
        page: 1,
      }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
        } else {
          if (res) {
            const data = res.data?.data;
            var selectedSubIntrests;
            const formData = editorFormData;
            if (isEdit) {
              if (selectedUser?.feed_subinterest_uuid[0] === "ALL") {
                selectedSubIntrests = data;
              } else {
                selectedSubIntrests = data.filter((o: any) =>
                  selectedUser?.feed_subinterest_uuid.some(
                    (uuid: any) => o.uuid === uuid
                  )
                );
              }
            }
            data.unshift({
              id: -1,
              uuid: "ALL",
              interest_uuid: "ALL",
              sub_interest_name: "ALL",
              sub_interest_keywords: [],
              is_active: 1,
            });
            data.forEach((element: any) => {
              element.label = element?.sub_interest_name;
              element.value = element?.uuid;
            });
            if (formData) {
              formData[3].value = [...value?.data];
              formData[4].option = data;
              formData[4].value = selectedSubIntrests;
              setEditorFormData([...formData]);
            }
          }
        }
      });
    } else {
      const formData = editorFormData;
      if (formData) {
        formData[4].value = [...value?.data];
        setEditorFormData([...formData]);
      }
    }
  };

  const ShowPopup = () => {

    const newData = editorFormData;

    setIsEdit(true);
    newData.forEach((element: any) => {
      element.value = ''
    });
    setEditorFormData([...newData]);

    // const formDataFields: any = [
    //   {
    //     title: "input",
    //     control: "",
    //     type: "text",
    //     label: "Title",
    //     name: "feed_title",
    //     value: "",
    //     required: true,
    //     maxLength: "",
    //     placeholder: "Enter title",
    //     error: "Please enter title",
    //     submitButtonName: "ADD",
    //   },
    //   {
    //     title: "input",
    //     control: "",
    //     type: "text",
    //     label: "Description",
    //     name: "feed_description",
    //     value: "",
    //     required: true,
    //     regex: "",
    //     maxLength: "",
    //     placeholder: "Enter description",
    //     error: "Please enter description",
    //   },

    //   {
    //     title: "ckEditor",
    //     control: "",
    //     type: "text",
    //     label: "Body",
    //     name: "feed_body",
    //     value: "",
    //     maxLength: "",
    //     placeholder: "Enter name",
    //     error: "Please enter name",
    //     column: 12
    //   },
    //   {
    //     title: "multiDropdown",
    //     control: "",
    //     type: "text",
    //     label: "Interest",
    //     name: "feed_interest_uuid",
    //     option: [],
    //     value: "",
    //     regex: "",
    //     required: true,
    //     placeholder: "Please select interest",
    //     error: "Please select interest",
    //     isParendDropDown: true,
    //     isApiCall: true,
    //   },
    //   {
    //     title: "multiDropdown",
    //     control: "",
    //     type: "text",
    //     label: "Sub interest",
    //     name: "feed_subinterest_uuid",
    //     option: [],
    //     value: "",
    //     regex: "",
    //     required: true,
    //     placeholder: "Please select sub interest",
    //     error: "Please select sub interest",
    //     isApiCall: false,
    //   },
    //   {
    //     title: "file",
    //     control: "",
    //     type: "file",
    //     label: "Image",
    //     name: "image_url",
    //     value: "",
    //     required: true,
    //     regex: "",
    //     maxLength: "",
    //     placeholder: "Upload image",
    //     error: "Please upload image",
    //   },
    //   // {
    //   //   title: "range",
    //   //   control: "",
    //   //   type: "range",
    //   //   label: "Impact meter",
    //   //   name: "impact_meter",
    //   //   value: 0,
    //   //   min: "0",
    //   //   max: "10",
    //   //   required: true,
    //   //   maxLength: "",
    //   //   placeholder: "Select impact meter",
    //   //   error: "Please select impact meter",
    //   //   column: 3
    //   // },
    //   {
    //     title: "like",
    //     control: "",
    //     type: "range",
    //     label: "Like",
    //     name: "like",
    //     value: false,
    //     required: false,
    //     maxLength: "",
    //     placeholder: "Select like",
    //     error: "Please select like",
    //     column: 1
    //   },
    //   {
    //     title: "disLike",
    //     control: "",
    //     type: "range",
    //     label: "dislike",
    //     name: "dislike",
    //     value: false,
    //     required: false,
    //     maxLength: "",
    //     placeholder: "Select dislike",
    //     error: "Please select dislike",
    //     column: 1
    //   },
    //   {
    //     title: "doubleLike",
    //     control: "",
    //     type: "range",
    //     label: "double like",
    //     name: "double_like",
    //     value: false,
    //     required: false,
    //     maxLength: "",
    //     placeholder: "Select double like",
    //     error: "Please select double like",
    //     column: 2
    //   }
    // ];
    // setEditorFormData(formDataFields);
    setShowModal(true);
  };

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    const intrestList: any = [];
    const isAllIntrests = formData.feed_interest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllIntrests) {
      intrestList.push("ALL");
    } else {
      formData.feed_interest_uuid.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
    }
    const subIntrestList: any = [];
    const isAllSubIntrests = formData.feed_subinterest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllSubIntrests) {
      subIntrestList.push("ALL");
    } else {
      formData.feed_subinterest_uuid.forEach((element: any) => {
        subIntrestList.push(element?.uuid);
      });
    }
    formData.feed_interest_uuid = intrestList;
    formData.feed_subinterest_uuid = subIntrestList;
    formData.feed_status = {
      like: formData.like ? formData.like : false,
      dislike: formData.dislike ? formData.dislike : false,
      double_like: formData.double_like ? formData.double_like : false
    }
    delete formData.like;
    delete formData.dislike;
    delete formData.double_like;
    // formData.like = formData.like ? formData.like : false;
    // formData.dislike = formData.dislike ? formData.dislike : false;
    // formData.double_like = formData.double_like ? formData.double_like : false;

    setLoading(true);
    CREATE_INSIGHT_FEED(formData).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          handleModalClosed();
          setLoading(false);
        }
      }
    })
  }
  return (
    <div className="container-fluid">
      <div className="col-12">
        <div className="px-2 mx-1">
          <ul className="nav nav-tabs my-3 position-relative">
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/insight-stories/feeds`}
                className={`nav-link text-decoration-none text-dark ${urlPath.length ===
                  3 &&
                  urlPath[2] === "editors-panel" &&
                  "active"} `}
              >
                Feeds
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/insight-stories/approved`}
                className="nav-link text-decoration-none text-dark"
              >
                Approved
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/dashboard/editors-panel/insight-stories/rejected`}
                className="nav-link text-decoration-none text-dark"
              >
                Rejected
              </NavLink>
            </li>
            <li className="position-absolute end-0">
              <button
                className="custom-bg-btn rounded border-0 text-white me-2" onClick={ShowPopup}>
                ADD INSIGHT
              </button>
            </li>
          </ul>
          <div>
            {loading && (
              <div className="text-center p-5">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
            {!loading && <InsightRoutes />}
          </div>
        </div>
      </div>

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">
                {isEdit ? "Update INSIGHT" : "ADD INSIGHT"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClosed}
              ></button>
            </div>
            <div className="modal-body text-start">
              {editorFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={editorFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={isEdit}
                  onChildEvent={(e) => onSubmitForm(e)}
                  onParentDropdownEvent={(e) => onSelectIntrest(e, false, "")}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

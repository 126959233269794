import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GETSUBINTERESTS } from "../../services/sub-interests.service";
import { UPDATE_FEED_STATUS } from "../../services/editor-panel.service";
import ThumbsUp from "../../assets/images/thumbs-up.svg";
import ThumbsDown from "../../assets/images/thumbs-down.svg";
import ThumbsUpYellow from "../../assets/images/thumbs-up-yellow.svg";
import ThumbsDownYellow from "../../assets/images/thumbs-down-yellow.svg";
import LeaderxDefault from "../../assets/images/leaderx-default.png";
import Pagination from "@mui/material/Pagination";
import ImageSlider from "../image-slider";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import LOGO from "../../assets/images/LdrX Logo Horiz.png"
import { Tooltip } from "@mui/material";
import { Loader } from "../loader";
import { DymamicForm } from "../form-builder/form";
import { CREATE_INSIGHT_FEED } from "../../services/insight-feed.service";
import { GETUSERS } from "../../services/users.service";
import { ADD_USER_EVENTS, UPDATE_USER_EVENTS } from "../../services/users-events.service";

interface Props {
  storiesData: any;
  intrestList: any;
  onUpdateStory: () => void;
  onPageChange?: (data: any) => void;
  pageCount?: any;
  onSelectPageChange?: (data: any) => void;
}
export const EditorPanel = (props: Props) => {
  const [newStoriesList, setNewStoriesList] = useState<any>();
  const [interestsList, setInterestsList] = useState<any>([]);
  const [itemOffset, setItemOffset] = useState(props?.pageCount?.page);
  const [itemsPerPage, setItemsPerPage] = useState(props?.pageCount?.limit);
  const pageCount = Math?.ceil(props?.storiesData?.total_count / itemsPerPage);
  const itemPerPage = [2, 5, 10, 25, 50, 100, 500];
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [selectedStoriesList, setSelectedStoriesList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // const [activeUserData, setActiveUserData] = useState<any>([]);

  const [editorFormData, setEditorFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Title",
      name: "feed_title",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Enter title",
      error: "Please enter title",
      submitButtonName: "ADD",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Description",
      name: "feed_description",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter description",
      error: "Please enter description",
    },

    {
      title: "ckEditor",
      control: "",
      type: "text",
      label: "Body",
      name: "feed_body",
      value: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      column: 12
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Interest",
      name: "feed_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select interest",
      error: "Please select interest",
      isParendDropDown: true,
      isApiCall: true,
    },
    {
      title: "multiDropdown",
      control: "",
      type: "text",
      label: "Sub interest",
      name: "feed_subinterest_uuid",
      option: [],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select sub interest",
      error: "Please select sub interest",
      isApiCall: false,
    },
    {
      title: "file",
      control: "",
      type: "file",
      label: "Image",
      name: "image_url",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Upload image",
      error: "Please upload image",
    },
    // {
    //   title: "range",
    //   control: "",
    //   type: "range",
    //   label: "Impact meter",
    //   name: "impact_meter",
    //   value: 0,
    //   min: "0",
    //   max: "10",
    //   required: true,
    //   maxLength: "",
    //   placeholder: "Select impact meter",
    //   error: "Please select impact meter",
    //   column: 3
    // },
    {
      title: "like",
      control: "",
      type: "range",
      label: "Like",
      name: "like",
      value: false,
      required: false,
      maxLength: "",
      placeholder: "Select like",
      error: "Please select like",
      column: 1
    },
    {
      title: "disLike",
      control: "",
      type: "range",
      label: "dislike",
      name: "dislike",
      value: false,
      required: false,
      maxLength: "",
      placeholder: "Select dislike",
      error: "Please select dislike",
      column: 1
    },
    {
      title: "doubleLike",
      control: "",
      type: "range",
      label: "double like",
      name: "double_like",
      value: false,
      required: false,
      maxLength: "",
      placeholder: "Select double like",
      error: "Please select double like",
      column: 2
    }
  ]);

  const [assignEventFormData, setAssignEventFormData] = useState<any>([
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "User",
      name: "user_uuid",
      option: [],
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select user",
      error: "Please select user",
      submitButtonName: "Assign",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Title",
      name: "title",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Enter event title",
      error: "Please enter event title",
    },
    {
      title: "datetime",
      control: "",
      type: "datetime-local",
      label: "Date",
      name: "event_date",
      value: "",
      required: true,
      maxLength: "",
      placeholder: "Please select date",
      error: "Please select date",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "is_active",
      option: [
        { label: "Active", value: 1 },
        { label: "InActive", value: 0 },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    }
  ]);

  useEffect(() => {

    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Title",
        name: "feed_title",
        value: "",
        required: true,
        maxLength: "",
        placeholder: "Enter title",
        error: "Please enter title",
        submitButtonName: "ADD",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Description",
        name: "feed_description",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter description",
        error: "Please enter description",
      },

      {
        title: "ckEditor",
        control: "",
        type: "text",
        label: "Body",
        name: "feed_body",
        value: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        column: 12
      },
      {
        title: "multiDropdown",
        control: "",
        type: "text",
        label: "Interest",
        name: "feed_interest_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select interest",
        error: "Please select interest",
        isParendDropDown: true,
        isApiCall: true,
      },
      {
        title: "multiDropdown",
        control: "",
        type: "text",
        label: "Sub interest",
        name: "feed_subinterest_uuid",
        option: [],
        value: "",
        regex: "",
        required: true,
        placeholder: "Please select sub interest",
        error: "Please select sub interest",
        isApiCall: false,
      },
      {
        title: "file",
        control: "",
        type: "file",
        label: "Image",
        name: "image_url",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Upload image",
        error: "Please upload image",
      },
      // {
      //   title: "range",
      //   control: "",
      //   type: "range",
      //   label: "Impact meter",
      //   name: "impact_meter",
      //   value: 0,
      //   min: "0",
      //   max: "10",
      //   required: true,
      //   maxLength: "",
      //   placeholder: "Select impact meter",
      //   error: "Please select impact meter",
      //   column: 3
      // },
      {
        title: "like",
        control: "",
        type: "range",
        label: "like",
        name: "like",
        value: false,
        required: false,
        maxLength: "",
        placeholder: "Select like",
        error: "Please select like",
        column: 1
      },
      {
        title: "disLike",
        control: "",
        type: "range",
        label: "dislike",
        name: "dislike",
        value: false,
        required: false,
        maxLength: "",
        placeholder: "Select dislike",
        error: "Please select dislike",
        column: 1
      },
      {
        title: "doubleLike",
        control: "",
        type: "range",
        label: "double like",
        name: "double_like",
        value: false,
        required: false,
        maxLength: "",
        placeholder: "Select double like",
        error: "Please select double like",
        column: 2
      }
    ];
    setEditorFormData(formDataFields);
  }, []);

  useEffect(() => {
    // const storiesData =
    const intrestData = props?.intrestList;
    setInterestsList([...intrestData]);
    props?.storiesData?.data.forEach((element: any, index: number) => {
      if (element?.feed_interest_uuid) {
        onChangeIntrest(element?.feed_interest_uuid, index, element);
      }
      if (element?.feed_interest_uuid && element?.feed_interest_uuid[0] === "ALL") {
        element.selectedIntrests = intrestData;
      } else {
        element.selectedIntrests = intrestData?.filter((o: any) =>
          element?.feed_interest_uuid?.some((uuid: any) => o.uuid === uuid)
        );
      }
    });
    setNewStoriesList(props?.storiesData?.data);
  }, [props]);

  const onChangeIntrest = (event: any, index: number, storyData: any) => {
    if (event) {
      const storiesList = props?.storiesData?.data;
      storiesList[index].feed_interest_uuid = event;
      storiesList[index].showIntrestError = false;
      GETSUBINTERESTS({
        multiple_interest_uuid: event.toString(),
        limit: 0,
        page: 1,
      }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
        } else {
          if (res) {
            const data = res.data?.data;
            data.unshift({
              id: -1,
              uuid: "ALL",
              interest_uuid: "ALL",
              sub_interest_name: "ALL",
              sub_interest_keywords: [],
              is_active: 1,
            });
            storiesList[index].subInterestList = data;
            if (storyData?.feed_subinterest_uuid?.length > 0) {
              if (storyData?.feed_subinterest_uuid[0] === "ALL") {
                storyData.selectedSubIntrests = data;
              } else {
                storyData.selectedSubIntrests = data.filter((o: any) =>
                  storyData?.feed_subinterest_uuid.some(
                    (uuid: any) => o.uuid === uuid
                  )
                );
              }
            }
            setNewStoriesList([...storiesList]);
          }
        }
      });
    }
  };

  const onChangeSubIntrest = (event: any, index: number) => {
    if (event.target.value) {
      const storiesList = newStoriesList;
      storiesList[index].feed_subinterest_uuid = event.target.value;
      storiesList[index].showSubIntrestError = false;
      setNewStoriesList([...storiesList]);
    }
  };

  const onsubmit = (index: number) => {

    setShowLoader(true);
    const storiesList = newStoriesList;
    if (
      storiesList[index].selectedIntrests?.length > 0 &&
      storiesList[index].selectedSubIntrests?.length > 0
    ) {
      var subIntrestList = [];
      if (storiesList[index]?.selectedSubIntrests.length > 0) {
        const isAllSubIntrests = storiesList[index]?.selectedSubIntrests.find(
          (data: any) => data?.uuid === "ALL"
        );
        if (isAllSubIntrests) {
          subIntrestList.push("ALL");
        } else {
          storiesList[index]?.selectedSubIntrests.forEach((element: any) => {
            subIntrestList.push(element?.uuid);
          });
        }
      }
      var intrestList = [];

      if (storiesList[index]?.selectedIntrests.length > 0) {
        const isAllSubIntrests = storiesList[index]?.selectedIntrests.find(
          (data: any) => data?.uuid === "ALL"
        );
        if (isAllSubIntrests) {
          intrestList.push("ALL");
        } else {
          storiesList[index]?.selectedIntrests.forEach((element: any) => {
            intrestList.push(element?.uuid);
          });
        }
      }
      const data = {
        uuid: storiesList[index].uuid,
        feed_status: {
          like: storiesList[index].feed_status?.dislike
            ? false
            : storiesList[index].feed_status?.like,
          dislike:
            storiesList[index].feed_status?.like ||
              storiesList[index].feed_status?.double_like
              ? false
              : storiesList[index].feed_status?.dislike,
          double_like: storiesList[index].feed_status?.dislike
            ? false
            : storiesList[index].feed_status?.double_like,
        },
        feed_interest_uuid: intrestList, //storiesList[index].feed_interest_uuid,
        feed_subinterest_uuid: subIntrestList, //storiesList[index].feed_subinterest_uuid,
        impact_meter: Number(storiesList[index].impact_meter),
        is_star_feed: storiesList[index].is_star_feed,
        feed_title: storiesList[index].feed_title,
        is_feedbody_from_chatgpt: storiesList[index].is_feedbody_from_chatgpt,
        feed_scrapper_data: storiesList[index].feed_scrapper_data,
        feed_url: storiesList[index].feed_url,
        is_web_view: storiesList[index].is_web_view,
      };
      const newData = { feed_data: [data] }
      UPDATE_FEED_STATUS(newData).then((res: any) => {
        setShowLoader(false);
        if (res.status === "fail") {
          toast.error(res.error);
        } else {
          if (res) {
            props.onUpdateStory();
            toast.success(res.message);
          }
        }
      });
    } else {
      if (
        storiesList[index].selectedIntrests?.length <= 0 ||
        !storiesList[index].selectedIntrests
      ) {
        storiesList[index].showIntrestError = true;
        setNewStoriesList([...storiesList]);
      }
      if (
        storiesList[index].selectedSubIntrests?.length <= 0 ||
        !storiesList[index].selectedSubIntrests
      ) {
        storiesList[index].showSubIntrestError = true;
        setNewStoriesList([...storiesList]);
      }
    }
  };

  const onLike = (index: number) => {
    const storiesList = newStoriesList;
    const data = selectedStoriesList;
    if (!data.find((element: any) => element === storiesList[index].uuid)) {
      data.push(storiesList[index].uuid);
      setSelectedStoriesList([...data])
    }
    storiesList[index].feed_status.dislike = false;
    storiesList[index].feed_status.like = !storiesList[index].feed_status?.like;
    setNewStoriesList([...storiesList]);
  };

  const onDisLike = (index: number) => {
    const storiesList = newStoriesList;

    const data = selectedStoriesList;
    if (!data.find((element: any) => element === storiesList[index].uuid)) {
      data.push(storiesList[index].uuid);
      setSelectedStoriesList([...data])
    }

    storiesList[index].feed_status.like = false;
    storiesList[index].feed_status.double_like = false;
    storiesList[index].feed_status.dislike = !storiesList[index].feed_status
      ?.dislike;
    setNewStoriesList([...storiesList]);
  };

  const onDoubleLike = (index: number) => {
    const storiesList = newStoriesList;

    const data = selectedStoriesList;
    if (!data.find((element: any) => element === storiesList[index].uuid)) {
      data.push(storiesList[index].uuid);
      setSelectedStoriesList([...data])
    }

    storiesList[index].feed_status.dislike = false;
    storiesList[index].feed_status.like = true;
    storiesList[index].feed_status.double_like = !storiesList[index].feed_status
      ?.double_like;
    setNewStoriesList([...storiesList]);
  };
  const onchangeImpactMeter = (event: any, index: number) => {
    if (event.target.value) {
      const storiesList = newStoriesList;

      const data = selectedStoriesList;
      if (!data.find((element: any) => element === storiesList[index].uuid)) {
        data.push(storiesList[index].uuid);
        setSelectedStoriesList([...data])
      }

      storiesList[index].impact_meter = event.target.value;
      setNewStoriesList([...storiesList]);
    }
  };

  const onchangeStar = (event: any, index: number) => {
    const storiesList = newStoriesList;

    const data = selectedStoriesList;
    if (!data.find((element: any) => element === storiesList[index].uuid)) {
      data.push(storiesList[index].uuid);
      setSelectedStoriesList([...data])
    }

    storiesList[index].is_star_feed = event.target.checked;
    setNewStoriesList([...storiesList]);
  };
  const handlePageClick = (e: any, value: any) => {
    if (props.onSelectPageChange) {
      props.onSelectPageChange(value);
    }
    setItemOffset(value);
  };

  const onChangeItemPerPage = (value: any) => {
    if (props.onPageChange) {
      props.onPageChange(value);
    }
    setItemsPerPage(value);
  };
  const showViewModal = (data: any) => {
    setSelectedData("");
    const newData = data;
    setShowModal(true);
    setSelectedData({ ...newData });
  };
  const closeViewModal = () => {
    setSelectedData("");
    const id: any = document.getElementById("myVideo");
    if (id) {
      id.pause();
    }
    setShowModal(false);
  };
  const onCloseViewDetails = () => {
    setSelectedData("");
    const id: any = document.getElementById("myVideo");
    if (id) {
      id.pause();
    }
    setShowDetailsModal(false);
  };
  const onSelectInterest = (
    selectedDataList: any,
    selectedData: any,
    index: number,
    element: any
  ) => {
    if (selectedData.uuid === "ALL") {
      const storiesList = newStoriesList;
      storiesList[index].selectedIntrests = interestsList;
      storiesList[index].showIntrestError = false;
      setNewStoriesList([...storiesList]);
      const intrestList: any = [];
      storiesList[index]?.selectedIntrests.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
      onChangeIntrest(intrestList, index, element);
    } else {
      const storiesList = newStoriesList;
      storiesList[index].selectedIntrests = selectedDataList;
      storiesList[index].showIntrestError = false;
      setNewStoriesList([...storiesList]);
      const intrestList: any = [];
      storiesList[index]?.selectedIntrests.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
      onChangeIntrest(intrestList, index, element);
    }
  };
  const onRemoveInterest = (
    selectedDataList: any,
    selectedData: any,
    index: number,
    element: any
  ) => {
    if (selectedData.uuid === "ALL") {
      const storiesList = newStoriesList;
      storiesList[index].selectedIntrests = [];
      storiesList[index].showIntrestError = false;
      setNewStoriesList([...storiesList]);
      const intrestList: any = [];
      storiesList[index]?.selectedIntrests.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
      onChangeIntrest(intrestList, index, element);
    } else {
      const storiesList = newStoriesList;
      storiesList[index].selectedIntrests = selectedDataList;
      storiesList[index].showIntrestError = false;
      setNewStoriesList([...storiesList]);
      const intrestList: any = [];
      storiesList[index]?.selectedIntrests.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
      onChangeIntrest(intrestList, index, element);
    }
  };

  const onSelectSubInterest = (
    selectedDataList: any,
    selectedData: any,
    index: number,
    element: any
  ) => {
    if (selectedData.uuid === "ALL") {
      const storiesList = newStoriesList;
      storiesList[index].selectedSubIntrests = element?.subInterestList;
      storiesList[index].showSubIntrestError = false;
      setNewStoriesList([...storiesList]);
    } else {
      const storiesList = newStoriesList;
      storiesList[index].selectedSubIntrests = selectedDataList;
      storiesList[index].showSubIntrestError = false;
      setNewStoriesList([...storiesList]);
    }
  };
  const onRemoveSubInterest = (
    selectedDataList: any,
    selectedData: any,
    index: number,
    element: any
  ) => {
    if (selectedData.uuid === "ALL") {
      const storiesList = newStoriesList;
      storiesList[index].selectedSubIntrests = [];
      storiesList[index].showSubIntrestError = false;
      setNewStoriesList([...storiesList]);
    } else {
      const storiesList = newStoriesList;
      storiesList[index].selectedSubIntrests = selectedDataList;
      storiesList[index].showSubIntrestError = false;
      setNewStoriesList([...storiesList]);
    }
  };
  const onErrorImage = (error: any) => {
    error.target.src = LOGO;
  };

  const showData = (data: any) => {
    if (data) {
      var date = moment.utc(data).format("YYYY-MM-DD HH:mm:ss");
      var stillUtc = moment.utc(date).toDate();
      var local = moment(stillUtc)
        .local()
        .format("DD-MMM-YYYY hh:mm a");
      return local;
    } else {
      return "";
    }
  };
  const editDetails = (data: any) => {
    setSelectedData({ ...data });
    setShowEditModal(true);
    let selectedIntrests: any;
    data.like = data.feed_status.like;
    data.dislike = data.feed_status.dislike;
    data.double_like = data.feed_status.double_like;
    data.image_url = data?.feed_media?.images[0];
    if (data?.feed_interest_uuid[0] === "ALL") {
      selectedIntrests = interestsList;
    } else {
      selectedIntrests = interestsList.filter((o: any) =>
        data?.feed_interest_uuid.some((uuid: any) => o.uuid === uuid)
      );
    }
    onSelectIntrest(
      { data: selectedIntrests, isApi: true },
      true,
      data
    );
    data.feed_interest_uuid = selectedIntrests;
    const newData = editorFormData;
    newData[0].submitButtonName = "UPDATE EDITOR";
    newData.forEach((element: any) => {
      element.value =
        element.name === "interest_keywords"
          ? data[element.name]
            ? data[element.name]?.toString()
            : ""
          : data[element.name];
    });
    setEditorFormData([...newData]);
  }
  const viewDetails = (data: any) => {
    setSelectedData("");
    const newData = data;
    setShowDetailsModal(true);
    setSelectedData({ ...newData });
  };

  const onSelectedSubmitStories = () => {
    setShowLoader(true);
    var isValid = false;
    // const data = selectedStoriesList;
    // newStoriesList
    const result = newStoriesList.filter((o: any) => selectedStoriesList.some((data) => o.uuid === data));
    const finalData: any = []
    result.forEach((element: any) => {
      if (
        element.selectedIntrests?.length > 0 &&
        element.selectedSubIntrests?.length > 0
      ) {
        var subIntrestList = [];
        if (element?.selectedSubIntrests.length > 0) {
          const isAllSubIntrests = element?.selectedSubIntrests.find(
            (data: any) => data?.uuid === "ALL"
          );
          if (isAllSubIntrests) {
            subIntrestList.push("ALL");
          } else {
            element?.selectedSubIntrests.forEach((element: any) => {
              subIntrestList.push(element?.uuid);
            });
          }
        }
        var intrestList = [];

        if (element?.selectedIntrests.length > 0) {
          const isAllSubIntrests = element?.selectedIntrests.find(
            (data: any) => data?.uuid === "ALL"
          );
          if (isAllSubIntrests) {
            intrestList.push("ALL");
          } else {
            element?.selectedIntrests.forEach((element: any) => {
              intrestList.push(element?.uuid);
            });
          }
        }
        const data = {
          uuid: element.uuid,
          feed_status: {
            like: element.feed_status?.dislike
              ? false
              : element.feed_status?.like,
            dislike:
              element.feed_status?.like ||
                element.feed_status?.double_like
                ? false
                : element.feed_status?.dislike,
            double_like: element.feed_status?.dislike
              ? false
              : element.feed_status?.double_like,
          },
          feed_interest_uuid: intrestList,
          feed_subinterest_uuid: subIntrestList,
          impact_meter: Number(element.impact_meter),
          is_star_feed: element.is_star_feed,
          feed_title: element.feed_title,
          is_feedbody_from_chatgpt: element.is_feedbody_from_chatgpt,
          feed_scrapper_data: element.feed_scrapper_data,
          feed_url: element.feed_url,
          is_web_view: element.is_web_view,
        };
        // const newData = { feed_data: [data] }
        finalData.push(data)
        // UPDATE_FEED_STATUS(newData).then((res: any) => {
        //   if (res.status === "fail") {
        //     toast.error(res.error);
        //   } else {
        //     if (res) {
        //       props.onUpdateStory();
        //       toast.success(res.message);
        //     }
        //   }
        // });
      } else {
        setShowLoader(false);
        isValid = true;
        toast.error('Please select Interests / Sub Interests')
        // if (
        //   storiesList[index].selectedIntrests?.length <= 0 ||
        //   !storiesList[index].selectedIntrests
        // ) {
        //   storiesList[index].showIntrestError = true;
        //   setNewStoriesList([...storiesList]);
        // }
        // if (
        //   storiesList[index].selectedSubIntrests?.length <= 0 ||
        //   !storiesList[index].selectedSubIntrests
        // ) {
        //   storiesList[index].showSubIntrestError = true;
        //   setNewStoriesList([...storiesList]);
        // }
      }
    });
    if (!isValid) {
      const newData = { feed_data: finalData };
      UPDATE_FEED_STATUS(newData).then((res: any) => {
        setShowLoader(false);
        if (res.status === "fail") {
          toast.error(res.error);
        } else {
          if (res) {
            props.onUpdateStory();
            toast.success(res.message);
            setSelectedStoriesList([]);
          }
        }
      });
    }
  }

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    const intrestList: any = [];
    const isAllIntrests = formData.feed_interest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllIntrests) {
      intrestList.push("ALL");
    } else {
      formData.feed_interest_uuid.forEach((element: any) => {
        intrestList.push(element?.uuid);
      });
    }
    const subIntrestList: any = [];
    const isAllSubIntrests = formData.feed_subinterest_uuid.find(
      (data: any) => data?.uuid === "ALL"
    );
    if (isAllSubIntrests) {
      subIntrestList.push("ALL");
    } else {
      formData.feed_subinterest_uuid.forEach((element: any) => {
        subIntrestList.push(element?.uuid);
      });
    }
    formData.feed_interest_uuid = intrestList;
    formData.feed_subinterest_uuid = subIntrestList;
    formData.uuid = selectedData.uuid;
    ;

    formData.feed_status = {
      like: formData.like ? formData.like : false,
      dislike: formData.dislike ? formData.dislike : false,
      double_like: formData.double_like ? formData.double_like : false
    }
    delete formData.like;
    delete formData.dislike;
    delete formData.double_like;
    setLoading(true);
    CREATE_INSIGHT_FEED(formData).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setShowEditModal(false);
          setLoading(false);
        }
      }
    })
  }

  const onSelectIntrest = (value: any, isEdit: any, selectedUser: any) => {
    if (value.isApi) {
      const multipleIntrests: any = [];
      if (value?.data) {
        value?.data?.forEach((element: any) => {
          multipleIntrests.push(element.uuid);
        });
      }
      GETSUBINTERESTS({
        multiple_interest_uuid: multipleIntrests.toString(),
        limit: 0,
        page: 1,
      }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
        } else {
          if (res) {
            const data = res.data?.data;
            var selectedSubIntrests;
            const formData = editorFormData;
            if (isEdit) {
              if (selectedUser?.feed_subinterest_uuid[0] === "ALL") {
                selectedSubIntrests = data;
              } else {
                selectedSubIntrests = data.filter((o: any) =>
                  selectedUser?.feed_subinterest_uuid.some(
                    (uuid: any) => o.uuid === uuid
                  )
                );
              }
            }
            data.unshift({
              id: -1,
              uuid: "ALL",
              interest_uuid: "ALL",
              sub_interest_name: "ALL",
              sub_interest_keywords: [],
              is_active: 1,
            });
            data.forEach((element: any) => {
              element.label = element?.sub_interest_name;
              element.value = element?.uuid;
            });
            if (formData) {
              formData[3].value = [...value?.data];
              formData[4].option = data;
              formData[4].value = selectedSubIntrests;
              setEditorFormData([...formData]);
            }
          }
        }
      });
    } else {
      const formData = editorFormData;
      if (formData) {
        formData[4].value = [...value?.data];
        setEditorFormData([...formData]);
      }
    }
  };

  const assignEvent = (data: any) => {
    setSelectedData("");
    const newData = data;
    setSelectedData({ ...newData });
    setShowAssignModal(true);
    getActiveUserData();
    const assignForm = assignEventFormData;
    assignForm[1].value = data?.feed_title;
    setAssignEventFormData([...assignForm]);
  }

  const getActiveUserData = () => {
    setLoading(true);
    GETUSERS({
      is_active: "active", limit: 0
    }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          res.data?.data.forEach((element: any) => {
            element.label = `${element?.user_name} ${element?.user_email ? ` (${element?.user_email})` : ''}`;
            element.value = element?.uuid;
          });
          const assignForm = assignEventFormData;
          assignForm[0].option = res.data?.data;
          setAssignEventFormData([...assignForm]);
          // assignEventFormData, setAssignEventFormData

          // setActiveUserData(res.data);
          setLoading(false);
        }
      }
    });
  };

  const onSubmitAssignForm = (data: any) => {
    setLoading(true);
    const formData = data?.formData;
    formData.is_active = Number(formData.is_active);
    formData.is_web_view = true;
    formData.is_insight = false;
    formData.is_post = false;
    formData.post_to_platform = null;
    formData.reminder_message = selectedData?.feed_url;

    // if (isEdit) {
    //   formData.uuid = selectedUser.uuid;
    //   setLoading(true);
    //   UPDATE_USER_EVENTS(formData).then((res: any) => {
    //     if (res.status === "fail") {
    //       toast.error(res.error);
    //       setLoading(false);
    //     } else {
    //       if (res) {
    //         setShowModal(false);
    //         getActiveUserData();
    //         toast.success(res.message);
    //         setLoading(false);
    //       }
    //     }
    //   });
    // } else {
    ADD_USER_EVENTS(formData).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setShowAssignModal(false);
          // getActiveUserData();
          toast.success(res.message);
          setLoading(false);
        }
      }
    });
    // }
  }
  return (
    <div className="row mb-4">
      <Loader LoadingReducer={showLoader}></Loader>
      <div>
        <button disabled={!(selectedStoriesList.length > 0)} className={`custom-bg-btn rounded border-0 text-white me-2 selected_submit ${!(selectedStoriesList.length > 0) && 'custom-bg-btn-disabled'}`} onClick={() => onSelectedSubmitStories()}>Submit Selected Stories</button>
      </div>
      <div className="col-12 my-2 px-2 mx-1">
        {newStoriesList?.length > 0 &&
          newStoriesList.map((data: any, i: number) => (
            <React.Fragment key={i}>
              <div
                key={i}
                className="card border rounded p-3 my-2 position-relative"
              >
                <div>
                  {data?.is_individual_feed && <button
                    className="custom-bg-btn rounded border-0 text-white me-2 position-absolute" style={{ right: "92px" }}
                    onClick={() => assignEvent(data)}
                  >
                    Assign
                  </button>}
                  {data?.is_insight_news && <button
                    className="custom-bg-btn rounded border-0 text-white me-2 position-absolute" style={{ right: "92px" }}
                    onClick={() => editDetails(data)}
                  >
                    Edit
                  </button>}
                  <button
                    className="custom-bg-btn rounded border-0 text-white me-2 position-absolute end-0"
                    onClick={() => viewDetails(data)}
                  >
                    View
                  </button>
                </div>
                <div className="row">
                  <div className="col-3 post_img mb-4">
                    <p className="text-start mb-1">
                      Source: {data?.feed_source_platform} /{" "}
                      {data?.feed_owner_username}
                    </p>
                    <p className="text-start mb-2">
                      Created at: {showData(data?.feed_created_time)}
                    </p>
                    {data?.feed_media?.videos.length > 0 && (
                      <span onClick={() => showViewModal(data)}>
                        <video>
                          <source
                            src={data?.feed_media?.videos[0]}
                            type="video/mp4"
                          />
                        </video>
                        <i className="bi bi-play-circle post_video_play"></i>
                      </span>
                    )}{" "}
                    {data?.feed_media?.images.length > 0 && (
                      <>
                        <img
                          src={
                            data?.feed_media?.images.length > 0
                              ? data?.feed_media?.images[0]
                              : LOGO
                          }
                          alt="Leaderx"
                          onError={onErrorImage}
                        />
                        {data?.feed_media?.images.length > 1 && (
                          <button
                            className="custom-bg-btn rounded border-0 text-white me-2"
                            onClick={() => showViewModal(data)}
                          >
                            View more images
                          </button>
                        )}
                      </>
                    )}

                    {(data?.feed_media?.images.length === 0 && data?.total_feed?.total_feed.thumbnail) && (
                      <>
                        <img
                          src={
                            data?.total_feed?.total_feed.thumbnail
                              ? data?.total_feed?.total_feed.thumbnail
                              : LOGO
                          }
                          alt="Leaderx"
                          onError={onErrorImage}
                        />
                        {/* {data?.feed_media?.images.length > 1 && (
                          <button
                            className="custom-bg-btn rounded border-0 text-white me-2"
                            onClick={() => showViewModal(data)}
                          >
                            View more images
                          </button>
                        )} */}
                      </>
                    )}
                    {(data?.feed_media?.images.length === 0 && data?.feed_media?.videos.length === 0 && !data?.total_feed?.total_feed.thumbnail) && (
                      <>
                        <img
                          src={
                            LOGO
                          }
                          alt="Leaderx"
                          onError={onErrorImage}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-9">
                    <div className="d-flex">
                      <div className="fw-bold fs-5 text-dark text-start feed_text">
                        {data.feed_title}
                      </div>
                    </div>
                    <div className="card-body px-0">
                      <div
                        className="text-start"
                        dangerouslySetInnerHTML={{
                          __html: data.feed_description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className={`row my-3 pt-3 ${data?.feed_media?.images.length > 1 &&
                    "pt-5 mt-5"} mx-1`}
                >
                  <div className="col-md-3 col-lg-3 px-1 my-1 my-md-0">
                    <label className="form-label d-flex justify-content-start">
                      Interests
                    </label>
                    <Multiselect
                      placeholder={data.placeholder}
                      options={interestsList}
                      selectedValues={data?.selectedIntrests}
                      onSelect={(selectedList, selectedItem) =>
                        onSelectInterest(selectedList, selectedItem, i, data)
                      }
                      onRemove={(selectedList, selectedItem) =>
                        onRemoveInterest(selectedList, selectedItem, i, data)
                      }
                      isObject={true}
                      avoidHighlightFirstOption={true}
                      showCheckbox={true}
                      hidePlaceholder={true}
                      displayValue="interest_name"
                      style={{
                        chips: { background: "#074795" },
                        searchBox: {
                          height: "38px",
                          overflow: "hidden",
                          padding: "5px 10px 0px 10px",
                        },
                      }}
                    />
                    {data?.showIntrestError && (
                      <div className="text-danger text-start">
                        Please select interest
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 col-lg-3 px-1 my-1 my-md-0">
                    <label className="form-label d-flex justify-content-start">
                      Sub Interests
                    </label>
                    <Multiselect
                      placeholder={data.placeholder}
                      options={data?.subInterestList}
                      selectedValues={data?.selectedSubIntrests}
                      onSelect={(selectedList, selectedItem) =>
                        onSelectSubInterest(selectedList, selectedItem, i, data)
                      }
                      onRemove={(selectedList, selectedItem) =>
                        onRemoveSubInterest(selectedList, selectedItem, i, data)
                      }
                      isObject={true}
                      avoidHighlightFirstOption={true}
                      showCheckbox={true}
                      hidePlaceholder={true}
                      displayValue="sub_interest_name"
                      style={{
                        chips: { background: "#074795" },
                        searchBox: {
                          height: "38px",
                          overflow: "hidden",
                          padding: "5px 10px 0px 10px",
                        },
                      }}
                    />
                    {data?.showSubIntrestError}
                    {data?.showSubIntrestError && (
                      <div className="text-danger text-start">
                        Please select sub interest
                      </div>
                    )}
                  </div>

                  {data?.selectedIntrests?.length > 0 && data?.selectedSubIntrests?.length > 0 ? <>
                    {!data?.is_insight_news && <div className="col-md-2 col-lg-2 px-1 my-1 my-md-0">
                      <label className="form-label d-flex justify-content-start">
                        Impact meter: {data?.impact_meter}
                      </label>
                      <input
                        type="range"
                        id="impact_meter"
                        name="impact_meter"
                        min="0"
                        max="10"
                        className="mt_12 slider"
                        value={data?.impact_meter}
                        onChange={(e) => onchangeImpactMeter(e, i)}
                      />
                    </div>}
                    <div className="col-6 col-md-3 col-lg-2 px-0 px-lg-0">
                      <div className="d-flex justify-content-end">
                        {!data?.is_insight_news && <div className="pe-2">
                          <label className="form-label d-flex justify-content-start">
                            Star
                          </label>
                          <input
                            type="checkbox"
                            id="is_star_feed"
                            name="is_star_feed "
                            className="mt_12"
                            checked={data?.is_star_feed}
                            onChange={(e) => onchangeStar(e, i)}
                          />
                        </div>}
                        <div className="mx-1 mx-sm-3 mx-md-1 mx-lg-2 mt-4 pt-2 px-0 px-lg-0">
                          {data?.feed_status?.like ? (
                            <img
                              src={ThumbsUpYellow}
                              className={`mb-1 pb-1 cursor-pointer`}
                              onClick={() => onLike(i)}
                            />
                          ) : (
                            <img
                              src={ThumbsUp}
                              className={`mb-1 pb-1 cursor-pointer`}
                              onClick={() => onLike(i)}
                            />
                          )}
                        </div>
                        <div className="mx-1 mx-sm-3 mx-md-1 mx-lg-2 mt-4 pt-2 px-0 px-lg-0">
                          {data?.feed_status?.dislike ? (
                            <img
                              src={ThumbsDownYellow}
                              className={`pb-1 cursor-pointer`}
                              onClick={() => onDisLike(i)}
                            />
                          ) : (
                            <img
                              src={ThumbsDown}
                              className={`pb-1 cursor-pointer`}
                              onClick={() => onDisLike(i)}
                            />
                          )}
                        </div>
                        <div className="mx-0 mx-sm-3 mx-md-1 mx-lg-0 mt-4 pt-2 px-0 px-lg-0">
                          <div>
                            {data?.feed_status?.double_like ? (
                              <img
                                src={ThumbsUpYellow}
                                className={`mb-1 pb-1 cursor-pointer`}
                                onClick={() => onDoubleLike(i)}
                              />
                            ) : (
                              <img
                                src={ThumbsUp}
                                className={`mb-1 pb-1 cursor-pointer`}
                                onClick={() => onDoubleLike(i)}
                              />
                            )}{" "}
                            <span className="mt-1 my-0">2</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </> :
                    <>
                      {/* <Tooltip
                        title={'Please select Interests and Sub Interests'}
                        placement="top"
                        arrow
                        className="custom-tooltip-position"
                      >
                        <> */}
                      <div className="col-md-2 col-lg-2 px-1 my-1 my-md-0">
                        <label className="form-label d-flex justify-content-start">
                          Impact meter: {data?.impact_meter}
                        </label>
                        <Tooltip
                          title={data?.selectedIntrests?.length > 0 ? 'Please select Sub Interests' : 'Please select Interests and Sub Interests'}
                          placement="top"
                          arrow
                          className="custom-tooltip-position"
                        >
                          <input
                            type="range"
                            id="impact_meter"
                            name="impact_meter"
                            min="0"
                            max="10"
                            className="mt_12 slider"
                            value={data?.impact_meter}
                            disabled
                          />
                        </Tooltip>
                      </div>
                      <div className="col-6 col-md-3 col-lg-2 px-0 px-lg-0">
                        <div className="d-flex justify-content-end">
                          <div className="pe-2">
                            <label className="form-label d-flex justify-content-start">
                              Star
                            </label>
                            <Tooltip
                              title={data?.selectedIntrests?.length > 0 ? 'Please select Sub Interests' : 'Please select Interests and Sub Interests'}
                              placement="top"
                              arrow
                              className="custom-tooltip-position"
                            >
                              <input
                                type="checkbox"
                                id="is_star_feed"
                                name="is_star_feed "
                                className="mt_12"
                                checked={data?.is_star_feed}
                                disabled
                              />
                            </Tooltip>
                          </div>
                          <Tooltip
                            title={data?.selectedIntrests?.length > 0 ? 'Please select Sub Interests' : 'Please select Interests and Sub Interests'}
                            placement="top"
                            arrow
                            className="custom-tooltip-position"
                          >
                            <div className="mx-1 mx-sm-3 mx-md-1 mx-lg-2 mt-4 pt-2 px-0 px-lg-0">
                              {data?.feed_status?.like ? (
                                <img
                                  src={ThumbsUpYellow}
                                  className={`mb-1 pb-1`}
                                />
                              ) : (
                                <img
                                  src={ThumbsUp}
                                  className={`mb-1 pb-1`}
                                />
                              )}
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={data?.selectedIntrests?.length > 0 ? 'Please select Sub Interests' : 'Please select Interests and Sub Interests'}
                            placement="top"
                            arrow
                            className="custom-tooltip-position"
                          >
                            <div className="mx-1 mx-sm-3 mx-md-1 mx-lg-2 mt-4 pt-2 px-0 px-lg-0">
                              {data?.feed_status?.dislike ? (
                                <img
                                  src={ThumbsDownYellow}
                                  className={`pb-1`}
                                />
                              ) : (
                                <img
                                  src={ThumbsDown}
                                  className={`pb-1`}
                                />
                              )}
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={data?.selectedIntrests?.length > 0 ? 'Please select Sub Interests' : 'Please select Interests and Sub Interests'}
                            placement="top"
                            arrow
                            className="custom-tooltip-position"
                          >
                            <div className="mx-0 mx-sm-3 mx-md-1 mx-lg-0 mt-4 pt-2 px-0 px-lg-0">
                              <div>
                                {data?.feed_status?.double_like ? (
                                  <img
                                    src={ThumbsUpYellow}
                                    className={`mb-1 pb-1`}
                                  />
                                ) : (
                                  <img
                                    src={ThumbsUp}
                                    className={`mb-1 pb-1`}
                                  />
                                )}{" "}
                                <span className="mt-1 my-0">2</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      {/* </>
                      </Tooltip> */}
                    </>}


                  <div className="col-6 col-md-3 col-lg-2 mt-4 pt-1">
                    <button
                      className="custom-bg-btn rounded border-0 text-white me-2"
                      onClick={() => onsubmit(i)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        {newStoriesList?.length === 0 && <div>No records found.</div>}
      </div>
      <div className="row">
        <div className="col-md-4 text-start">
          <span className="me-2"> Item per page</span>
          <select
            onChange={(e) => onChangeItemPerPage(e.target.value)}
            value={itemsPerPage}
          >
            {itemPerPage.map((page: number, index: number) => {
              return (
                <option key={index} value={page}>
                  {page}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-8 d-flex justify-content-end mb-4">
          {newStoriesList?.length > 0 && (
            <div>
              <Pagination
                count={pageCount}
                defaultPage={itemOffset}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                onChange={(e: any, value) => handlePageClick(e, value)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="modal bd-example-modal-lg"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">View</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => closeViewModal()}
              ></button>
            </div>
            <div className="modal-body text-start">
              {selectedData?.feed_media?.videos.length > 0 && (
                <Carousel showArrows={true} showThumbs={false}>
                  {selectedData?.feed_media?.videos?.map((video: any) => {
                    return (
                      <div className="vide_player">
                        <video controls autoPlay width={"100%"} id="myVideo">
                          <source src={video} type="video/mp4" />
                        </video>
                      </div>
                    );
                  })}
                </Carousel>
              )}{" "}
              <Carousel showArrows={true} showThumbs={false}>
                {selectedData?.feed_media?.images?.map((image: any) => {
                  return (
                    <div>
                      <img
                        src={image}
                        className="d-block w-100 carousel_image"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showDetailsModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">View</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onCloseViewDetails()}
              ></button>
            </div>
            <div className="modal-body text-start">
              <React.Fragment>
                <div className="card p-3 my-2 position-relative">
                  <div className="row">
                    <div className="col-3 post_img mb-4">
                      <p className="text-start mb-1">
                        Source: {selectedData?.feed_source_platform} /{" "}
                        {selectedData?.feed_owner_username}
                      </p>
                      <p className="text-start mb-2">
                        Created at: {showData(selectedData?.feed_created_time)}
                      </p>
                      <div className="details_view">
                        {selectedData?.feed_media?.videos.length > 0 && (
                          <Carousel showArrows={true} showThumbs={false}>
                            {selectedData?.feed_media?.videos?.map(
                              (video: any) => {
                                return (
                                  <div className="vide_player">
                                    <video
                                      controls
                                      autoPlay
                                      width={"100%"}
                                      id="myVideo"
                                    >
                                      <source src={video} type="video/mp4" />
                                    </video>
                                  </div>
                                );
                              }
                            )}
                          </Carousel>
                        )}{" "}
                        {selectedData?.feed_media?.images.length > 0 && (
                          <>
                            <Carousel showArrows={true} showThumbs={false}>
                              {selectedData?.feed_media?.images?.map(
                                (image: any) => {
                                  return (
                                    <div>
                                      <img
                                        src={image}
                                        className="d-block w-100 carousel_image"
                                        onError={onErrorImage}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </Carousel>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-9">
                      <div className="d-flex">
                        <div className="fw-bold fs-5 text-dark text-start feed_text">
                          {selectedData?.feed_title}
                        </div>
                      </div>
                      {selectedData?.feed_source_platform === "NEWS" ? (
                        <div className="card-body px-0">
                          <div
                            className="text-start source_data"
                            dangerouslySetInnerHTML={{
                              __html: selectedData?.feed_body,
                            }}
                          ></div>
                        </div>
                      ) : (
                        <div className="card-body px-0">
                          <div
                            className="text-start source_data"
                            dangerouslySetInnerHTML={{
                              __html: selectedData?.feed_description,
                            }}
                          ></div>
                        </div>
                      )}{" "}
                      <a href={selectedData?.feed_url} target="_blanck">
                        {selectedData?.feed_url}
                      </a>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showEditModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">
                Update INSIGHT
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowEditModal(false)}
              ></button>
            </div>
            <div className="modal-body text-start">
              {editorFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={editorFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={true}
                  onChildEvent={(e) => onSubmitForm(e)}
                  onParentDropdownEvent={(e) => onSelectIntrest(e, false, "")}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal bd-example-modal-lg"
        style={{ display: showAssignModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content modal-box-shadow">
            <div className="modal-header">
              <h5 className="modal-title">
                Assign Event
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowAssignModal(false)}
              ></button>
            </div>
            <div className="modal-body text-start">
              {editorFormData && (
                <DymamicForm
                  formControls={""}
                  userFormData={assignEventFormData}
                  columns={2}
                  cancelRoute={""}
                  isEdit={true}
                  onChildEvent={(e) => onSubmitAssignForm(e)}
                  // onChangeRadioButton={(e) => onChangeRadioButton(e)}
                  customClass={"display-block text-center col-12"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

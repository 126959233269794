import API from "../utils/axios";

export const GET_USER_EVENTS = (data: any): Promise<any> => {
  return API.get(`/user_calendar/fetch`, { params: data });
};

export const ADD_USER_EVENTS = (data: any): Promise<any> => {
  return API.post(`/user_calendar/create`, data);
};

export const UPDATE_USER_EVENTS = (data: any): Promise<any> => {
  return API.put(`/user_calendar/update`, data);
};

export const DELETE_USER_EVENTS = (uuid: string): Promise<any> => {
  return API.delete(`/user_calendar/delete/${uuid}`);
};

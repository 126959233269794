import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConciergeScheduledUsersPage } from "../pages/Dashboard/concierge-users/scheduled-users/concierge-users";
import { ConciergeCompletedUsersPage } from "../pages/Dashboard/concierge-users/completed-users/concierge-users";
import { ConciergePendingUsersPage } from "../pages/Dashboard/concierge-users/pending-users/concierge-users";
import { ConciergePaymentUsersPage } from "../pages/Dashboard/concierge-users/payment-users/concierge-users";

export const ConciergeUsers = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ConciergePendingUsersPage />}></Route>
        <Route path="/scheduled" element={<ConciergeScheduledUsersPage />}></Route>
        <Route path="/completed" element={<ConciergeCompletedUsersPage />}></Route>
        <Route path="/pending" element={<ConciergePendingUsersPage />}></Route>
        <Route path="/subscribed" element={<ConciergePaymentUsersPage />}></Route>
      </Routes>
    </div>
  );
};

import API from "../utils/axios";

export const GET_WAITLIST_USERS = (data: any): Promise<any> => {
  return API.get(`/waitlist_users/fetch`, { params: data });
};

export const CREATE_WAITLIST_USER = (data: any): Promise<any> => {
  return API.post(`/waitlist_users/create`, data);
};

export const UPDATE_WAITLIST_USER = (data: any): Promise<any> => {
  return API.put(`/waitlist_users/update`, data);
};

export const DELETE_WAITLIST_USER = (uuid: string): Promise<any> => {
  return API.delete(`/waitlist_users/delete/${uuid}`);
};

import React, { useEffect, useState } from "react";
import { DataTable } from "../../../../components/data-table";
import { InterestsDataGridCols } from "./intrests-data-grid-cols";
import {
  ADDINTEREST,
  DELETEINTERESTS,
  GETINTERESTS,
  UPDATEINTERESTS,
} from "../../../../services/interests.service";
import { DynamicFormControls } from "../../../../components/form-builder/form/types";
import { DymamicForm } from "../../../../components/form-builder/form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Breadcum } from "../../../../components/breadcum";
import { Search } from "../../../../components/search/search";

export const SubUserFormControls: DynamicFormControls[] = [
  {
    title: "",
    control: "",
    type: "text",
    label: "User name",
    name: "user_name",
    value: "",
    required: "1",
    placeholder: "Enter user name ",
    error: "",
  },
];

export const InterestsListPage = () => {
  const [interestsList, setInterestsList] = useState<any>();
  const [userFormData, setUserFormData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const breadcumInfo = [
    {
      label: "Interests",
      value: "Interests",
      routerLink: "/dashboard/interests",
      isActive: true,
    },
  ];
  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Interest Name",
        name: "interest_name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter interest name",
        error: "Please enter interest name",
        submitButtonName: "ADD INTEREST",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Keywords",
        name: "interest_keywords",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter keywords",
        error: "Please enter valid keywords",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setUserFormData(formDataFields);
    getInterestsList();
  }, []);
  const getInterestsList = () => {
    setLoading(true);
    GETINTERESTS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setInterestsList(res.data);
          setLoading(false);
        }
      }
    });
  };

  const onSubmitForm = (data: any) => {
    const formData = data.formData;
    formData.interest_keywords = [data.formData.interest_keywords];
    if (data.type === "create") {
      setLoading(true);
      ADDINTEREST(formData).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          getInterestsList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    } else {
      formData.uuid = selectedData?.uuid;
      setLoading(true);
      UPDATEINTERESTS(formData).then((res: any) => {
        if (res.code === 200) {
          setIsEdit(false);
          const newData = userFormData;
          newData[0].submitButtonName = "ADD INTEREST";
          setUserFormData([...newData]);
          toast.success(res?.message);
          getInterestsList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    }
  };
  const onActions = (data: any) => {
    setSelectedData(data?.data);
    if (data.type === "edit") {
      setIsEdit(true);
      const newData = userFormData;
      newData[0].submitButtonName = "UPDATE INTEREST";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setUserFormData([...newData]);
    } else if (data.type === "delete") {
      setLoading(true);
      DELETEINTERESTS(data.data.uuid).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          getInterestsList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    } else if (data.type === "link") {
      navigate(`/dashboard/sub-interests/${data?.data?.uuid}`);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getInterestsList();
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getInterestsList();
  };

  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getInterestsList();
  };
  return (
    <div className="containerfluid">
      <div className="row px-3 mx-1">
        <div className="col-12 px-0">
          <div className="d-flex justify-content-between">
            <Breadcum breadcumList={breadcumInfo}></Breadcum>
          </div>
        </div>
        <div className="col-12">
          {userFormData && (
            <DymamicForm
              formControls={SubUserFormControls}
              userFormData={userFormData}
              columns={4}
              cancelRoute={"/dashboard/sub-accounts"}
              isEdit={isEdit}
              onChildEvent={(e) => onSubmitForm(e)}
            />
          )}
        </div>
        <div className="col-12 mt-2">
          <Search onSearch={(e) => onSearch(e)} />
        </div>
      </div>
      <div className="row px-3 mx-1">
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12 px-0">
            <DataTable
              tableData={interestsList}
              TableCols={InterestsDataGridCols}
              onActions={(e) => onActions(e)}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

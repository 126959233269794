import { DataTableCol } from "../../../../components/data-table/types";

export const PendingUsersDataGridCols: DataTableCol[] = [
  {
    title: "Email",
    control: "user_email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Name",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Phone",
    control: "user_phone",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Status",
    control: "is_active",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: false,
    isResend: true,
    isDelete: false,
    canShowColumn: true,
  },
];

import { Routes, Route } from "react-router-dom";
import { UsersPage } from "../pages/Dashboard/users/users";
import { SocialServicesPage } from "../pages/Dashboard/social-sources/social-sources";
import { UserForm } from "../pages/Dashboard/users/form";
import { SubUserForm } from "../pages/Dashboard/interests/form";
import { TransactionsPage } from "../pages/Dashboard/news-sources/news-sources";
import { EditorPanelPage } from "../pages/Dashboard/editor-panel/editor-panel";
import { InActiveUsersList } from "../pages/Dashboard/users/pending-users/pending-users-list";
import { InterestsListPage } from "../pages/Dashboard/interests/interests/intrests-list";
import { SubInterestsPage } from "../pages/Dashboard/interests/sub-interests/sub-interests";
import { NewStoriesPage } from "../pages/Dashboard/editor-panel/new-stories/new-stories";
import { ApprovedStoriesPage } from "../pages/Dashboard/editor-panel/approved-stories/approved-stories";
import { RejectedStoriesPage } from "../pages/Dashboard/editor-panel/rejected-stories/rejected-stories";
import { EditorsPage } from "../pages/Dashboard/users/editors/editors";
import { RolesPage } from "../pages/Dashboard/roles/roles";
import { SpinTypePage } from "../pages/Dashboard/spin-type/spin-type";
import { EventsPage } from "../pages/Dashboard/events/events";
import { FetchSocialPostPage } from "../pages/Dashboard/fetch-social-posts/fetch-social-posts";
import { WaitlistUsersPage } from "../pages/Dashboard/waitlist-users/waitlist-users";
import { ProfessionPage } from "../pages/Dashboard/profession/profession";
import { FetchPostPage } from "../pages/Dashboard/fetch-posts/fetch-social-posts";
import { SubscriptionPage } from "../pages/Dashboard/subscription-list/subscription-list";
import { ConciergeUsersPage } from "../pages/Dashboard/concierge-users/concierge-users";
import { ConciergeScheduledUsersPage } from "../pages/Dashboard/concierge-users/scheduled-users/concierge-users";
import { ConciergeCompletedUsersPage } from "../pages/Dashboard/concierge-users/completed-users/concierge-users";
import { ConciergePendingUsersPage } from "../pages/Dashboard/concierge-users/pending-users/concierge-users";
import { ConciergePaymentUsersPage } from "../pages/Dashboard/concierge-users/payment-users/concierge-users";
import { HomePage } from "../pages/Dashboard/home/home";
import { ReportsPage } from "../pages/Dashboard/reports/reports";

export const DashboardRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<UsersPage />}></Route>
        <Route path="home" element={<HomePage />}></Route>
        <Route path="reports" element={<ReportsPage />}></Route>
        <Route path="/users" element={<UsersPage />}>
          <Route path="/users/active" element={<UsersPage />}></Route>
          <Route path="/users/pending" element={<InActiveUsersList />}></Route>
          <Route path="/users/editors" element={<EditorsPage />}></Route>
          <Route path="/users/events" element={<EditorsPage />}></Route>
        </Route>
        <Route path="/concierge" element={<ConciergeUsersPage />}>
          <Route path="/concierge/scheduled" element={<ConciergeScheduledUsersPage />}></Route>
          <Route path="/concierge/completed" element={<ConciergeCompletedUsersPage />}></Route>
          <Route path="/concierge/pending" element={<ConciergePendingUsersPage />}></Route>
          <Route path="/concierge/subscribed" element={<ConciergePaymentUsersPage />}></Route>
        </Route>
        <Route path="/users/form" element={<UserForm />}></Route>
        <Route path="/users/form:id" element={<UsersPage />}></Route>
        <Route path="/social-sources" element={<SocialServicesPage />}></Route>
        {/* <Route path="/interests/*" element={<IntrestsPage />}> */}
        <Route path="interests" element={<InterestsListPage />}></Route>
        <Route path="sub-interests/:id" element={<SubInterestsPage />}></Route>
        {/* </Route> */}
        <Route path="/interests/form" element={<SubUserForm />}></Route>
        <Route path="/news-sources" element={<TransactionsPage />}></Route>
        <Route path="/editors-panel/*" element={<EditorPanelPage />}>
          <Route path="new-stories" element={<NewStoriesPage />}></Route>
          <Route
            path="approved-stories"
            element={<ApprovedStoriesPage />}
          ></Route>
          <Route
            path="rejected-stories"
            element={<RejectedStoriesPage />}
          ></Route>
        </Route>
        <Route path="/roles" element={<RolesPage />}></Route>
        <Route path="/spin-types" element={<SpinTypePage />}></Route>
        <Route path="/events" element={<EventsPage />}></Route>
        <Route path="/waitlist-users" element={<WaitlistUsersPage />}></Route>
        <Route path="/concierge-users" element={<ConciergeUsersPage />}></Route>
        <Route path="/profession" element={<ProfessionPage />}></Route>
        <Route path="/fetch-social-posts" element={<FetchSocialPostPage />}></Route>
        <Route path="/fetch-posts" element={<FetchPostPage />}></Route>
        <Route path="/subscription-list" element={<SubscriptionPage />}></Route>

      </Routes>
    </div>
  );
};

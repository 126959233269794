import React, { useEffect, useState } from "react";
import { DataTable } from "../../../../components/data-table";
import { SubInterestsDataGridCols } from "./sub-interests-data-grid-cols";
import { Breadcum } from "../../../../components/breadcum";
import { toast } from "react-toastify";
import { GETINTERESTS } from "../../../../services/interests.service";
import { useParams } from "react-router-dom";
import {
  ADDSUBINTEREST,
  DELETESUBINTEREST,
  GETSUBINTERESTS,
  UPDATESUBINTEREST,
} from "../../../../services/sub-interests.service";
import { DymamicForm } from "../../../../components/form-builder/form";
import { Search } from "../../../../components/search/search";

export const SubInterestsPage = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState<any>();
  const [subInterestsData, setSubInterestsData] = useState<any>();
  const [userFormData, setUserFormData] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [breadcumInfo, setBreadcumInfo] = useState<any>([
    {
      label: "Interests",
      value: "Interests",
      routerLink: "/dashboard/interests",
      isActive: false,
    },
    {
      label: "Sub Interest",
      value: "Sub Interest",
      routerLink: "",
      isActive: false,
    },
  ]);
  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Sub Interest Name",
        name: "sub_interest_name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter sub interest name",
        error: "Please enter subinterest name",
        submitButtonName: "ADD SUB INTEREST",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Keywords",
        name: "sub_interest_keywords",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter keywords",
        error: "Please enter keywords",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "is_active",
        option: [
          { label: "Active", value: 1 },
          { label: "InActive", value: 0 },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
      {
        title: "input",
        control: "",
        type: "number",
        label: "Ranking",
        name: "ranking",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Please enter ranking",
        error: "Please enter ranking",
      },
    ];
    setUserFormData(formDataFields);
    getSelectedInterestsList();
    getSubIntrestList();
  }, []);
  const getSelectedInterestsList = () => {
    setLoading(true);
    GETINTERESTS({ uuid: params?.id }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setSelectedInterest(res.data?.data);
          const breadcrumb = breadcumInfo;
          if (breadcrumb.length >= 2) {
            breadcrumb.splice(2, 1);
          }

          breadcrumb.push({
            label: res?.data[0]?.interest_name,
            value: res?.data[0]?.interest_name,
            routerLink: "",
            isActive: true,
          });
          setBreadcumInfo([...breadcrumb]);
          setLoading(false);
        }
      }
    });
  };

  const getSubIntrestList = () => {
    setLoading(true);
    const data: any = pageCount;
    data.interest_uuid = params?.id;
    GETSUBINTERESTS(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setSubInterestsData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const onActions = (data: any) => {
    setSelectedData(data?.data);
    if (data.type === "edit") {
      setIsEdit(true);
      const newData = userFormData;
      newData[0].submitButtonName = "UPDATE SUB INTEREST";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name]
            ? data.data[element.name]
            : "";
      });
      setUserFormData([...newData]);
    } else if (data.type === "delete") {
      setLoading(true);
      DELETESUBINTEREST(data.data.uuid).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          getSubIntrestList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    }
  };

  const onSubmitForm = (data: any) => {
    const formData = data.formData;
    formData.interest_uuid = params?.id;
    formData.sub_interest_keywords = [data.formData.sub_interest_keywords];
    if (data.type === "create") {
      setLoading(true);
      ADDSUBINTEREST(formData).then((res: any) => {
        if (res.code === 200) {
          toast.success(res?.message);
          getSubIntrestList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    } else {
      formData.uuid = selectedData?.uuid;
      setLoading(true);
      UPDATESUBINTEREST(formData).then((res: any) => {
        if (res.code === 200) {
          setIsEdit(false);
          const newData = userFormData;
          newData[0].submitButtonName = "ADD INTEREST";
          setUserFormData([...newData]);
          toast.success(res?.message);
          getSubIntrestList();
          setLoading(false);
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      });
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getSubIntrestList();
  };
  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getSubIntrestList();
  };

  const onSearch = (value: string) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getSubIntrestList();
  };
  return (
    <div className="containerfluid">
      <div className="row px-3 mx-1">
        <div className="col-12 px-0">
          <div className="d-flex justify-content-between">
            <Breadcum breadcumList={breadcumInfo}></Breadcum>
          </div>
        </div>
        <div className="col-12">
          {userFormData && (
            <DymamicForm
              formControls={""}
              userFormData={userFormData}
              columns={6}
              cancelRoute={"/dashboard/sub-accounts"}
              isEdit={isEdit}
              onChildEvent={(e) => onSubmitForm(e)}
            />
          )}
          <div className="col-12 mt-2">
            <Search onSearch={(e) => onSearch(e)} />
          </div>
        </div>
        <div className="row">
          {loading && (
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          {!loading && (
            <div className="col-12 px-0 ">
              <DataTable
                tableData={subInterestsData}
                TableCols={SubInterestsDataGridCols}
                onActions={(e) => onActions(e)}
                onPageChange={(e) => onPageChange(e)}
                pageCount={pageCount}
                onSelectPageChange={(e) => onSelectPageinator(e)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { DataTableCol } from "../../../../components/data-table/types";

export const ActiveUsersDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "user_email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Phone Number",
    control: "user_phone",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Interest Score",
    control: "interest_core",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Balance Tokens",
    control: "balance_tokens",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Active Since",
    control: "active_since",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    // isEdit: true,
    isDelete: true,
    isEvent:true,
    canShowColumn: true,
  },
];

import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { DataTable } from "../../../components/data-table";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../components/form-builder/form";
import CustomModel from "../../../components/modal/custom-model";
import { FetchPostsDataGridCols } from "./subscription-list-data-grid-cols";
import { GETSOCIALTYPES } from "../../../services/lookup.service";
import {
  CREATE_SOCIAL_MEDIA_FETCH,
  DELETE_SOCIAL_MEDIA_FETCH,
  GET_SOCIAL_MEDIA_FETCH,
  UPDATE_SOCIAL_MEDIA_FETCH,
} from "../../../services/fetch-social-post.service";
import { GETSUBINTERESTS } from "../../../services/sub-interests.service";
import { GETINTERESTS } from "../../../services/interests.service";
import { GETNEWSTORIES } from "../../../services/editor-panel.service";
import { EditorPanel } from "../../../components/editor-panel/editor-panel";

export const SubscriptionPage = () => {
  const [fetchPostsData, setFetchPostsData] = useState<any>(
    {
      data: [
        {
          user_name: 'test',
          subscription_status: 'Active',
          started_on: '06-11-2023',
          ending_on: '06-11-2024',
          reactions: '5',
          daily_talking_points: ''
        },
        {
          user_name: 'test 1',
          subscription_status: 'Active',
          started_on: '06-11-2023',
          ending_on: '06-11-2024',
          reactions: '5',
          daily_talking_points: ''
        },
        {
          user_name: 'test 2',
          subscription_status: 'Active',
          started_on: '06-11-2023',
          ending_on: '06-11-2024',
          reactions: '5',
          daily_talking_points: ''
        },
        {
          user_name: 'test 3',
          subscription_status: 'Active',
          started_on: '06-11-2023',
          ending_on: '06-11-2024',
          reactions: '5',
          daily_talking_points: ''
        }
      ],
      total_count: 4
    }
  );
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFetchPost, setSelectedFetchPost] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [fetchPostFormData, setFetchPostFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "input",
      label: "URL",
      name: "social_platform",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter social platform",
      error: "Please enter social platform",
      submitButtonName: "ADD SOCIAL PLATFORM",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Social media post url",
      name: "social_media_post_url",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter social media post url",
      error: "Please enter social media post url",
    },
  ]);

  const breadcumInfo = [
    {
      label: "Subscription List",
      value: "Subscription List",
      routerLink: "/dashboard/subscription-list",
      isActive: true,
    },
  ];
  const [showDailyTalkingPoints, setShowDailyTalkingPoints] = useState(false);
  const [searchForm, setSearchForm] = useState<any>([
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Interests",
      name: "feed_interest_uuid",
      option: [],
      value: "",
      regex: "",
      required: false,
      placeholder: "Please select interests",
      error: "Please select interests",
      submitButtonName: "SEARCH",
      isParendDropDown: true,
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Sub Interests",
      name: "feed_subinterest_uuid",
      option: [],
      value: "",
      regex: "",
      required: false,
      placeholder: "Please select sub interests",
      error: "Please select sub interests",
    },
    // {
    //   title: "input",
    //   control: "",
    //   type: "checkbox",
    //   label: "Interests ",
    //   name: "is_interest_empty",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   column: 1,
    // },
    // {
    //   title: "input",
    //   control: "",
    //   type: "checkbox",
    //   label: "Sub Interests",
    //   name: "is_sub_interest_empty",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   column: 2,
    // },
    // {
    //   title: "input",
    //   control: "",
    //   type: "checkbox",
    //   label: "Double Thumbs Up",
    //   name: "status",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   column: 2,
    // },
    // {
    //   title: "input",
    //   control: "",
    //   type: "checkbox",
    //   label: "Star Feed",
    //   name: "is_star_feed",
    //   value: "",
    //   regex: "",
    //   required: false,
    //   column: 1,
    // },
  ]);
  const loginUserData: any = sessionStorage.getItem("loginUserData");
  const loginUserInfo = JSON.parse(loginUserData);
  const [approvedStoriesList, setApprovedStoriesList] = useState<any>();
  const [interestsList, setInterestsList] = useState<any>([]);


  useEffect(() => {
    const formDataFields: any = [
      {
        title: "dropdown",
        control: "",
        type: "dropdown",
        label: "Social Platform",
        name: "social_platform",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter social platform",
        error: "Please enter social platform",
        submitButtonName: "ADD SOCIAL PLATFORM",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Social media post url",
        name: "social_media_post_url",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter social media post url",
        error: "Please enter social media post url",
      },
    ];
    setFetchPostFormData(formDataFields);
    getSocialTypes();
    // getFetchPostsList(pageCount);
    getInterestsList();
    getApprovedStoriesList(pageCount);

  }, []);

  const getApprovedStoriesList = (pageCount: any) => {
    setLoading(true);
    const data = pageCount;
    data.status = pageCount?.status === true ? "DOUBLELIKE" : "LIKE";
    data.system_user_uuid =
      loginUserInfo?.user_role === "EDITOR" ? loginUserInfo?.uuid : "";
    GETNEWSTORIES(data).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setApprovedStoriesList(res.data);
          setLoading(false);
        }
      }
    });
  };

  const getSocialTypes = () => {
    setLoading(true);
    GETSOCIALTYPES().then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          const form = fetchPostFormData;
          const data: any = [];
          res.data.forEach((element: any) => {
            data.push({
              label: element,
              value: element,
            });
          });

          form[0].option = data;
          setFetchPostFormData([...form]);
          // setSocialTypesList(res.data);
          setLoading(false);
        }
      }
    });
  };

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const getFetchPostsList = (pageCount: any) => {
    setLoading(true);
    GET_SOCIAL_MEDIA_FETCH(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setFetchPostsData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const ShowPopup = () => {
    const newData = fetchPostFormData;
    newData[0].submitButtonName = "ADD SOCIAL PLATFORM";
    newData.forEach((element: any) => {
      element.value = "";
    });
    setFetchPostFormData([...newData]);
    setIsEdit(false);
    setShowModal(true);
  };

  const onActions = (data: any) => {
    setSelectedFetchPost(data);
    if (data?.type === "edit") {
      setIsEdit(true);
      const newData = fetchPostFormData;
      newData[0].submitButtonName = "UPDATE SOCIAL PLATFORM";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setFetchPostFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === 'dailyTalkingPoints') {
      setShowDailyTalkingPoints(true);
    }
    //  else if (data?.type === "delete") {
    //   setDeleteModalBody(
    //     `Are you sure you want to delete this ${data?.data?.event_title} event!`
    //   );
    //   setShowDeleteModal(true);
    // }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getFetchPostsList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getFetchPostsList(pageData);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    if (isEdit) {
      formData.uuid = selectedFetchPost.uuid;
      setLoading(true);
      UPDATE_SOCIAL_MEDIA_FETCH(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getFetchPostsList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      CREATE_SOCIAL_MEDIA_FETCH(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getFetchPostsList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETE_SOCIAL_MEDIA_FETCH(selectedFetchPost?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getFetchPostsList(pageCount);
          setLoading(false);
        }
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };


  const getInterestsList = () => {
    setLoading(true);
    GETINTERESTS({ limit: 0, page: 1 }).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          const data = res.data?.data;
          data.unshift({
            id: -1,
            interest_keywords: [],
            interest_name: "ALL",
            is_active: 1,
            label: "ALL",
            sub_interest_count: -1,
            uuid: "ALL",
            value: "ALL",
          });
          setInterestsList(data);
          data.forEach((element: any) => {
            element.label = element?.interest_name;
            element.value = element?.uuid;
          });
          const searchFormData = searchForm;
          searchFormData[0].option = res.data?.data;
          setSearchForm([...searchFormData]);
          setLoading(false);
        }
      }
    });
  };

  const onSelectIntrest = (event: any) => {
    if (event) {
      setLoading(true);
      GETSUBINTERESTS({ interest_uuid: event }).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            const data = res.data?.data;
            data.forEach((element: any) => {
              element.label = element?.sub_interest_name;
              element.value = element?.uuid;
            });
            const searchFormData = searchForm;
            searchFormData[1].option = data;
            setSearchForm([...searchFormData]);
            setLoading(false);
          }
        }
      });
    }
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
            {/* <div>
              <div className="mt-3 pe-1">
                <button
                  className="custom-bg-btn rounded border-0 text-white me-2"
                  onClick={ShowPopup}
                >
                  Create Fetch Posts
                </button>
              </div>
            </div> */}
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">
            <DataTable
              tableData={fetchPostsData}
              TableCols={FetchPostsDataGridCols}
              onActions={onActions}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />

            {showDailyTalkingPoints && <div className="text-left" style={{ textAlign: 'left' }}>
              <h5>{selectedFetchPost?.data?.user_name} Talking points {selectedFetchPost?.dateData?.date}th {selectedFetchPost?.dateData?.month}</h5>

              <DymamicForm
                formControls={""}
                userFormData={searchForm}
                columns={3}
                cancelRoute={""}
                isEdit={false}
                onChildEvent={(e) => onSubmitForm(e)}
                onParentDropdownEvent={(e) => onSelectIntrest(e)}
                isNotEmptyFormAfterSubmit={true}
              />
              {!loading && (
                <EditorPanel
                  storiesData={approvedStoriesList}
                  intrestList={interestsList}
                  onUpdateStory={() => getApprovedStoriesList(pageCount)}
                  onPageChange={(e) => onPageChange(e)}
                  pageCount={pageCount}
                  onSelectPageChange={(e) => onSelectPageinator(e)}
                />
              )}
            </div>}
            {showDeleteModal && (
              <CustomModel
                modalTitle={"Delete"}
                ismodalShow={showDeleteModal}
                modalStatus={getModalStatus}
                modalHeaderText={deleteModalBody}
                modalType={"delete"}
                deleteStatus={onDeleteUser}
              />
            )}
            <div
              className="modal"
              style={{ display: showModal ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content modal-box-shadow">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? "Update Fetch Posts" : "Create Fetch Posts"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleModalClosed}
                    ></button>
                  </div>
                  <div className="modal-body text-start">
                    <DymamicForm
                      formControls={""}
                      userFormData={fetchPostFormData}
                      columns={1}
                      cancelRoute={""}
                      isEdit={isEdit}
                      onChildEvent={(e) => onSubmitForm(e)}
                      customClass={"display-block text-center col-12"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

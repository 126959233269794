import API from "../utils/axios";

export const GETNEWSTORIES = (data: any): Promise<any> => {
  return API.get(`source_feed/create_insight_feeds`, { params: data });
};

export const CREATE_INSIGHT_FEED = (data: any): Promise<any> => {
  return API.post(`/source_feed/create_insight_feeds`, data);
};

export const UPDATE_FEED_STATUS = (data: any): Promise<any> => {
  return API.put(`/source_feed/update_feed_status`, data);
};
import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../components/breadcum";
import { DataTable } from "../../../components/data-table";
import { toast } from "react-toastify";
import { DymamicForm } from "../../../components/form-builder/form";
import CustomModel from "../../../components/modal/custom-model";
import {
  CREATE_WAITLIST_USER,
  DELETE_WAITLIST_USER,
  GET_WAITLIST_USERS,
  UPDATE_WAITLIST_USER,
} from "../../../services/waitlist-users.service";
import { WaitlistUsersDataGridCols } from "./waitlist-users-data-grid-cols";

export const WaitlistUsersPage = () => {
  const [waitlistUsersData, setWaitlistUsersData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [waitlistUsersName, setWaitlistUsersName] = useState("");
  const [isActive, setIsActive] = useState(1);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [selectedWaitlistUser, setSelectedWaitlistUser] = useState<any>("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalBody, setDeleteModalBody] = useState("");
  const [waitlistUserFormData, setWaitlistUserFormData] = useState<any>([
    {
      title: "input",
      control: "",
      type: "text",
      label: "Name",
      name: "name",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter name",
      error: "Please enter name",
      submitButtonName: "ADD WAITLIST USER",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "email",
      name: "email",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter email",
      error: "Please enter email",
    },
    {
      title: "input",
      control: "",
      type: "text",
      label: "Occupation",
      name: "occupation_title",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Enter occupation",
      error: "Please enter occupation",
    },
    {
      title: "dropdown",
      control: "",
      type: "text",
      label: "Status",
      name: "status",
      option: [
        { label: "Active", value: "ACTIVE" },
        { label: "Pending", value: "PENDING" },
      ],
      value: 1,
      regex: "",
      required: true,
      placeholder: "Please select status",
      error: "Please select status",
    },
  ]);

  const breadcumInfo = [
    {
      label: "WAITLIST USER",
      value: "WAITLIST USER",
      routerLink: "/dashboard/waitlist-users",
      isActive: true,
    },
  ];

  useEffect(() => {
    const formDataFields: any = [
      {
        title: "input",
        control: "",
        type: "text",
        label: "Name",
        name: "name",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter name",
        error: "Please enter name",
        submitButtonName: "ADD WAITLIST USER",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Email",
        name: "email",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter email",
        error: "Please enter email",
      },
      {
        title: "input",
        control: "",
        type: "text",
        label: "Occupation",
        name: "occupation_title",
        value: "",
        required: true,
        regex: "",
        maxLength: "",
        placeholder: "Enter occupation",
        error: "Please enter occupation",
      },
      {
        title: "dropdown",
        control: "",
        type: "text",
        label: "Status",
        name: "status",
        option: [
          { label: "Active", value: "ACTIVE" },
          { label: "Pending", value: "PENDING" },
        ],
        value: 1,
        regex: "",
        required: true,
        placeholder: "Please select status",
        error: "Please select status",
      },
    ];
    setWaitlistUserFormData(formDataFields);
    getWaitlistUsersList(pageCount);
  }, []);

  const handleModalClosed = () => {
    setShowModal(false);
  };

  const getWaitlistUsersList = (pageCount: any) => {
    setLoading(true);
    GET_WAITLIST_USERS(pageCount).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          setWaitlistUsersData(res.data);
          setLoading(false);
        }
      }
    });
  };
  const ShowPopup = () => {
    const newData = waitlistUserFormData;
    newData[0].submitButtonName = "ADD WAITLIST USER";
    newData.forEach((element: any) => {
      element.value = "";
    });
    setWaitlistUserFormData([...newData]);
    setIsEdit(false);
    setShowModal(true);
  };

  const onActions = (data: any) => {
    setSelectedWaitlistUser(data?.data);
    if (data?.type === "edit") {
      setIsEdit(true);
      const newData = waitlistUserFormData;
      newData[0].submitButtonName = "UPDATE EVENT";
      newData.forEach((element: any) => {
        element.value =
          element.name === "interest_keywords"
            ? data.data[element.name]
              ? data.data[element.name]?.toString()
              : ""
            : data.data[element.name];
      });
      setWaitlistUserFormData([...newData]);
      setShowModal(true);
    } else if (data?.type === "delete") {
      setDeleteModalBody(
        `Are you sure you want to delete this ${data?.data?.name} waitlist users!`
      );
      setShowDeleteModal(true);
    }
  };
  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getWaitlistUsersList(pageData);
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getWaitlistUsersList(pageData);
  };

  const onSubmitForm = (data: any) => {
    const formData = data?.formData;
    if (isEdit) {
      formData.uuid = selectedWaitlistUser.uuid;
      setLoading(true);
      UPDATE_WAITLIST_USER(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getWaitlistUsersList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(true);
      CREATE_WAITLIST_USER(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error);
          setLoading(false);
        } else {
          if (res) {
            setShowModal(false);
            getWaitlistUsersList(pageCount);
            toast.success(res.message);
            setLoading(false);
          }
        }
      });
    }
  };

  const onDeleteUser = () => {
    setLoading(true);
    DELETE_WAITLIST_USER(selectedWaitlistUser?.uuid).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message);
          setShowDeleteModal(false);
          getWaitlistUsersList(pageCount);
          setLoading(false);
        }
      }
    });
  };

  const getModalStatus = (status: any) => {
    setShowDeleteModal(false);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <Breadcum breadcumList={breadcumInfo} />
            </div>
            <div>
              <div className="mt-3 pe-1">
                <button
                  className="custom-bg-btn rounded border-0 text-white me-2"
                  onClick={ShowPopup}
                >
                  Create Waitlist Users
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="text-center p-5">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-12">
            <DataTable
              tableData={waitlistUsersData}
              TableCols={WaitlistUsersDataGridCols}
              onActions={onActions}
              onPageChange={(e) => onPageChange(e)}
              pageCount={pageCount}
              onSelectPageChange={(e) => onSelectPageinator(e)}
            />
            {showDeleteModal && (
              <CustomModel
                modalTitle={"Delete"}
                ismodalShow={showDeleteModal}
                modalStatus={getModalStatus}
                modalHeaderText={deleteModalBody}
                modalType={"delete"}
                deleteStatus={onDeleteUser}
              />
            )}
            <div
              className="modal"
              style={{ display: showModal ? "block" : "none" }}
            >
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content modal-box-shadow">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {isEdit ? "Update Waitlist User" : "Create Waitlist User"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleModalClosed}
                    ></button>
                  </div>
                  <div className="modal-body text-start">
                    <DymamicForm
                      formControls={""}
                      userFormData={waitlistUserFormData}
                      columns={1}
                      cancelRoute={""}
                      isEdit={isEdit}
                      onChildEvent={(e) => onSubmitForm(e)}
                      customClass={"display-block text-center col-12"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

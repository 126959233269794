import API from "../utils/axios";

export const GET_CONCIERGE_USERS = (data: any): Promise<any> => {
  return API.get(`/concierge_users/fetch`, { params: data });
};

export const CREATE_CONCIERGE_USER = (data: any): Promise<any> => {
  return API.post(`/concierge_users/create`, data);
};

export const UPDATE_CONCIERGE_USER = (data: any): Promise<any> => {
  return API.put(`/concierge_users/update`, data);
};

import React from "react";
import { useNavigate } from "react-router-dom";

export const UserForm = () => {
  const navigate = useNavigate();

  const handleCancelButton = () => {
    navigate("/dashboard/users");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <h3 className="text-start my-2">Create User</h3>
        <div className="col-12">
          <div>
            <form>
              <div className="row d-flex justify-content-between">
                <div className="col-md-6 my-2">
                  <label className="form-label d-flex justify-content-start">
                    User name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter user name "
                  />
                </div>
                <div className="col-md-6 my-2">
                  <label className="form-label  d-flex justify-content-start">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter email"
                  />
                </div>
                <div className="col-md-6 my-2">
                  <label className="form-label  d-flex justify-content-start">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end my-3">
                <button className="custom-bg-btn rounded border-0 text-white me-2">
                  Create
                </button>
                <button
                  className="custom-cancel-btn text-dark  rounded border-0 px-3 py-1"
                  onClick={handleCancelButton}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

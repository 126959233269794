import API from "../utils/axios";

export const GET_PROFESSION = (data: any): Promise<any> => {
  return API.get(`/profession/fetch`, { params: data });
};

export const CREATE_PROFESSION = (data: any): Promise<any> => {
  return API.post(`/profession/create`, data);
};

export const UPDATE_PROFESSION = (data: any): Promise<any> => {
  return API.put(`/profession/update`, data);
};

export const DELETE_PROFESSION = (uuid: string): Promise<any> => {
  return API.delete(`/profession/delete/${uuid}`);
};

import API from "../utils/axios";

export const GET_SPIN_TYPES = (data: any): Promise<any> => {
  return API.get(`/spinType/fetch`, { params: data });
};

export const CREATE_SPIN_TYPE = (data: any): Promise<any> => {
  return API.post(`/spinType/create`, data);
};

export const UPDATE_SPIN_TYPE = (data: any): Promise<any> => {
  return API.put(`/spinType/update`, data);
};

export const DELETE_SPIN_TYPE = (uuid: string): Promise<any> => {
  return API.delete(`/spinType/delete/${uuid}`);
};

import { DataTableCol } from "../data-table/types";

export const UserPostedPostReportGridCols: DataTableCol[] = [
    {
        title: "Feed Title",
        control: "feed_title",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Posted To",
        control: "post_to_platform",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Emotion",
        control: "post_emotion",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Length",
        control: "post_length",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Sentiment",
        control: "post_sentiment",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Post Type",
        control: "post_type",
        sortable: true,
        canShowColumn: true,
    }
];

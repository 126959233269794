import { DataTableCol } from "../../../../components/data-table/types";

export const ConciergeUsersDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Phone",
    control: "phone",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "User Score",
    control: "user_score",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "status",
    control: "status",
    sortable: true,
    canShowColumn: true,
    isTextOverflowHide: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: false,
    canShowColumn: true,
  },
];

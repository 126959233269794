import API from "../utils/axios";

export const GET_SOCIAL_MEDIA_FETCH = (data: any): Promise<any> => {
  return API.get(`/socialMediaFetch/fetch`, { params: data });
};

export const CREATE_SOCIAL_MEDIA_FETCH = (data: any): Promise<any> => {
  return API.post(`/socialMediaFetch/create`, data);
};

export const UPDATE_SOCIAL_MEDIA_FETCH = (data: any): Promise<any> => {
  return API.put(`/socialMediaFetch/update`, data);
};

export const DELETE_SOCIAL_MEDIA_FETCH = (uuid: string): Promise<any> => {
  return API.delete(`/socialMediaFetch/delete/${uuid}`);
};

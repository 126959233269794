import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_LAST_WEEKS_REPORT } from "../../services/report.service";

export const WeekReportsPage = () => {
  const [weekReportData, setWeekReportData] = useState<any>();

  useEffect(() => {
    getWeekReport();
  }, []);

  const getWeekReport = () => {
    // setLoading(true);
    GET_LAST_WEEKS_REPORT().then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error);
        // setLoading(false);
      } else {
        if (res) {
          setWeekReportData(res?.data)
          // setLoading(false);
        }
      }
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table_new_ui">
        <thead className="text-center">
          <tr>
            {weekReportData?.data.map((columnName: any, index: any) => (
              <th key={index}>{columnName?.column_name}</th>
            ))}
          </tr>
        </thead>
        <tbody className="text-center">
          <tr>
            {weekReportData?.data.map((item: any, index: any) => (
              <td key={index}>{item.count}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

import API from "../utils/axios";

export const GETNEWSSOURCES = (data: any): Promise<any> => {
  return API.get(`/newsSources/fetch`, { params: data });
};

export const ADDNEWSSOURCE = (data: any): Promise<any> => {
  return API.post(`/newsSources/create`, data);
};

export const UPDATENEWSSOURCE = (data: any): Promise<any> => {
  return API.put(`/newsSources/update`, data);
};

export const DELETENEWSSOURCE = (uuid: string): Promise<any> => {
  return API.delete(`/newsSources/delete/${uuid}`);
};

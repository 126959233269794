import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ActiveUsersList } from "../pages/Dashboard/users/active-users/active-users-list";
import { InActiveUsersList } from "../pages/Dashboard/users/pending-users/pending-users-list";
import { EditorsPage } from "../pages/Dashboard/users/editors/editors";
import { ActiveUsersEventList } from "../pages/Dashboard/users/active-users-events/active-users-events-list";

export const NestedUsers = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ActiveUsersList />}></Route>
        <Route path="/active" element={<ActiveUsersList />}></Route>
        <Route path="/events" element={<ActiveUsersEventList />}></Route>
        <Route path="/pending" element={<InActiveUsersList />}></Route>
        <Route path="/editors" element={<EditorsPage />}></Route>
      </Routes>
    </div>
  );
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import { NewStoriesPage } from "../pages/Dashboard/editor-panel/new-stories/new-stories";
import { ApprovedStoriesPage } from "../pages/Dashboard/editor-panel/approved-stories/approved-stories";
import { RejectedStoriesPage } from "../pages/Dashboard/editor-panel/rejected-stories/rejected-stories";
import { DoubleThumbsUpStoriesPage } from "../pages/Dashboard/editor-panel/double-thumbs-up-stories/double-thumbs-up-stories";
import { StaredStoriesPage } from "../pages/Dashboard/editor-panel/stared-stories/stared-stories";
import { MasterStoriesPage } from "../pages/Dashboard/editor-panel/master-feed/master-stories";
import { TodaysStoriesPage } from "../pages/Dashboard/editor-panel/todays-feeds/todays-stories";
import { SocialMediaStoriesPage } from "../pages/Dashboard/editor-panel/social-media/social-media-stories";
import { SERPPage } from "../pages/Dashboard/editor-panel/SERP-feeds/SERP-panel";
import { SERPFeedsPage } from "../pages/Dashboard/editor-panel/SERP-feeds/feeds/feeds-stories";
import { SERPApprovedPage } from "../pages/Dashboard/editor-panel/SERP-feeds/approved/SERP-approved-stories";
import { SERPRejectedPage } from "../pages/Dashboard/editor-panel/SERP-feeds/rejected/SERP-rejected-stories";
import { IndividualFeedsPage } from "../pages/Dashboard/editor-panel/individual-feeds/individual-feeds";
import { InsightFeedsPage } from "../pages/Dashboard/editor-panel/insight-feeds/feeds/feeds-stories";
import { InsightPanelPage } from "../pages/Dashboard/editor-panel/insight-feeds/insight-panel";
import { PerplexityFeedsPage } from "../pages/Dashboard/editor-panel/perplexity-feeds/feeds/feeds-stories";
import { PerplexityPage } from "../pages/Dashboard/editor-panel/perplexity-feeds/perplexity-panel";

export const EditorPanelRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<TodaysStoriesPage />}></Route>
        <Route path="/new-stories" element={<NewStoriesPage />}></Route>
        <Route
          path="/approved-stories"
          element={<ApprovedStoriesPage />}
        ></Route>
        <Route
          path="/rejected-stories"
          element={<RejectedStoriesPage />}
        ></Route>
        <Route
          path="/individual-feeds"
          element={<IndividualFeedsPage />}
        ></Route>
        <Route
          path="/double-thumbs-up-stories"
          element={<DoubleThumbsUpStoriesPage />}
        ></Route>
        <Route
          path="/stared-stories"
          element={<StaredStoriesPage />}
        ></Route>
        <Route
          path="/master-stories"
          element={<MasterStoriesPage />}
        ></Route>
        <Route
          path="/todays-stories"
          element={<TodaysStoriesPage />}
        ></Route>
        <Route
          path="/social-media-stories"
          element={<SocialMediaStoriesPage />}
        ></Route>
        <Route
          path="/SREP-stories/*"
          element={<SERPPage />}
        >
          <Route
            path="feeds"
            element={<SERPFeedsPage />}
          >
          </Route>
        </Route>
        <Route
          path="/perplexity-stories/*"
          element={<PerplexityPage />}
        >
          <Route
            path="feeds"
            element={<PerplexityFeedsPage />}
          >
          </Route>
        </Route>
        <Route
          path="/insight-stories/*"
          element={<InsightPanelPage />}
        >
          <Route
            path="feeds"
            element={<InsightFeedsPage />}
          >
          </Route>
        </Route>
      </Routes>
    </div >
  );
};

import React from "react";
import { Route, Routes } from "react-router-dom";
import { PerplexityRejectedPage } from "../pages/Dashboard/editor-panel/perplexity-feeds/rejected/perplexity-rejected-stories";
import { PerplexityAprovedPage } from "../pages/Dashboard/editor-panel/perplexity-feeds/approved/perplexity-approved-stories";
import { PerplexityFeedsPage } from "../pages/Dashboard/editor-panel/perplexity-feeds/feeds/feeds-stories";

export const PerplexityRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<PerplexityFeedsPage />}></Route>

        <Route
          path="feeds"
          element={<PerplexityFeedsPage />}
        >
        </Route>
        <Route
          path="approved"
          element={<PerplexityAprovedPage />}
        ></Route>
        <Route
          path="rejected"
          element={<PerplexityRejectedPage />}
        ></Route>
      </Routes>
    </div >
  );
};

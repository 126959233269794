import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DymamicForm } from "../../components/form-builder/form";
import { FORGOT_PASSWORD } from "../../services/system-users.service";
import { toast } from "react-toastify";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const forgotFormData = [
    {
      title: "input",
      control: "",
      type: "text",
      label: "Email",
      name: "user_email",
      value: "",
      required: true,
      regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,10}.[a-z]{2,6}$/,
      placeholder: "Enter email",
      error: "Please enter email",
      submitButtonName: "SUBMIT",
      hideMandatoryStar: true,
    },
  ];
  const onClickLogin = () => {
    // navigate("/dashboard")
  };
  const onSubmitForm = (event: any) => {
    setLoading(true);
    FORGOT_PASSWORD(event?.formData).then((res: any) => {
      if (res.status === "error") {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res) {
          navigate("/");
          toast.success(res.message);
          setLoading(false);
        }
      }
    });
  };
  return (
    <>
      <div className="modal" style={{ display: "block" }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="login-form">
              <div className="d-flex flex-column justify-content-center">
                <div className="">
                  <form className="m-4">
                    <div className="Login-form-content">
                      <h4 className="Login-form-title text-center">
                        Forgot Password
                      </h4>
                      <DymamicForm
                        formControls={""}
                        userFormData={forgotFormData}
                        columns={1}
                        cancelRoute={""}
                        isEdit={false}
                        onChildEvent={(e) => onSubmitForm(e)}
                        customClass={"display-block"}
                        loading={loading}
                        isNotEmptyFormAfterSubmit={true}
                      />
                      <div className="d-flex flex-column justify-content-center ">
                        <div className="my-2">
                          <NavLink to="/" className="text-decoration-none">
                            <span
                              className="custom-text-color"
                              onClick={onClickLogin}
                            >
                              Login
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { DataTableCol } from "../../../../components/data-table/types";

export const EditorsDataGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Email",
    control: "user_email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Phone Number",
    control: "user_phone",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    isEdit: true,
    isDelete: true,
    canShowColumn: true,
  },
];
